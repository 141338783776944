import moment from "moment"
const OrdersCard = ({...props}) => {
   const order = props.order

   const collectClick = () => {
       props.setShowCollect(true)
       props.setSelectedOrder(order)
   }

   

    return ( 
    
       
        <div className={`w-full  border ${ order.status != 'active' ? 'border-green-500' : 'border-blue-500' } rounded  py-2 px-3`}>
                <div className="w-full flex justify-between mb-2">
                    {order.status !== 'active' ? 
                    <p className="text-green-500 font-bold">{order.customer.name}</p>
                    :
                    <p className="text-blue-500 font-bold">{order.customer.name} </p>
                    }

                    <p className="text-xs font-bold"># {order.id}</p>
                </div>
                <div className="flex justify-between text-sm my-2">
                    <p className="font-bold text-gray-500">KSH {order.totals} </p>
                    <p>
                {order.status == 'active' ? 
                    <div className="flex-none py-1 px-4 font-sm bg-blue-200 text-blue-600 rounded-lg font-bold cursor-pointer" onClick={collectClick}>Collect</div>
                    :
                    <div className="flex-none py-1 px-4  font-sm bg-green-200 text-green-700 rounded-lg font-bold" >Received</div>
                    }
                    </p>
                </div>

                <div className="w-full">
                    <p className="px-3 py-1 rounded inline-flex text-xs font-bold text-blue-600 border border-blue-600">
                    {moment(order.ordered_at).format('Do MMM YY hh:mm A')}
                    </p>
                </div>


        </div>
    );
}
 
export default OrdersCard;