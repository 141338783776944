import { useState } from "react"
const ReturnCrate = ({...props}) => {

    const [quantity,setQuantity]  = useState(null)

    const handleInput = (val) => {
        setQuantity(val)
    }

    const handleSubmit = () => {
        console.log('clicked')
        quantity && quantity <= props.driver.crates && 
        props.returnCrates({driver:props.driver.pk,quantity:quantity})  
    }

    return ( 


        <div id="" 
        className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          
                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          
          
              <div className=" inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl py-4 px-6 transform transition-all sm:my-8 w-full md:w-1/2 lg:w-1/3 " id="">
            
              
                <div className="flex mb-3 justify-between w-full pb-2 border-b">
                    <p className="font-bold text-lg">{props.driver.name} <span className="ml-3 bg-yellow-600 text-white font-bold py-1 px-4 rounded">Return Crates</span> </p>
                    <span className="material-icons-outlined text-gray-700 cursor-pointer" onClick={()=>props.openModal(false)}>
                      close
                      </span>
                </div> 


                
            <div className="w-full walkInData">



            <p className=" inline-flex text-gray-600 bg-yellow-200 text-yellow-600 px-4 rounded font-bold py-1 ">Issued  Crates - {props.driver.crates} Crates</p>
  


            <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Enter Quantity</label>
                    <input type="text" onChange = {(e)=>{
                      handleInput(e.target.value)
                  }
                    } className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>

        

            </div>


                
                            <div className="w-full lg:w-2/3 mx-auto my-5">
                                                                              <button className="bg-yellow-600 w-full text-white rounded py-3 font-bold" onClick={handleSubmit}  >Submit</button>
                                                                              </div>
                        
        
        
              
          
            
              </div>
            </div>
          </div>


     );
}
 
export default ReturnCrate;