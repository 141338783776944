import axios from 'axios'
import { error_customers, loading_customers, success_customers } from '../features/workforce/customers_slice'

const token = localStorage.getItem('b_token')
const apiUrl = process.env.REACT_APP_API_URL




export const fetchCustomers = () => dispatch => {
    return new Promise (async (resolve,reject) => {
        try{
            dispatch(loading_customers())
            const res = await axios.get(`${apiUrl}workforce/customerList`,{
                headers: {
                  'Authorization': `Token ${token}` 
        }})
            console.log(res.status)
            res.status == 200 ? dispatch(success_customers(res.data)): dispatch(error_customers('Customers Error'))
        }catch (e) {
           
        }
    }) 
}