import axios from 'axios'
import { error_mixes, loading_mixes, success_mixes } from '../features/production/mixSlice'

const apiUrl = process.env.REACT_APP_API_URL


export const fetchMixes = () => dispatch => {
    return new Promise (async(resolve,reject)  => {
        try{    
            dispatch(loading_mixes())
            const res = await axios.get(`${apiUrl}products/fetchMixes`)
            res.status == 200 ? dispatch(success_mixes(res.data)): dispatch(error_mixes('Mix Error'))
        }catch{

        }
    })
}   