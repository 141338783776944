import axios from "axios"
import { userSet,groupSet, failedUser } from "../features/sytemuserSlice"

const apiUrl = process.env.REACT_APP_API_URL

const userAddAPI = apiUrl+'auth/systemusers'
const userFetchAPI = apiUrl+'auth/systemusersFetch'
const groupsAPI = apiUrl+'auth/groups'

export const addUser = (user) => dispatch => {
  return new Promise (async (resolve,reject) => {
      try {
          const res = await axios.post(userAddAPI,user)
          res.status == 201 &&
           dispatch (fetchUsers())
           resolve('success')
      }catch (error){
        //   console.log(error)
      }
  } )
}


export const setUserPasscode = (data)=> dispatch => {
    return new Promise (async (resolve,reject) => {
        try {
            const res = await axios.post(apiUrl+'auth/passcode',data)
            res.status == 201 &&
            dispatch (fetchUsers())
            resolve('success')
        }catch(error){
           dispatch(failedUser())
        }
    })
}



export const fetchUsers = () => dispatch => {
    return new Promise (async (resolve,reject) => {
        try {
            const res =  await axios.get(userFetchAPI)
            res.status == 200 &&
            dispatch(userSet(res.data))
        }catch (error) {
            console.log(error)
        }
    } )
}


export const fetchGroups = () => dispatch => {
    return new Promise (  async (resolve,reject) => {
        try {
            const res =  await axios.get(groupsAPI)
            res.status == 200 &&
            dispatch(groupSet(res.data))
        }catch (error) {
            console.log(error)
        }
    })
}