import { useState,useEffect } from 'react'
import OrderPending from './modals/orderPending';
import OrderProgress from './modals/orderProgress';
import OrderProducedConfirmation from './modals/OrderProducedConfirmation';
import OrderCard from "./orderCard";
import { RedLoader, YellowLoader } from '../../universal/loaders';
import SearchOrders from './searchOrders';

const OrdersList = ({...props}) => {

    const [productId,setProductId] = useState(null)
    const [openModal,setOpenModal] = useState(false)
    const [ssProd,ssProduction] = useState()

    const [selectedProduct,setSelectedProduct] = useState({})




    const [choosenStatus,setChooseStatus] = useState(null)

    const mixorders = props.mixorders


    const openOrder = (id,status,prodId) => {
        console.log(prodId)
        ssProduction('')
        ssProduction(prodId)
        setProductId(id)
        setOpenModal(true)
        setChooseStatus(status)
        const selected = mixorders.filter(item=>{
            return item.id===id
        }).map(product=>{
            return product
        })
        setSelectedProduct(selected)
        // console.log(selected)   
    }

    const setShowModal = () => {
        setOpenModal(false)
    }

        


    return ( 
        <div className="w-full flex flex-col overflow-x-hidden  sm:-mx-px">


<SearchOrders type={'order'} />
            
<div className="w-full overflow-x-hidden overflow-y-scroll flex flex-wrap">
            {mixorders === null ? (
               <div className="w-full flex h-48 items-center justify-center ">
                 <RedLoader />
               </div>
            ) : (
                <>
{mixorders.map(mixorder=> (       
<OrderCard  openOrder={openOrder} status={mixorder.status} mixorder={mixorder} isKitchen={props.isKitchen}  />
))}
                </>
            ) }

</div>

            {(() => {
                if(openModal && choosenStatus == 'initialized'){
                    return (<OrderPending  addMixIngredients={props.addMixIngredients} mixproduct={selectedProduct} setShowModal={setShowModal} /> )
                }else if (openModal && choosenStatus == 'on progress' ){
                    return (<OrderProgress mixproduct={selectedProduct} addProduction={props.addProduction}   setShowModal={setShowModal} />)
                }else if(openModal && choosenStatus == 'produced' && !props.isKitchen) {
                    return (<OrderProducedConfirmation prodId={ssProd} updateMix={props.updateMix} updateProduction={props.updateProduction} mixproduct={selectedProduct} addProduction={props.addProduction}   setShowModal={setShowModal} />)
                }
            })()}


     


        </div>
     );
}
 
export default OrdersList;