import { Fragment,useEffect,useState } from "react";
import WarningAlert from "../../../alerts/warningAlert";
import DeliveryNote from "../deliveryNote";
import Load from "../../../universal/load";
import { YellowLoader } from "../../../universal/loaders";
import LoadingSheet from "../loadingSheet";


const DeliveryShow = ({...props}) => {


    const delivery = props.delivery

    const recentDelivery = props.recentDelivery

    const [del,setDel]=useState(null)


    function reduceFilter(acc,obj){
        if (Array.isArray(obj.products)){
          return obj.products.reduce(reduceFilter,acc)
        }
       
        return [...acc,{"product":obj.product_name,"quantity":(acc["product"]===obj.product_name || 0)+parseFloat(obj.quantity)}]
    }

    useEffect(() => {
        recentDelivery !== null && 
        setDel(recentDelivery.orders.reduce(reduceFilter,[]).reduce(furtherReduce,[]))
    }, [recentDelivery])

    console.log(del)



function furtherReduce(acc,obj){
    if (acc.find(x=>x.product==obj.product)){
      acc.find(x=>x.product==obj.product).quantity+=obj.quantity
       return [...acc]
    }else{
      return [...acc,{'product':obj.product,'quantity':obj.quantity}]
    }
  
  }

    useEffect (() => {
        console.log('starting...')
        props.getDelivery(delivery.id)

        console.log(recentDelivery)
    }, [])



    return ( 
        <Fragment>
                <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                    {/*content*/}
                <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        
          
        
                <div className="flex justify-between px-3 pt-2 pb-1 border-b">
                            <p className="text-xl font-bold text-yellow-400"> {delivery.driver_name} | {delivery.plate_no}</p>
                            <button  onClick={()=>props.setShowDel(false)}  className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                          </div>
        
        
                          <div className="relative px-4 w-full pb-8    mt-4 text-left">
                                       
                        {
                            recentDelivery === null ?
                            <div className="w-full flex h-48 justify-center items-center">
                                <YellowLoader />
                            </div>
                            :
                            recentDelivery.orders.length < 1 ? <WarningAlert message="Order Not Assigned" /> :

                            <>
                            <div className="flex items-center">
                            <div className="w-full font-bold text-gray-600 mt-2 mb-3 ">Assigned Orders</div>

                            <DeliveryNote delivery={recentDelivery} />
                            <LoadingSheet delivery={recentDelivery} />
                            
                            </div>

                            <div className="w-full max-h-48 overflow-y-scroll">

                           { recentDelivery.orders.map(order=>
                            
                            <div className="w-full flex flex-wrap px-2 ">

                            <div className="w-full px-3 my-2  overflow-x-hidden">
                                <div className="w-full flex py-3 border-b items-center ">
                                    <div className="w-1/3  font-bold uppercase border-r">
                                        <p>{order.customer.name}</p>
                                        <p>{order.customer.phone}   <button className="w-8 h-8 ml-3 bg-blue-600 rounded-full text-white">
                                            <span onClick={()=>{
                                                props.selectEditedOrder(order)
                                                props.setShowDel(false)
                                                props.setOpenModal(true)
                                            }}  className="material-icons-outlined text-sm">
                                                edit
                                            </span>
                                        </button>
  </p>

                                      

                                        
                                    </div>
                                    <div className="flex-grow text-sm font-bold text-gray-500 uppercase px-3">

                                {order.products.length > 0 && 
                                        order.products.map(product=>
                                            <div className="my-3 border-b"> 
                                            <div className="flex justify-between">
                                            <p>{product.product_name}</p>
                                            <p><span className="">{product.price} X {parseFloat(product.quantity)} pcs</span></p>
                                            </div>   
                                            <div className="w-full mb-3 text-right -m-1">
                                               = {product.total_before_return}
                                            </div> 
                                            </div>
                                        )
                                        
                                                               
                                    }
                                     <div className="flexmy-3 text-right">
                                          
                                            <p><span className="font-bol text-black ">KSH {order.total_before_return}</span></p>
                                            </div>     
                                    </div>
                                </div>


                            </div>

                        </div>

                            )}

</div>

{del !== null && 
<div className="w-full my-4">
    <div className="rounded p-2 pb-4 border-yellow-500 border">
       <p className="font-bold my-2 text-yellow-600">Summary</p>


       <div className="w-full my-3 max-h-36 overflow-y-scroll">

        {
            del.map(summary=>
                <div className="w-full flex justify-between text-sm py-3 px-2 font-bold border-b">
                <p class="text-gray-500 uppercase">{summary.product}</p>
                <div className="py-2 text-xs px-4 rounded-full font-extrabold text-yellow-600 bg-yellow-200">{summary.quantity} pcs</div>
               </div>
                
            )
        }

       </div>
    </div>
</div>

}


                            </>

                        }
        
        
        
        
        
        
                        
        
                        
         
        
                        </div>
              
        
        
        
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
     );
}
 
export default DeliveryShow;