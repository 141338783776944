import React from "react";
import ReactToPrint from "react-to-print";
import mlblogo from "../../../assets/img/mlblogo.PNG"
import moment from 'moment'

class LoadingS extends React.Component {



    render() {
    
    const delivery = this.props.delivery

    return (
      <>  
      <div className="printPage">
     <div className="flex w-full  px-4  mx-1">
     <div className="mb-1">
        <img src={mlblogo} className="object-fill h-24" alt="" />
      </div>
<div className="w-2/3 font-bold uppercase mt-1 mb-1 mx-auto border-b-2 border-black px-3">
<p className="">BAKERS JOINT LIMITED</p>
        <p className="">P.O BOX  97810, MAKUPA, MOMBASA</p>
        <p className="">Tel: +254792349348 | +254741375766 | +254723163886 </p>
        <p className="capitalize" ><i>Dealers in:- All types of confectioneries</i></p>
  </div>
     </div>

        <div className="flex  my-3 px-2 w-full px-4">
        <div className="w-1/2 flex flex-col text-sm border-2 border-black  px-3 py-1  text-left uppercase font-bold">
        <p className="">LOADING SHEET # {delivery.id}</p>
        <p className="">{delivery.driver_name}</p>
        <p className="">{delivery.plate_no}</p>
        <p className="">{moment(delivery.created_at).format('YYYY-MM-DD hh:mm A ')}
        </p>        
        </div>
        </div>


<div className="w-full">

<table class="min-w-full  divide-y divide-gray-200 text-sm table">
          <thead class="bg-black text-white font-bold">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs uppercase tracking-wider whitespace-nowrap ">
                CUSTOMER
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs uppercase tracking-wider whitespace-nowrap ">
                QUANTITY
              </th>
            </tr>
              </thead>


          <tbody class="bg-white divide-y text-xs divide-gray-200 items-start uppercase">
          { delivery.orders.map(order=> 

              <tr className="border" >
                <td class="px-6 border py-1 whitespace-nowrap">
                    <p className="font-bold">#{order.id}</p>
                    <p>{order.customer.name}</p>
                    <p>{order.customer.phone}</p>                 
                </td>  
                <td className="border px-3" >

                {order.products.length > 0 && 
            order.products.map(product=> 
             <div className="my-1 w-full font-bold  flex justify-between">
              <p>{product.product_name}</p>
              {/* <div className="w-full flex justify-between pr-5"> */}
              <p><span className="font-bold">{parseFloat(product.quantity)} pcs</span></p>
              {/* <p className="font-bold" >= KSH {product.total}</p>
              </div> */}
             </div>               

                )
            
                                   
              }
              {/* <div className="text-right   ml-4 font-bold pr-5">
              <p className="font-bold py-3">= KSH {order.totals}</p>
              </div> */}

              </td>         
  
    
                </tr>
)
          }
          






          {/* <tr>
            <td className="px-6 border py-1 whitespace-nowrap  text-lg font-bold">Total</td>
            <td className="text-lg font-bold border text-right pr-6" >KSH {delivery.total}</td>
          </tr> */}


                </tbody>
        </table>

</div>




        </div>

      


     
      </>
    );
  }
}







class LoadingSheet extends React.Component {
  render() {
    const d = this.props.delivery
    return (
      <div>
        <ReactToPrint
          trigger={() =><button className="bg-blue-200 text-blue-600 px-3 py-1 cursor-pointer focus:outline-none rounded-lg mx-3"><span class="material-icons-outlined">
          upload_file
          </span> </button>
                               }
          content={() => this.componentRef}
        />
   <div style={{ display: "none"}}>    <LoadingS delivery={d}  ref={(el) => (this.componentRef = el)} /></div> 
      </div>
    );
  }
}

export default LoadingSheet;
