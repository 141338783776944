import {configureStore} from "@reduxjs/toolkit"
import authReducer from "./features/loginSlice"
import userReducer from "./features/userSlice"
import systemuserReducer from "./features/sytemuserSlice"
import reportSReducer from './features/reportSlice'
import MixPricingReportSReducer  from "./features/reports/mpr_slice"
import stockoutreportSReducer  from "./features/reports/sor_slice"
import customersalesreportSReducer from "./features/reports/csr_slice"
import productsalesreportReducer from './features/reports/productSales_slice'
import stockinreportSReducer from "./features/reports/sir_slice"
import ingredientsReducer from './features/stock/ingredients_slice'
import mixesReducer from './features/production/mixSlice'
import customersReducer from './features/workforce/customers_slice'
import productsReducer from './features/product_slice'


export default configureStore({
    reducer:{
        auth:authReducer,
        user:userReducer,
        systemusers:systemuserReducer,
        reports:reportSReducer,
        mixpricingreports:MixPricingReportSReducer,
        stockoutreports: stockoutreportSReducer,
        stockinreports: stockinreportSReducer,
        customersalesreports: customersalesreportSReducer,
        ingredients:ingredientsReducer,
        mixes:mixesReducer,
        customers:customersReducer,
        products:productsReducer,
        productsalesreport:productsalesreportReducer,
        
    }
})  