
//  stockin  report api
import { success_abr_sir,error_abr_sir,clear_error_abr_sir,loading_abr_sir } from '../../features/reports/sir_slice'
import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL




export const getStockInReport = (start,end,product) => dispatch => {
    return new Promise (async (resolve,reject) => {
            try {
                dispatch(loading_abr_sir())
                const res = await axios.get(apiUrl+`reports/stockinreport/${start}/${end}/${product}`)
                // console.log(res)
                res.status == 200 ? dispatch(success_abr_sir(res.data)) : dispatch(error_abr_sir(res.data))
            }catch{

            }
    } )
}

