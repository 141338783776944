import Navbar from "../universal/navbar";
import BreadCrumbs from "../universal/breadcrumbs"
import FabExpense from "../layouts/expenses/FabExpense";
import ExpenseList from "../layouts/expenses/expenseList";
import {useEffect} from 'react'

const Expenses = ({...props}) => {

    useEffect(() => {
        props.fetchExpense()
    }, []);

    return ( 
        <div className="relative mx-auto ">
        <Navbar active="expenses"/>
        <div className="w-full px-6">
        <BreadCrumbs newPage="Expenses" />

 
         <FabExpense addExpense={props.addExpense} />

        <ExpenseList expenses={props.expenses} />
           
        </div>

        </div>
     );
}
 
export default Expenses;