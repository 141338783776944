import moment from "moment";
const DistributionModal = ({setShowModal,order}) => {
    return ( 
        <div class=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      
               <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      
       
          <div class=" inline-block align-top bg-white rounded-lg text-left overflow-hidden shadow-xl p-4 transform transition-all sm:my-8 sm:align-middle w-full lg:w-1/3" id="">
         <div class="w-full" id="">
           
         <div class="flex mb-3 justify-between w-full">
                <p class="font-bold text-lg">{order.customer.name}</p>
                <span class="material-icons-outlined text-gray-700 cursor-pointer" onClick={()=>setShowModal(false)}>
                  close
                  </span>
             </div> 

            <div className="w-full lg:w-2/3  text-right my-4 ml-auto p-3 text-xs border font-bold rounded">
                Ordered On {moment(order.ordered_at).format('YYYY-MM-DD hh:mm A')}
            </div>
             
             <div class="w-full" id="editEmployeeDataArea text-xs" >

            { order.products.map(product=>
                                            <div className="my-3 border-b"> 
                                            <div className="flex justify-between">
                                            <p className="font-bold" >{product.product_name}</p>
                                            <p><span className="">{product.price} X {product.quantity} pcs</span></p>
                                            </div>   
                                            <div className="w-full mb-3 text-right -m-1">
                                               = {product.total}
                                            </div> 
                                            </div>
                                        )
                
}
             </div>      

             <div className="text-lg font-bold text-right pb-4 ">
              KSH  {order.totals}     
            </div>   
             
          
      
           </div>
          </div>
        </div>
      </div>
    
     );
}
 
export default DistributionModal;