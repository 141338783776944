import Navbar from "../universal/navbar";
import BreadCrumbs from "../universal/breadcrumbs"
import {reportState} from "../../features/reportSlice"
import {FetchGraphProd, ProductionReportAPI} from '../../api/reportAPI'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {useState,useEffect} from 'react'    
import moment from 'moment'
import {useDispatch,useSelector} from 'react-redux'
import Spinner from "../universal/spinner";
import MixChartProduction from "../charts/productionLine";
import MixSummaryLine from "../charts/mixBar";



const ProductionReport = () => {

    const [startDate,setStartDate] = useState(null)
    const [endDate,setEndDate] = useState(null)

    

    const reportV = useSelector(reportState)

    const reportData = reportV.reportData
    const isLoading = reportV.isLoading
    const fetchGraph = reportV.fetchGraph
    const graphData = reportV.graphData
    const graphLoading = reportV.graphLoading

    const [summary,setSummary]=useState(null)
//    const isLoading = true
    
    

    const dispatch = useDispatch()



    const handleSubmit = () => {
        startDate  && 
        endDate  &&
        dispatch(ProductionReportAPI(moment(startDate).format('YYYY-MM-DD'),moment(endDate).format('YYYY-MM-DD')))
    }

    useEffect(()=>{

        reportData &&
        setSummary(reportData.reduce(reduceMix,[]).sort((a,b)=>b.quantity-a.quantity))

        console.log(setSummary)
     

    },[reportData])


 function reduceMix(acc,obj){
        if (acc.find(x=>x.mix==obj.mix_name)){
          acc.find(x=>x.mix==obj.mix_name).quantity+=obj.quantity
           return [...acc]
        }else{
          return [...acc,{'mix':obj.mix_name,'quantity':obj.quantity}]
        }
      
      }
    
    

    useEffect(() => {
        fetchGraph &&    
        dispatch(FetchGraphProd(moment(startDate).format('YYYY-MM-DD'),moment(endDate).format('YYYY-MM-DD')))
    }, [fetchGraph])



 
    return ( 

        <div className="relative mx-auto ">
        <Navbar active="expenses"/>
        <div className="w-full px-0 lg:px-6">
        <BreadCrumbs newPage="Production Report" />
           
        
        <div className="w-full p-3 flex flex-wrap items-end">
            <div className="w-full text-left lg:w-1/6 p-2">
            <p className="text-gray-500 font-bold py-3">Start Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setStartDate(e.target.value)} />    
            </div>

            <div className="w-full text-left lg:w-1/6 p-2">
            <p className="text-gray-500 font-bold py-3">End Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setEndDate(e.target.value)} />    
            </div>

            <div className="w-full lg:w-1/6 px-4 py-2">

            { !isLoading ? 
<button className="w-full font-bold border-2 border-blue-600 text-blue-600 mb-2 focus:outline-none rounded-lg py-3" onClick={handleSubmit}>Get Productions</button>
            :
<button disabled className="w-full font-bold border-2 border-blue-600 text-blue-600 mb-2 focus:outline-none rounded-lg py-3">Fetching Report ...</button>
            
            }


            </div>

        </div>
    

        <div className="w-full my-3 flex flex-wrap">

            {/* first right side */}

            <div className="w-full lg:w-3/5 p-3 flex flex-wrap max-h-screen overflow-y-scroll">


                {

                    isLoading ? 

                    <Spinner />

                    :

                    reportData && 

                    reportData.length > 0  ?

                    reportData.map(mixorder => 
                        <div className="w-full lg:w-1/2 p-2">
                        <div className="w-full shadow  px-3 rounded py-3 flex justify-between items-center">
                            <div className="flex w-1/2 flex-col  px-2">
                                <div className="w-full flex justify-between ">
                                <p className="text-sm uppercase font-bold text-gray-900">{mixorder.mix_name}</p> 
                            <div>
                            <div className="py-1 w-full rounded-full font-bold flex-none px-3 border-blue-600 text-blue-600 border text-xs">
                                {mixorder.quantity} Mixes
                            </div>
                            </div>
                                </div>
                                <div className="w-full my-3">
                                    <p className="text-xs font-bold text-right text-gray-600">{moment(mixorder.ordered_at).format('YYYY-MM-DD hh:mm A')}</p>
                                </div>
                        
                            </div>
                        
    
                            <div className="border-l w-1/2 px-2  ">
                        {mixorder.production && 
                            mixorder.production.map(prod=>
                                <div className="w-full  my-3 flex justify-between text-uppercase font-bold text-xs text-gray-600">
                                <p className="uppercase">{prod.productName}</p>
                                <p>{prod.quantity} pcs</p>
                            </div>
                            
                                )
                        }                                
                              
                            </div>
    
                        </div>
                    </div>
                    )
                    :
                    <div className="w-full  lg:w-1/2 text-center">
                    <div className="rounded-lg w-full shadow border-2 border-blue-600 py-3 my-2">
                        <div className="w-full flex justify-between  text-blue-600 py-1 px-5 ">
                                <span className="material-icons-outlined">
                                feedback
                    </span>
                            <p className="font-extrabold"> No Payments Found</p>
                        </div>
                    </div>
                    
                            </div>

                    

                }








            </div>

            {/* chart side */}

            <div className="w-full lg:w-2/5 h-72 font-bold text-xs  p-3">
            {
                summary && 
                summary.length > 0 &&



                <div className="w-full">
                    {/* <p className="font-bold my-3 text-left ">Overall Mix Summary</p> */}
                    <div className="w-full flex">

                <p className="font-bold py-2 rounded-lg border-blue-600 text-blue-600 border  my-3 text-left px-3">Overall Mix Summary</p>
                    </div>


                    <div className="w-full max-h-72 overflow-y-scroll">

               

                    <table class="min-w-full leading-normal text-sm uppercase">
              <thead>
                <tr>
         
                  <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                   Mix
                  </th>
                  <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                  Quantity of Mixes
                  </th>
                </tr>
              </thead>
              <tbody>

            {
                summary && 
                summary.length > 0 &&

                summary.map(summary=> 


                    <tr class="align-top" >
                    <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                        <p class="text-gray-900 whitespace-no-wrap">{summary.mix}</p>
                      </td>
                      <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                        <p class="text-gray-900 whitespace-no-wrap">{summary.quantity}</p>
                      </td>
                    
                    </tr>


                    )
          

        
}
             </tbody>
             </table>

             </div>
                </div>

 }


                {/* {
                    graphLoading ? 
                    <Spinner />
                    :
                
                graphData &&
                graphData.data.length > 0 &&
                
                <> 
                   <div className="flex ">
                <p className="font-bold py-2 rounded-lg border-blue-600 text-blue-600 border  my-3 text-left px-3">Mix Summary</p>
              



                </div>
                <MixSummaryLine  results = {graphData} />
                    </>


                

                } */}


                {
                    summary &&
                    summary.length > 0 &&
                        <>
                    <div className="flex ">
                    <p className="font-bold py-2 rounded-lg border-blue-600 text-blue-600 border  my-3 text-left px-3">Mix Summary</p>
                   
                    </div>

<MixChartProduction result={summary} />

</>


                }
             

            </div>

        </div>


        </div>

        </div>
     );
}
 
export default ProductionReport;