import { useState } from "react";

const ReceivePaymentModal = ({...props}) => {

    const paymentMethods = ['Mpesa','Cash']

    const [smethod,setMethod] = useState('')


    const [amount,setAmount] = useState(0)

    const handleSubmit = () =>{
            amount > 0 && smethod.length > 0 &&
            props.receivePayment({amount:amount,mode:smethod})
    }

    return ( 


        <div id="" 
        className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          
                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          
          
              <div className=" inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl py-4 px-6 transform transition-all sm:my-8 w-full md:w-1/2 lg:w-1/3 " id="">
            
              
                <div className="flex mb-3 justify-between w-full pb-2 border-b">
                    <p className="font-bold text-lg">Receive Payment</p>
                    <span className="material-icons-outlined text-gray-700 cursor-pointer" onClick={()=>props.setOpenModal(false)}>
                      close
                      </span>
                </div> 
                
            <div className="w-full walkInData">


        <div className="text-gray-600 font-bold">
            <p className="text">Select Payment</p>
        </div>

        <div className="w-full flex flex-wrap my-3">
            {paymentMethods.map(method=>
                            <button onClick={()=>setMethod(method)} className={` ${smethod != method ? "border-gray-400 text-gray-400" : "border-blue-600 text-blue-600"  }   py-3 border px-4 mx-2 font-bold rounded`}>
                                        {method}
                            </button>
                )}

        </div>




         <div className="text-gray-500 w-full text-sm  my-3 text-left leading-relaxed mt-7">
                                <label className="font-bold text-base my-2" >Enter Amount</label> 
                                <input  type="text" onChange={(e)=>{setAmount(e.target.value)}} className="border border-1 rounded-sm  designation border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-4 px-3 w-full border-gray-200 " />
                              </div>
        

            </div>


                
                            <div className="w-full lg:w-2/3 mx-auto my-5">
                                                                              <button onClick={handleSubmit} className="bg-blue-600 w-full text-white rounded py-3 font-bold">Submit</button>
                                                                              </div>
                        
        
        
              
          
            
              </div>
            </div>
          </div>

     );
}
 
export default ReceivePaymentModal;