import { toast,ToastContainer } from "react-toastify";

export const notify = (msg) => {
    toast.error(msg,{
        autoClose:true,
        position:toast.POSITION.TOP_CENTER
    })
}

export  const contextClass = {
    success: "bg-blue-500",
    error: "bg-red-500",
    info: "bg-gray-300",
    warning: "bg-orange-400",
    default: "bg-indigo-600",
    dark: "bg-white-600 font-gray-300",
  };
