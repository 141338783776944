import {createSlice} from "@reduxjs/toolkit"

export const systemUserSlice  = createSlice({
    name: "systemuser",
    initialState: {
        users:null,
        groups:null,
        failed:false
    },
    reducers: {
        userSet: (state,action) => {
            state.users=action.payload
        },
        groupSet:(state,action)=>{
            state.groups = action.payload
        },
        failedUser:(state,action)=>{
            state.failed=true
        },
        clearFailed:(state,action)=>{
            state.failed=false
        }
    }
})


export const {userSet,groupSet,failedUser,clearFailed} = systemUserSlice.actions

export const systemusers = (state)=>state.systemusers

export default systemUserSlice.reducer