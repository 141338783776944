import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL

const token = localStorage.getItem('b_token')


export const fetchPendingOrders = async () => {
    return await axios.get(apiUrl+'distribution/pendingOrders',{
      headers: {
        'Authorization': `Token ${token}` 
}})
}


export const editingOrder = async (order,obj) => {
    
    return  axios.patch(apiUrl+'distribution/orderEdit/'+order,obj).then((res)=>{
    return res
  }).catch((error)=>{
    return null
  })
}