import AllDistributions from "./allDistributions"
import ShopDistribution from "./shopDistribution"
import AllOrders from "./allOrders"
import WarningAlert from "../../alerts/warningAlert"

import { useState } from "react"
import CollectOrders from "./modals/collectOrder"
import ReturnOrders from "./modals/returnsModal"
import PayOrders from "./modals/PayOrders"
import ShopReturns from "./modals/shopReturns"
import CollectShop from "./modals/collectShop"
import ConfirmPay from "./modals/confirmPay"
import ShopPay from "./modals/shopPay"
import ConfrirmPayShop from "./modals/confirmPayShop"
import OrderDamages from "./modals/damagesModal"
import AddDiscountModal from "./modals/addDiscount"

const SubmitDistribution = ({...props}) => {
    const distribution = []
   
    const deliveries = []
    // const shopStocks= distribution.shopstocks
    const shopStocks= []

    const [showCollect,setShowCollect] = useState(false)

    const [showReturns,setShowReturns] = useState(false) 

    const [showDamaged,setShowDamaged] = useState(false)

    const [discountModal,setDiscountModal] = useState(false)
    const [discountItem,setDiscountItem] = useState(null)

    // console.log('discountModal ....',discountModal);
    

    // console.log('selected order',props.selectedOrder)


    const [showPayOrder,setShowPayOrder] = useState(null)

   

    const [showCollectShop,setShowCollectShop] = useState(false)


    const [selectedShopStock,setSelectedShopStock] = useState(null)

    const [showReturnShop,setShowReturnShop] = useState(null)
    

    const [showConfirmPay,setShowConfirmPay]  = useState(false)


    const [payObj,setPayObj] = useState(null)

    const [showPayShop,setShowPayShop] = useState(false)

    const [confirmShop,setConfirmShop] = useState(false)

    const [payShopObj,setPayShopObj] = useState(null)



    return ( 


<div className="w-full  flex flex-wrap  ">
<div className="w-full   text-left my-2 p-2">
 {props.pendingOrders.length > 0 ? <AllOrders orders={props.pendingOrders} setShowCollect={setShowCollect}  setSelectedOrder={props.setSelectedOrder} /> : <WarningAlert message="Distributed Orders Not Available" />  }          
</div>
{/* <div className="w-full lg:w-1/4 text-left my-2 p-2">

</div> */}
{/* 
<div className="w-full lg:w-1/4 text-left my-2 p-2">
               <div className="w-full overflow-y-scroll  max-h-96">
               {deliveries.length > 0 ? <AllDistributions getDelivery={props.getDelivery} delivery={props.delivery} deliveries={deliveries} /> : <WarningAlert message="Deliveries Not Available" />  }
                 </div>

                 <div className="w-full overflow-y-scroll my-2 max-h-48">
            {shopStocks.length > 0 &&  <ShopDistribution  showCollectShop={setShowCollectShop} setSelectedShopStock={setSelectedShopStock} shopstocks={shopStocks} /> }
                 </div>

           </div> */}

{showCollect && (<CollectOrders setDiscountModal={setDiscountModal} setDiscountItem={setDiscountItem} setShowCollect={setShowCollect} setShowDamaged={setShowDamaged}  setShowPayOrder={setShowPayOrder} setShowReturns={setShowReturns} distribution={props.distribution} selectedOrder={props.selectedOrder}  />)}
{showReturns && (<ReturnOrders returnDist={props.returnDist}  addPayment={props.addPayment} setShowCollect={setShowCollect} setShowReturns={setShowReturns} distribution={props.distribution} selectedOrder={props.selectedOrder}   />)}
{showDamaged && (<OrderDamages addDamages={props.addDamages}  addPayment={props.addPayment} setShowCollect={setShowCollect} setShowDamaged={setShowDamaged} distribution={props.distribution} selectedOrder={props.selectedOrder}   />)}


{discountModal && <AddDiscountModal addDiscountApi={props.addDiscountApi} setDiscountModal={setDiscountModal} discountItem={discountItem} setShowCollect={setShowCollect} />}

{showPayOrder && (
            <PayOrders setPayObj={setPayObj} setShowConfirmPay={setShowConfirmPay} setShowCollect={setShowCollect} setShowPayOrder={setShowPayOrder} distribution={props.distribution} selectedOrder={props.selectedOrder}   />
        )}







{   
               showCollectShop && (
                   <CollectShop shopstock = {selectedShopStock} setShowPayShop={setShowPayShop} setShowCollectShop={setShowCollectShop}  distribution={props.distribution} setShowReturnShop={setShowReturnShop } />
               )
           } 

{    showReturnShop && (
                  <ShopReturns returnShop={props.returnShop} shopstock = {selectedShopStock} setShowCollectShop={setShowCollectShop}  distribution={props.distribution}  setShowReturnShop={setShowReturnShop } />
              )
          }



{
    showPayShop &&  <ShopPay setShowCollectShop={setShowCollectShop} payShopObj={setPayShopObj}  setConfirmShop={setConfirmShop} shopstock={selectedShopStock} setShowPayShop={setShowPayShop}  />
} 


        {
            showConfirmPay && <ConfirmPay  addPayment={props.addPayment} payObj={payObj} setShowConfirmPay={setShowConfirmPay} setConfirmShop={setConfirmShop}   setShowPayOrder={setShowPayOrder}  />
        }


{confirmShop && <ConfrirmPayShop addShopSales={props.addShopSales} payShopObj={payShopObj} setConfirmShop={setConfirmShop} setShowPayShop={setShowPayShop} /> }


</div>




);

        
      

    
}
 
export default SubmitDistribution;