
//  cstockout  report api
import axios from 'axios'
import { success_abr_sor,error_abr_sor,clear_error_abr_sor,loading_abr_sor } from '../../features/reports/sor_slice'

const apiUrl = process.env.REACT_APP_API_URL


export const getStockOutReport = (start,end,product) => dispatch => {
    return new Promise (async (resolve,reject) => {
            try {
                dispatch(loading_abr_sor())
                const res =await axios.get(apiUrl+`reports/stockoutreport/${start}/${end}/${product}`)
                res.status == 200 ? dispatch(success_abr_sor(res.data)) : dispatch(error_abr_sor(res.data))
            }catch{

            }
    } )
}

