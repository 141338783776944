import { useState,useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { addUser } from "../../../../api/systemAPI";
import { systemusers } from "../../../../features/sytemuserSlice";

const AddUsers = () => {

    const [showModal,setShowModal] = useState(false)  


  const dispatch = useDispatch()

  const [selectedGroup,setSelectedGroup] = useState(null)

    useEffect(() => {
      setSelectedGroup(null)
    }, [showModal])


    const [user,setUser] = useState({first_name:null,last_name:null,username:null,email:null,password:null,groups:null})

    const handleInput = (val,target) => {
        setUser({...user,[target]:val})
    }

    const nongroups = ['Driver','Customer']

    const groups = useSelector(systemusers)

    const handleSubmit = () => {
      user.groups=selectedGroup.toString().split("")
    
      dispatch(addUser(user)).then((res)=>{
        res == 'success' && 
        setShowModal(false)
      }).catch((err)=>{
        console.log(err)
      })
    }

   

    return ( 
        
        <>
<button onClick={()=> { setShowModal(true) }} className="h-13 w-13 my-2 focus:outline-none px-3 py-2 text-white bg-gray-400 rounded-xl text-xs">
   Add Users
  </button>


        {showModal && 
    <>
                <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                    {/*content*/}
                <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        
          
        
                <div className="flex justify-between px-3 pt-5 pb-1">
                            <p className="text-xl font-bold text-blue-600">Add Users</p>
                            <button   className="focus:outline-none text-gray-400"><span className="material-icons-outlined" onClick={()=>setShowModal(false)}>close</span></button>
                          </div>
        
        
                          <div className="relative px-4 w-full pb-3    mt-4 text-left">
                                       
                    
           <div className="flex w-full flex-wrap">
           <div className="text-gray-400 px-1 w-full lg:w-1/2 my-2 text-left leading-relaxed">
                    <label>First Name</label>
                    <input type="text" onChange = {(e)=>{
                      handleInput(e.target.value,"first_name")
                  }
                    } className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>

                    <div className="text-gray-400 px-1 w-full lg:w-1/2 my-2 text-left leading-relaxed">
                    <label>Last Name</label>
                    <input type="text" onChange = {(e)=>{
                      handleInput(e.target.value,"last_name")
                  }
                    } className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>
        
        
        
           </div>

           <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Username</label>
                    <input type="text" onChange = {(e)=>{
                      handleInput(e.target.value,"username")
                  }
                    } className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                </div>

        
           <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Email</label>
                    <input type="email" onChange = {(e)=>{
                      handleInput(e.target.value,"email")
                  }
                    } className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>

            
                    <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Password</label>
                    <input type="password" onChange = {(e)=>{
                      handleInput(e.target.value,"password")
                  }
                    } className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>
     
                    

                    <div className="w-full mt-4">
                        <p className="font-bold">
                            Select User Role 
                            </p>  

                        <div className="w-full flex flex-wrap mt-4">
                         {
                            groups && 
                            groups.groups.map(group=>
                                ! nongroups.includes(group.name) ? 
                               selectedGroup == group.id ? 
                               <button onClick={()=>setSelectedGroup(group.id)} className="border-2 m-1 border-blue-600 text-blue-600 capitalize text-sm font-bold rounded-full px-4 py-1">{group.name}</button>
                               :
                               <button onClick={()=>setSelectedGroup(group.id)} className="border-2 m-1 border-gray-300 text-gray-400 capitalize text-sm font-bold rounded-full px-4 py-1">{group.name}</button>
                              : ''                                 

                                )
                         }
                        </div>

                    </div>




                    <div className="w-full mt-5 mb-3 mx-auto lg:w-2/3">
                        <button className="outline:none bg-blue-600 text-white w-full py-3 rounded font-bold" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
        
        
        
                        
        
                        
         
        
                            </div>
              
        
        
        
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        }

        </>

     );
}
 
export default AddUsers;