import { useState,useEffect, Fragment } from "react";
import ProceedToCustomer from "./ProceedToCustomer";

export default function AddDistributionModal({...props}) {
  // const [showModal, setShowModal] = useState(false);

  // receive order modals
  const showModal = props.orderModal
  const setShowModal = props.setOrderModal


  useEffect(() => {
    props.setOrderObj([])
}, [])

  const showCustomers = props.showCustomers
  const setShowCustomers = props.setShowCustomers

  const distribution = props.distribution

  const items = distribution.items

  const deliveries = distribution.deliveries


  const [selectedI, setSelected] = useState([]);

  const [dist, setDist] = useState([]);

  const [del,setDelivery] = useState(null)
  





  const makeSelected = (id, product) => {
    setSelected([...selectedI, id]);
    setDist([...dist, {product: id, quantity: 0,price:0 }]);
  };
  

  
  const removeSelected = (id) => {
    setSelected(selectedI.filter((product) => product !== id));
    setDist(dist.filter((product) => product.product !== id));
  };



  const captureValues = (e) => {
    dist.map(
      (d) => d.product === parseInt(e.target.name) && (d.quantity = e.target.value)
    );
    setDist([...dist], dist);
  };





  const handleSubmit = ()=> {

    const obj = {
      distribution : distribution.id,
      products : dist,
      delivery:del,
    }

    
    props.setOrderObj(obj)
  
    setShowModal(false)
    setShowCustomers(true)



  }


  return (
    <>
      <button
        onClick={() => {
          setShowModal(true);
        }}
        className="h-13 w-13 my-2 focus:outline-none px-3 py-2 text-white bg-gray-400 rounded-xl text-xs"
      >
        Distribute Orders
      </button>
      <br />

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">
              {" "}
              {/*content*/}
              <div className="border-1 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header section */}

                <div className="flex justify-between px-6 pt-5 pb-1">
                  <p className="text-xl font-bold">Distribute Orders</p>
                  <button
                    onClick={() => setShowModal(false)}
                    className="focus:outline-none text-gray-400"
                  >
                    <span className="material-icons-outlined">close</span>
                  </button>
                </div>

                {/*body*/}
                <div className="relative px-6 pb-3 flex-auto">
                  <div className="flex w-full px-3 pb-5">
                    <div className="w-full mt-5 mb-4 text-blue-600 text-left max-h-48 overflow-y-scroll">
                      <p className="font-bold   my-3">Select Products</p>
                      {
                      items.filter(x=>x.remaining > 0).map((product) => {
                        if (selectedI.includes(product.id)) {
                          return (
                            <button
                              className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-not-allowed border-blue-600 font-bold px-4 py-2 text-sm text-blue-600`}
                              onClick={() => removeSelected(product.id)}
                            >
                              {" "}
                              <span
                                className={`material-icons-outlined  text-sm text-blue-600`}
                              >
                                done
                              </span>{" "}
                              {product.productdetails.name}
                            </button>
                          );
                        } else {
                          return (
<button className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-pointer border-gray-300 font-bold px-4 py-2 text-sm text-gray-500`}
      onClick={() =>
        makeSelected(product.id, product.productdetails.name)
      }
                            >
                              {" "}
                              <span 
                                className={`material-icons-outlined hidden text-sm text-blue-600`}
                              >
                                done
                              </span>
                              {" "}
                              {product.productdetails.name}
                            </button>
                          );
                        }
                      })}
                    </div>
                  </div>

                  {selectedI.length > 0 ? (
                    <Fragment>
                      <div className="flex text-blue-600 font-bold px-6 py-1">
                        <p>Enter Product Amount</p>
                      </div>

                      <div className="flex flex-wrap max-h-48  overflow-y-scroll overflow-x-hidden mt-4 sm:m-2  px-2 py-3">
                        {items.map((product) => {
                          if (selectedI.includes(product.id)) {
                            return (
                              <div className="w-full flex my-1  px-1 shadow py-2">
                                <p class="py-3 w-full font-bold text-black text-left px-2">
                                  {product.productdetails.name}
                                </p>
                                <input
                                  type="number"
                                  placeholder="Enter Amount"
                                  onChange={captureValues}
                                  name={product.id}
                                  className="border border-1 my-1 rounded-lg text-sm   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 mr-3 focus:ring-gray-400 focus:ring-opacity-400 py-1 px-2 w-full border-gray-200"
                                />
                              </div>
                            );
                          }
                        })}
                      </div>

                      <div className="w-full px-4 text-blue-600 mt-3 text-left max-h-12 font-bold">
                          Select Delivery
                      </div>
                      <div className="w-full flex flex-wrap p-3 max-h-36 overflow-y-scroll">

                      {deliveries.map(delivery =>  {
  if (delivery.id === del) {
  return (
    <button onClick={()=>{
      setDelivery(delivery.id)
    }} className="border border-blue-600 text-blue-600 text-sm font-bold py-2 px-6 rounded-full m-2">
                      {delivery.driver_name} | {delivery.plate_no}
      </button>
  )
}
else{
 return (
   <>
   
  <button onClick={()=>{
    setDelivery(delivery.id)
  }} className="border border-gray-300 text-gray-400 text-sm font-bold py-2 px-6 rounded-full m-2">
                    {delivery.driver_name} | {delivery.plate_no}
    </button>
    </>
 )
}
}

         )}

                       

                        

                      </div>

                      <div className="flex flex-wrap overflow-hidden">
                        <div className="w-full lg:w-1/2 my-3 mx-auto text-center">
                          <button
                            className="bg-blue-600 w-full py-3 focus:outline-none text-white rounded-lg"
                                             onClick={handleSubmit}     >
                            Submit Delivery Order
                          </button>
                        </div>
                      </div>

               
       
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
