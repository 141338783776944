import {loginPending,loginSuccess,loginFailed, resetFailed} from '../features/loginSlice'
import { useDispatch,useSelector } from 'react-redux'
import axios from 'axios'
import { UserDetails } from './userAPI'
import { logoutUser } from '../features/userSlice'
const apiUrl = process.env.REACT_APP_API_URL

export const LoginUser =  (data) => dispatch => {
    return new Promise (async (resolve,reject) => {

       
        try {
            dispatch(loginPending())

            const res = await axios.post(apiUrl+'auth/token/',data)
            if (res.status == 200) {
              localStorage.setItem('b_token',res.data.key)
              dispatch(UserDetails())
              dispatch(loginSuccess()) 
            }
          
        }catch (error) {
            dispatch(loginFailed('Incorrect Username or Password'))
            setTimeout(() => {
                dispatch(resetFailed())
            }, 3000);
            reject(error)
        }

    }

    )


}

export const Logout = () =>dispatch => {

    dispatch(logoutUser())
}


export const PasscodeLogin = (pass) => dispatch => {
    console.log(pass)
    return new Promise (async (resolve,reject) => {

       
        try {
            dispatch(loginPending())

            const res = await axios.get(apiUrl+'auth/passcode/'+pass)
            if (res.status == 200) {
              localStorage.setItem('b_token',res.data.key)
              dispatch(UserDetails())
              dispatch(loginSuccess()) 
            }
          
        }catch (error) {
            dispatch(loginFailed('Incorrect Passcode'))
            reject(error)
        }

    }
    )
}