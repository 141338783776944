import FabUsers from "../layouts/users/FabUsers";
import BreadCrumbs from "../universal/breadcrumbs";
import Navbar from "../universal/navbar";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchGroups, fetchUsers } from "../../api/systemAPI";
import UserList from "../layouts/users/userList";
const Users = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchGroups())
        dispatch(fetchUsers())
    }, [])

    return ( 
        <div className="relative mx-auto ">
        <Navbar active="users"/>
        <div className="w-full px-6">
        <BreadCrumbs newPage="System Users" />
        
 
         <FabUsers  />  
        
        <UserList />



     
           
        </div>

        </div>
     );
}
 
export default Users;