
//  customer sales  report api

import axios from 'axios'
import { loading_abr_csr,success_abr_csr,error_abr_csr } from '../../features/reports/csr_slice'

const apiUrl = process.env.REACT_APP_API_URL
const token = localStorage.getItem('b_token')


export const getCustomerSalesReport = (start,end,customer) => dispatch => {
    return new Promise (async (resolve,reject) => {
            try {
                dispatch(loading_abr_csr())
                const res = await axios.get(apiUrl+`reports/customersalesreport/${start}/${end}/${customer}`,{
                    headers: {
                      'Authorization': `Token ${token}` 
            }})
                res.status == 200 ? dispatch(success_abr_csr(res.data)) : dispatch(error_abr_csr(res.data))
            }catch{

            }
    } )
}

