// slice Customer Sales  Report
import {createSlice} from "@reduxjs/toolkit"

export const customersalesreportslice = createSlice({
    name:"customersalesreports",
    initialState: {
        loading:false,
        error:false,
        errorMsg:null,
        data:null,
        selected_data:null
    },

    reducers: {
        loading_abr_csr: (state)=> {
            state.error = false;
            state.errorMsg = null;
            state.loading = true;
        },
        success_abr_csr: (state,action)=>{
            state.loading= false;
            state.data = action.payload;
        },
        error_abr_csr: (state,action)=>{
            state.loading = false;
            state.errorMsg = action.payload;
            state.error = true
        },
        clear_error_abr_csr:(state)=>{
            state.error = 'false'
        }
    }
})


export const  {success_abr_csr,loading_abr_csr,error_abr_csr,clear_error_abr_csr} = customersalesreportslice.actions

export const csr_states = (state) => state.customersalesreports

export default customersalesreportslice.reducer