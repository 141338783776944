import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL

const token = localStorage.getItem('b_token')

// fetch crates

export const fetchCrates = async () => {
    return await axios.get(apiUrl+'crates',{
        headers: {
          'Authorization': `Token ${token}` 
  }})
}

// fetching drivers with crates

export const fetchDrivers = async () => {
  return await axios.get(apiUrl+'crates/driver',{
    headers: {
      'Authorization': `Token ${token}` 
}})
}


// adding crates

export const addCrateAPI = async (obj) => {
  return await axios.post(apiUrl+'crates/receive', obj, {
    headers: {
      'Authorization': `Token ${token}`
    }
  })
}



// issuing crates

export const  issueCrateAPI = async (obj) => {
  return await axios.post(apiUrl+'crates/issue',obj,{
    headers:{
      'Authorization': `Token ${token}`
    }
  })
}


// returning crates


export const returnCrateAPI = async  (obj) =>{
  return await axios.post(apiUrl+'crates/return',obj,{
    headers:{
      'Authorization': `Token ${token}`
    }
  })
}