
//  mix pricing report api
import { success_abr_mpr,error_abr_mpr,clear_error_abr_mpr,loading_abr_mpr } from '../../features/reports/mpr_slice'
import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL




export const getMixPricingReport = (mix) => dispatch => {
    return new Promise (async (resolve,reject) => {
            try {
                dispatch(loading_abr_mpr())
                const res = await axios.get(apiUrl+'reports/mixpricingreport/'+mix)
                res.status == 200 ? dispatch(success_abr_mpr(res.data)) : dispatch(error_abr_mpr(res.data))
            }catch{

            }
    } )
}

