import OrdersCard from "./ordersCard"

const AllOrders = ({...props}) => {
    return ( 
        <>
        <div className="flex text-blue-600 bg-blue-200 inline-flex my-1 font-bold px-3 py-1 rounded ">Distrubuted Orders</div>
        <div className="w-full flex flex-wrap mt-1">
        {props.orders.map(order=> 
            <div className="w-full lg:w-1/4 w-flex my-2 px-1">
               <OrdersCard  setShowCollect={props.setShowCollect}  setSelectedOrder={props.setSelectedOrder} order={order}  />
           </div>
            )}
             

        </div>
        </>
    );
}
 
export default AllOrders;