import { success_ingredients,error_ingredients,loading_ingredients,clear_error_ingredients } from "../features/stock/ingredients_slice";
import axios from 'axios'



const apiUrl = process.env.REACT_APP_API_URL


export const fetchIngredients = () => dispatch=>{
    return new Promise (async (resolve,reject)=> {
        try{
            dispatch(loading_ingredients())
            const res =await axios.get(apiUrl+'stock/')
            // console.log(res)
            res.status == 200 ? dispatch(success_ingredients(res.data)):dispatch(error_ingredients('Error Fetching Ingredients'))
        }catch{

        }
    })
}


// export const fetchProducts = () => dispatch => {
//     return new Promise (async (resolve,reject) => {
//         try {
//             dispatch(loading_products())
//             const res = await axios.get(apiUrl+'products/')
//         }catch(e){

//         }
//     })
// }

// export const addMixExpense = (data) => {
//     axios.post(apiUrl+'products/mixexpenses',data).then((res)=> {
//         res.status == 201 && fetchProducts()
//     })
 
// }

