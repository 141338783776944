import { useState } from 'react'

export default function AddOrderModal({...props}) {

    const [showModal,setShowModal] = useState(false)  
    
    const [selectedProduct,setSelelectedProduct] = useState()
    
    const products = props.products
    
    const [mixno,setMixNo] = useState(null)


    const makeSelected = (id) =>{
        setSelelectedProduct(id)
    }

    const handleSubmit = () => {
      const obj = {
         'quantity':mixno,
         'mix': selectedProduct,
         'ordered_by': 1,
         'status':'initialized'
       }
      props.addOrderMix(obj)

       setShowModal(false)

    }



    return (

        <>

<button onClick={()=> { setShowModal(true) }} className="h-13 w-13 my-2 focus:outline-none px-3 py-2 text-white bg-gray-400 rounded-xl text-xs">
    New Order
  </button>
        <br />

        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-2/3 lg:w-1/3 xl:w-1/3  px-3">                {/*content*/}
                            
                <div className="border-1 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                  {/* header section */}

                  <div className="flex justify-between px-6 pt-4 pb-2 border-b">
                    <p className="text-xl font-bold">Make An Order</p>
                    <button onClick={()=> setShowModal(false)} className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                  </div>

                
                  <div className="w-full mt-5 mb-4 px-6 text-left">
                      <p className="font-bold my-3" >Select Product</p>

                    <div className="w-full flex flex-wrap overflow-y-scroll max-h-48">

        {products === null ? (<p>Loading......</p>): (            
            
          
                  products.map(product=> 
                    product.mix  &&  (
                    selectedProduct === product.mix.id ?
               (     <button key={product.mix.id} className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-pointer border-blue-600 font-bold px-4 py-2 text-xs uppercase text-blue-600`} onClick={()=> makeSelected(product.mix.id) }> <span className={`material-icons-outlined  text-xs  text-blue-600`}>done</span> {product.name}</button>)
                 :                         
                    ( <button key={product.mix.id} className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-pointer border-gray-300 font-bold px-4 py-2 text-xs  uppercase text-gray-500`} onClick={()=> makeSelected(product.mix.id) }> <span className={`material-icons-outlined hidden text-xs text-blue-600`}>done</span> {product.name}</button>)
                    
                    )
                    
                  )
           
              
        ) }

</div>
                    </div>

                  <div className="w-full">

                  </div>

                  {/*body*/}
                  <div className="relative px-6 pb-3 flex-auto">

                    <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label class="text-black" >No of Mixes  </label>
                    <input type="number" onChange={(e)=> { setMixNo(e.target.value) }} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-4 px-5 w-full border-gray-200" />
                    </div>

              
                   
                      <div className="w-full lg:w-2/3 mx-auto">
                    <button className="mt-4 py-3 bg-blue-600 w-full font-bold focus:outline-none text-bold text-white rounded-xl mb-4" onClick={handleSubmit} >Submit</button>
                      </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
  