import AddStockModal from "./modals/addStockModal"
import { useState } from "react"
import EnterStockDetails from "../orders/modals/enterStockDetails"

const FabStock = ({...props}) => {
    const [orderModal,setOrderModal] = useState(false)

    const [prodMenuState,setMenuState] = useState('hidden')


    const [showMain,setShowMain] = useState(false)

    const [showDetails,setShowDetails] = useState(false)

    const [addedStock,setAddedStock] = useState()

    const showMore = ()=> {
        if (prodMenuState === 'hidden'){
          setMenuState('')
        }else{
          setMenuState('hidden')
        }
      }

      const [selectedStock,setSelectedStock] = useState([])
    

    return ( 
        <>
        <div className={`hiddenFloat ${prodMenuState}  absolute bottom-24 right-8`}>
        <AddStockModal products={props.products} showModal={showMain} setShowModal={setShowMain} setShowDetails={setShowDetails} addedStock={addedStock} setAddedStock={setAddedStock}  setSelectedStock={setSelectedStock} selectedStock={selectedStock} />
        </div>


        <button  onClick={showMore} 
        className="p-0 w-16 bottom-7 fixed right-8 h-16 bg-blue-600 text-center rounded-full hover:bg-blue-900 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">  
          <span className="material-icons-outlined text-white">add</span>
      </button>


      {showDetails && 
      <EnterStockDetails setShowDetails={setShowDetails} addStock={props.addStock} setShowMain={setShowMain} addedStock={addedStock} setAddedStock={setAddedStock}  />
      }






        </>
     );
}
 
export default FabStock;