import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL

const token = localStorage.getItem('b_token')

// console.log(token)


export const fetchCustomers = async () => {
    return await axios.get(apiUrl+'accounts/receivableClients',{
        headers: {
          'Authorization': `Token ${token}` 
}})
}



export const fetchCustomerDetails = async (id) => {
    return await axios.get(apiUrl+'accounts/receivableClients/'+id,
    {
        headers: {
          'Authorization': `Token ${token}` 
}}
    )
}


export const addingPayments = async (obj) => {


      return  axios.post(apiUrl+'distribution/bulkPayments',obj,{
        headers: {
          'Authorization': `Token ${token}` 
  }}).then((res)=>{
    return res
  }).catch((error)=>{
    return null
  })





}