import { Fragment,useState,useEffect } from 'react'

import {useSelector,useDispatch} from 'react-redux'

import { userSession,userRole } from '../../../../features/userSlice'

import { cashierManagement } from '../../../../features/permissions'



const CollectOrders = ({...props}) => { 

    const order = props.selectedOrder
    const role = useSelector(userRole)

    
   

    return ( 
        <Fragment>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
            {/*content*/}
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

  

        <div className="flex justify-between px-3  pb-2 border-b">
                    <p className="text-xl font-bold text-blue-600">#{order.id} | {order.customer.name}</p>
                    <button  onClick={()=>{
                        props.setShowCollect(false)                    
                    }}  className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                  </div>


                  <div className="relative px-4 w-full pb-3    mt-5 text-left">
                               
             

                  <div className="w-full my-5 mb-7 flex flex-wrap max-h-40 overflow-y-scroll">
                    
                    {order.products.map(product=>
                           <div className="w-full flex my-1 px-1">
                           <div className="w-full shadow-sm px-2 py-4  flex flex-col justify-between ">

                                <div className="w-full flex justify-between">
                                <p className="font-bold capitalize">{product.product_name}</p>
                             <div className="flex">
                             <div className="font-bold text-sm mr-2 flex-none px-3 py-1 bg-blue-600 text-white rounded-full">{parseFloat(product.quantity)} pcs</div>
                             <div className="font-bold text-sm mr-2 flex-none px-3 py-1 bg-red-500 text-white rounded-full">{product.returned} pcs</div>
                             <div className="font-bold text-sm flex-none px-3 py-1 bg-yellow-600 text-white rounded-full">{product.damaged_items} pcs</div>
                                </div>




                             </div>
                           
                             <div className="w-full flex justify-between py-2 items-center">
                                <button className="py-2 px-3 text-sm font-bold rounded bg-yellow-600 text-white" onClick={()=>{
                                  props.setDiscountItem(product)
                                  props.setShowCollect(false)
                                  props.setDiscountModal(true)
                                }} >Add Discount </button>
                                  <p className="font-bold"> = KSH {product.total}</p>
                                </div>

                           </div>                               
                     </div>

                        )}

                 

                       
                  </div>


                  <div className="w-full lg:w-1/2 flex-col border-blue-600 rounded-lg ml-auto text-right border border-blue-600 px-3 max">
                        <div className="my-3 text-blue-600 text-sm  font-bold ">
                            <span className="capitalize">{order.delivery.driver_name} | <span className="uppercase">{order.delivery.plate_no}</span></span>
                        </div>
                       
                    </div>


                      <div className="w-full flex flex-wrap mt-6 mb-4">

                   

    

              <div className="flex mx-auto w-full lg:w-1/2 px-2 my-1 ">
                        <button  className="w-full py-3 border-2 border-red-400 text-red-400 focus:outline-none text-white font-bold rounded-lg" onClick={()=>{
                          props.setShowReturns(true)
                          props.setShowCollect(false)
                          }}>Receive Returns  </button>
              </div>


              <div className="flex mx-auto w-full lg:w-1/2 px-2 my-1 ">
                        <button  className="w-full py-3 border-2 border-yellow-600 text-yellow-600 focus:outline-none text-white font-bold rounded-lg" onClick={()=>{
                          props.setShowDamaged(true)
                          props.setShowCollect(false)
                          }}>Damaged Items  </button>
              </div>


              {role && 
      cashierManagement.includes(role) && 

              <div className="flex mx-auto w-full lg:w-1/2 px-2 my-1 ">
                        <button  className="w-full py-3 border-2 border-green-400 text-green-400 focus:outline-none text-white font-bold rounded-lg" onClick={()=>{
                          props.setShowCollect(false)
                          props.setShowPayOrder(true)
                          }}>Collect Payments  </button>
              </div>

                        }

              </div>
                    </div>
      



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</Fragment>
     );
}
 
export default CollectOrders;