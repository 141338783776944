import Navbar from "../universal/navbar";
import BreadCrumbs from "../universal/breadcrumbs";
import { fetchCustomers } from "../../api/accountingAPI";
import { BlueLoader } from "../universal/loaders";
import { useEffect,useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";


const Customers = () => {

    const [loading,setLoading] = useState(false)

    const [customers,setCustomers] = useState([])



    const gettingCustomers = async () => {
        setLoading(true)
        const customers = await fetchCustomers()
        setLoading(false)
        setCustomers(customers.data)
    }


    useEffect(() => {
        gettingCustomers()
    }, []);
    



    return ( 
        <div className="w-full">
                  <Navbar />
                  <div className="w-full px-6">
                         <BreadCrumbs newPage="Customer Accounts" />   



{ loading ? 
<div className="w-full flex justify-center items-center h-48">
<BlueLoader />
</div>

:

customers && 
customers.length > 0  &&

<section className="my-3 w-full p-3">
  
  <div className="w-full p-2 flex flex-wrap">


    {customers.map(customer=> 
        
        <div className="w-full lg:w-1/4 px-2  ">
        <Link to={`/customer/${customer.pk}`}>
        <div className="w-full px-2 shadow rounded py-5 spareCards cursor-pointer  ">
            <div className="flex justify-between items-center px-2">
             <div className="py-1 px-3 rounded bg-blue-600 text-white" >
              <span className="material-icons-outlined">
                supervisor_account
                </span>
             </div>
             <div className="text-right">
                <p className="text-gray-600 font-bold text-sm capitalize">{customer.name}</p>
              
             </div>
             
            </div>
            <div className="flex my-1">
              <div className="ml-auto font-bold text-xs">{moment(customer.joined_at).format('Do MMM  YYYY')}</div>
            </div>
        </div>
    </Link>
        </div>

        
        )}






  </div>

</section>


}



                  </div>
        </div>
     );
}
 
export default Customers;