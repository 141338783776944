import { useState } from "react";
const AddDiscountModal = ({...props}) => {
    const product = props.discountItem

    const [discount,setDiscount] = useState( {
        qty: product.discount ? product.discount.qty.toString() :product.quantity.toString(),
        price: product.discount ? product.discount.price.toString() : product.price.toString()
    })


    const handleSubmit = () =>{
        console.log('hand')
        if (discount.qty.length > 0 && discount.price.length > 0) {
            props.addDiscountApi(discount,product.id)
            props.setDiscountModal(false)
            props.setShowCollect(true)
        }else{
            console.log(discount.price.length)
        }
        
    }



    return ( 
        <div id="" 
        class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          
              <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          
                  <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          
          
              <div class=" inline-block align-middle bg-white rounded-2xl text-left overflow-hidden shadow-xl py-4 px-6 transform transition-all sm:my-8 w-full md:w-1/2 lg:w-1/4 " id="">
            
              
                <div class="flex mb-3 justify-between w-full pb-2 border-b">
                    <p class="font-bold text-lg">Collect Discount <span className="py-1 text-white bg-yellow-600 font-bold px-3 ml-4 rounded">{product.product_name}</span></p>
                    <span class="material-icons-outlined text-gray-700 cursor-pointer" onClick={()=>{
                        props.setDiscountModal(false)
                        props.setShowCollect(true)
                    }}>
                      close
                      </span>
                </div> 
                
            <div class="w-full walkInData">
            <div className="text-gray-500 w-full text-sm  my-3 text-left leading-relaxed">
                                <label className="font-bold text-base my-1" >Items Above</label> 
                                <input value={discount.qty} onChange={(e)=>{
                                    setDiscount({...discount,qty:e.target.value})
                                }} type="text" className="border border-1 rounded-sm  designation border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-3 px-3 w-full border-gray-200 " />
                                </div>
    
                            <div className="text-gray-500 w-full text-sm  my-3 text-left leading-relaxed">
                            <label className="font-bold text-base my-1" >Price</label> 
                            <input onChange={(e)=>{
                                    setDiscount({...discount,price:e.target.value})
                                }} value={discount.price}  type="text" className="border border-1 rounded-sm  designation border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-3 px-3 w-full border-gray-200 " />
                            </div>
            
                                
                                <div className="w-full lg:w-2/3 mx-auto">
                                    <button className="py-3 text-white bg-yellow-600 w-full rounded font-bold my-3" onClick={handleSubmit} >Add Discount</button>
                                </div>

            </div>
        
        
        
              
          
            
              </div>
            </div>
          </div>
        
     );
}
 
export default AddDiscountModal;