import { useState,Fragment } from 'react'
import AddUsers from './modals/addUsers'


const FabUsers = ({...props}) => {  
        
  const [prodMenuState,setMenuState] = useState('hidden')

  const showMore = ()=> {
    if (prodMenuState === 'hidden'){
      setMenuState('')
    }else{
      setMenuState('hidden')
    }
  }
  // console.log(props.products)

  return ( 
  <Fragment className="">
  <div className={`hiddenFloat ${prodMenuState}  fixed  bottom-24 right-8`}>
   <AddUsers  />
  </div>

    
  
  <button  onClick={showMore} 
    className="p-0 w-16 bottom-7 fixed right-8 h-16 bg-blue-600 text-center rounded-full hover:bg-blue-900 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">  
      <span className="material-icons-outlined text-white">add</span>
  </button>
  </Fragment>
     );
}
 
export default FabUsers;