
//  mix pricing report api
import { success_abr_ps,error_abr_ps,clear_error_abr_ps,loading_abr_ps } from '../../features/reports/productSales_slice'
import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL




export const fetchProductSalesReport = (start,end,product,customer) => dispatch => {
    return new Promise (async (resolve,reject) => {
            try {
                dispatch(loading_abr_ps())
                const res = await axios.get(`${apiUrl}reports/productsales/${start}/${end}/${product}/${customer}`)
                res.status == 200 ? dispatch(success_abr_ps(res.data)) : dispatch(error_abr_ps(res.data))
            }catch{

            }
    } )
}

