
import moment  from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import {Redirect} from "react-router-dom";

const CustomerSalesReportTable = ({data,setSelectedO,setShowModal}) => {


    const redirectL = () => {
        <Redirect to="/customerOrderViews" ></Redirect>
    }


    return ( 
        data.length > 0 ? 

        

        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">


          <div
            class="inline-block min-w-full shadow-md rounded overflow-hidden"
          >
            <table class="min-w-full leading-normal text-sm uppercase">
              {/* <thead> */}
                <tr>
                <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                   Customer
                  </th>


                  <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                   Total Sales
                  </th>


                  <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                   Total Paid
                  </th>

                  <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                   Total Balance
                  </th>

                  <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                   Order Summary
                  </th>
                 
          
                </tr>
              {/* </thead> */}
              <tbody>


            {data.map(customer_d=> 
                     <tr class="align-top" >
                     <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                         <p class="text-gray-900 whitespace-no-wrap">{customer_d.name}</p>
                       </td>
                       <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                         <p class="text-gray-900 whitespace-no-wrap">{customer_d.summary.overall_total}</p>
                       </td>
                       <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                         <p class="text-gray-900 whitespace-no-wrap">{customer_d.summary.overall_paid}</p>
                       </td>
                       <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                         <p class="text-gray-900 whitespace-no-wrap">{customer_d.summary.overall_balance}</p>
                       </td>

                       <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                           {
                               customer_d.orders.length > 0 ?   

                            <button class="text-xs bg-green-200 text-green-600 text-xs font-bold rounded-lg px-3 py-1" onClick={()=>{
                                setSelectedO(customer_d)
                                setShowModal(true)
                            }} >View Orders</button>


                               :
                               'N/A'
                           }
                       </td>


                        
                     </tr>
              
                )}
                              
               
              
                

              </tbody>
            </table>
          </div>
        </div>
        :
        <div className="w-full lg:w-1/2 text-center">
        <div className="rounded-lg w-full shadow border-2 border-blue-600 py-3 my-2">
            <div className="w-full flex justify-between  text-blue-600 py-1 px-5 ">
                    <span className="material-icons-outlined">
                    feedback
        </span>
                <p className="font-extrabold"> Results Not Found </p>
            </div>
        </div>
        
                </div>
     );
}
 
export default CustomerSalesReportTable;