import WarningAlert from "../../alerts/warningAlert";
import ProductRecipeShow from "../products/modals/productRecipeShow";
import AllDistributions from "./allDistributions";
import DistributionDashboard from "./distributionDashboard";
import FabDistribution from "./FabDistribution";


const PerformDistribution = ({...props}) => {
    const distribution = props.distribution

    const deliveries = distribution.deliveries

    const distributionItems = distribution.items

    return ( 
        <> 
        <FabDistribution addShopStock={props.addShopStock} addOrderSell={props.addOrderSell} vehicles={props.vehicles}  distribution={props.distribution} addDelivery={props.addDelivery} drivers={props.drivers} fetchDrivers={props.fetchDrivers} fetchVehicles={props.fetchVehicles} fetchCustomers={props.fetchCustomers} customers={props.customers}  />

        <div className="w-full flex flex-wrap items-start">
        <DistributionDashboard distribution={distribution} />
        <div className="w-full lg:w-1/3 text-left my-2 px-4">
               <div className="w-full overflow-y-scroll  max-h-96">
               {deliveries.length > 0 ? <AllDistributions getLastDist = {props.getLastDist} customers={props.customers} fetchCustomers={props.fetchCustomers}  getDelivery={props.getDelivery} items={distributionItems}  delivery={props.delivery} deliveries={deliveries} /> : <WarningAlert message="Deliveries Not Available" />  }
                 </div>
        </div>
        </div>

        
        </>
    );
}
 
export default PerformDistribution;