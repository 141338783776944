import Navbar from "../universal/navbar";
import BreadCrumbs from "../universal/breadcrumbs"
import {useEffect, useState} from 'react'
import FabCrate from "../layouts/crates/fabCrates";
import { fetchCrates, fetchDrivers,issueCrateAPI,returnCrateAPI,addCrateAPI } from "../../api/crateAPI";
import { BlueLoader, SBlueLoader, SGreenLoader, SYellowLoader } from "../universal/loaders";
import { createSelector } from "@reduxjs/toolkit";
import IssueCrates from "../layouts/crates/modals/issueCrate";
import ReturnCrate from "../layouts/crates/modals/returnCrate";


const Crates = ({...props}) => {

    const [crates,setCrates] = useState(null)
    const [loadCrate,setLoadCrate] = useState(false)
    const [errorCrate,setErrorCrate] = useState(null)
    const [errorDriver,setErrorDriver] = useState(null)
    const [drivers,setDrivers] = useState(null)


    const [selectedDriver, setSelectedDriver] = useState(null)



    const [openIssueModal,setOpenIssueModal] = useState(false)
    const [openReturnModal,setOpenReturnModal] = useState(false)





    const fetchingCrates  = async () => {
        const res = await fetchCrates()
        res.status == 200 ? setCrates(res.data): setErrorCrate(res.data)
    }


    const fetchingDrivers = async () => {
        const  res = await fetchDrivers()
        res.status == 200 ? setDrivers(res.data) : setErrorDriver()
    }

     useEffect(() => {   

        if(!drivers) {
            fetchingDrivers()
        }

        if(!crates){
            fetchingCrates()
        }

    }, []);




    const issueCrates = async (obj) => {
        setOpenIssueModal(false)
        const  res = await issueCrateAPI(obj)
        if(res.status == 201){
            fetchingDrivers()
            fetchingCrates()
        }else{
            console.log('Error')
        }
    }



    const returnCrates = async (obj)=> {
        setOpenReturnModal(false)

        const res = await returnCrateAPI(obj)
        if(res.status == 201){
            fetchingDrivers()
            fetchingCrates()
        }else{
            console.log('Error')
        }

    }


    const addingCrate = async  (obj) => {
        const res = await addCrateAPI(obj)
        if(res.status == 201){
            fetchingDrivers()
            fetchingCrates()
        }else{
            console.log('Error')
        } 
    }



    return ( 
        <div className="relative mx-auto ">
        <Navbar active="Cratesx"/>
        <div className="w-full px-2">
        <BreadCrumbs newPage="Crate" />



        

        <div className="w-full lg:w-1/3 mx-auto">
<div class="bg-white shadow p-2 flex">
                <span class="w-auto flex justify-end items-center text-gray-500 p-2">
                    <i class="material-icons text-3xl">search</i>
                </span>
                <input class="w-full rounded p-2 text-sm focus:outline-none searchMeals" type="text" placeholder="Search"/>
            </div>
        </div>



     
        


        <div className="w-full p-1 flex flex-wrap items-start justify-end">

            <div className="w-full flex flex-wrap lg:w-5/6 p-2">



            { !drivers ? 
            
            <div className="w-full flex h-48 items-center justify-center">
                <BlueLoader />
            </div>

            :
        
        



         drivers &&
         
         drivers.map(driver=> 
            

            <div className="lg:w-1/4 w-full  p-2 ">
            <div className=" rounded-lg shadow px-3 py-2">
                <div className="w-full flex flex-wrap p-3 items-center">
                    <div className="bg-blue-600 text-white px-3 py-2 rounded-full">
                        <span className="material-icons-outlined">
                            person
                        </span>
                    </div>
                    <p className="text-gray-600 font-bold ml-3">
                        {driver.name}
                    </p>
                </div>

                 <div className="w-full flex flex-wrap pb-4 justify-end items-start">
                    <button onClick={()=>{
                        setSelectedDriver(driver)
                        setOpenIssueModal(true)
                    }}  className="py-2 m-1 text-xs font-bold px-3 rounded-full border border-green-600 border text-green-600 ">
                        <span className="material-icons-outlined">
                            add
                        </span>
                    </button>
                    <button 
                        onClick={()=>{
                            setSelectedDriver(driver)
                            setOpenReturnModal(true)
                        }} 
                    
                    className="py-3 m-1 text-xs font-bold px-4 rounded-full border border-yellow-600 border text-yellow-600 ">
                        {driver.crates} Crates
                    </button>


                </div>


        </div>  
        </div>  

            
            
            ) 


        


        



            }

            </div>



            <div className="w-full lg:w-1/6 p-2" >
                <div className=" p-3 border bg-green-200 text-green-600 rounded-2xl">
                <div className="w-full flex p-2 items-center">
                    <div className="px-3 py-2 rounded-full bg-green-600 text-green-200 font-bold mr-3">
                    <span className="material-icons-outlined">
                        widgets
                    </span>
                    </div>

                    <p className="ml-2 font-bold">Available Crates</p>
                </div>

                <div className="my-2 flex px-3">
                    <div className="flex items-center">
        {crates ? 
           <p className="font-bold text-3xl">  
           {crates.remaining}
       </p>
        :
        <SGreenLoader />
    }
                
                    <p className="text-sm ml-2 font-bold ">Crates</p>
                    </div>
               
                </div>

            </div>


     
                <div className="p-3 my-3 border bg-yellow-200 text-yellow-600 rounded-2xl">
                <div className="w-full flex p-2 items-center">
                    <div className="px-3 py-2 rounded-full bg-yellow-600 text-yellow-200 font-bold mr-3">
                    <span className="material-icons-outlined">
                        unarchive
                    </span>
                    </div>

                    <p className="ml-2 font-bold">Issued Crates</p>
                </div>
                <div className="my-2 flex px-3">
                    <div className="flex items-center">
        {crates ? 
           <p className="font-bold text-3xl">  
           {crates.issued}
       </p>
        :
        <SYellowLoader />
    }
                
                    <p className="text-sm ml-2 font-bold ">Crates</p>
                    </div>
               
                </div>

            </div>
        

            </div>




        </div>


{openIssueModal && 
    <IssueCrates openModal={setOpenIssueModal} driver={selectedDriver} issueCrates={issueCrates}  remaining={crates.remaining} />
}


 { openReturnModal && 
    <ReturnCrate openModal={setOpenReturnModal} driver={selectedDriver} returnCrates={returnCrates} />
 }


       <FabCrate addingCrate={addingCrate} />
           
        </div>

        </div>
     );
}
 
export default Crates;