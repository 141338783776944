import moment from "moment";

const ExpenseCard = ({...props}) => {
    const expense = props.expense
    return ( 
        <div className="w-full p-2 lg:w-1/4">
            <div className="w-full shadow-sm border rounded-lg py-4 px-3">
                <div className="w-full flex justify-between tex-sm">
                    <p className="font-bold text-gray">#{expense.id}</p>
                    <p className="font-bold text-gray">{moment(expense.created_on).format('Y-MM-DD hh:mm:A')}</p>
                    {/* <p>{expen}</p> */}
                </div>
                <div className="w-full text-left my-2 uppercase text-xs text-gray-600">
                           {expense.title}
                </div>
                <div className="w-full text-left my-2 uppercase text-xs text-gray-600">
                           {expense.description}
                </div>
                <div className="w-full my-4 text-right">KSH {expense.amount}</div>
            </div>
        </div>
     );
}
 
export default ExpenseCard;