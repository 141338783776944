import CustomerOrder from "./modals/customerOrder"
import { useState,Fragment } from "react"

const CustomerOrders = ({...props}) => {
    const customers= props.customers

    const [showCustomer,setShowCustomer] = useState(false)

    const [customerselected,setcustomerselected] = useState(null)

    return ( 

        <>
{        customers.map(customer=>
            <div 
            onClick={()=>{setShowCustomer(true); setcustomerselected(customer.customer) }}
            className="rounded-lg shadow border-2 w-full border-yellow-400 uppercase py-3 my-2">
            <div className="w-full flex justify-between  text-yellow-400 py-1 px-5 ">
                <p className="text-sm font-bold">{customer.name}</p>
                <p className="font-extrabold">KES {customer.total}</p>
            </div>
        </div>
         )  }


         {showCustomer &&             
            <CustomerOrder order={props.dOrder} customer={customerselected} orderPerCustomer={props.orderPerCustomer} setShowCustomer={setShowCustomer} />
        }
        </>

     );
}
 
export default CustomerOrders;