import {useState,useRef,useEffect} from 'react'
import SLoad from '../../../universal/sload'
import {useDispatch,useSelector} from "react-redux"
import { setUserPasscode } from '../../../../api/systemAPI'
import { clearFailed, systemusers } from '../../../../features/sytemuserSlice'


const AddPasscode = ({...props}) => {   

    const digits = ["1","2","3","4","5","6","7","8","9"]

    const dispatch = useDispatch()

    const failed = useSelector(systemusers).failed

    console.log(failed)

    const [color,changeColor] = useState('bg-blue-500')
    const [borderColor,changeBorderColor] = useState('border-blue-500')
    const [textColor,changeTextColor] = useState('text-blue-500')


    // const [failed,setFailed] = useState(false)



    const [disabled,setDisabled] = useState(false)

    const [loading,setLoading] = useState(false)

    const [passcode,setPasscode] = useState([])

    const pressed = (value) => {
        // console.log(value)
        setPasscode(old=>[...old,value]) 
    }

    const erase = () => {
        setPasscode((pass) => pass.filter((_, i) => i !== pass.length - 1))
    }


    const passRef = useRef(null)

    useEffect(() => {
        var passInputs = document.getElementsByClassName('inputs')  
        for (var i=0; i < 4 ; i++ ){
            passInputs[i].textContent=''
    }

    // console.log(passcode)
        if (passcode.length < 5) {
        for (var i=0; i < passcode.length ; i++ ){
                passInputs[i].textContent='*'
        }

        if (passcode.length  === 4) {
            setDisabled(true)
            setLoading(true)
            submitValue()
        }

    }
    }, [passcode])


    const submitValue = () => {
        let code = passcode.join('')
        const sendData = {passcode:code,user:props.user.id}
        dispatch(setUserPasscode(sendData)).then((res)=>{
            res == 'success' &&
            props.showPass(false)
          }).catch((err)=>{
            console.log(err)
          })
        // dispatch(PasscodeLogin(parseInt(code)))
    }

    useEffect(()=>{
      if (failed){
        changeColor('bg-red-400')
        changeBorderColor('border-red-400')
        changeTextColor('text-red-400')
        setLoading(false)
        setTimeout(() => {
          dispatch(clearFailed())
          setDisabled(false)
          setPasscode([])
        }, 2000);
      }else{
        changeColor('bg-blue-500')
        changeBorderColor('border-blue-500')
        changeTextColor('text-blue-500')
      }
    },[failed])



    return (
        <>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
            {/*content*/}
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">




                  <div className="relative px-4 w-full pb-3  flex   mt-5 justify-between">
            {failed ?
                  <p className="text-xl font-bold text-red-400 capitalize">Enter Unique Passcode</p>
                  :
                  <p className="text-xl font-bold text-blue-500">{props.user.first_name+' '+props.user.last_name}'s Passcode</p>
          
            }
                            <button   className="focus:outline-none text-gray-400"><span className="material-icons-outlined" onClick={()=>props.showPass(false)}>close</span></button>


                    </div>


        <div className="w-full flex justify-center px-6">

          <div className="w-1/4 p-2 ">
            <div className={`border-2 ${borderColor} h-16 w-16 rounded-full text-6xl ${textColor} flex justify-center items-center `}>               <p className="mt-6 inputs"></p> 
            </div>
          </div>

          <div className="w-1/4 p-2 justify-center">
            <div className={`border-2 ${borderColor} h-16 w-16 rounded-full text-6xl ${textColor} flex justify-center items-center `}>
               <p className="mt-6 inputs"></p> 
            </div>
          </div>

          <div className="w-1/4 p-2 justify-center">
            <div className={`border-2 ${borderColor} h-16 w-16 rounded-full text-6xl ${textColor} flex justify-center items-center `}>
               <p className="mt-6 inputs"></p> 
            </div>
          </div>

          <div className="w-1/4 p-2 justify-center">
            <div className={`border-2 ${borderColor} h-16 w-16 rounded-full text-6xl ${textColor} flex justify-center items-center `}>               <p className="mt-6 inputs"></p> 
            </div>
          </div>



        </div>


            <div className="w-full">
                <div className="w-full lg:w-2/3 mx-auto flex flex-wrap">

                    {digits.map(digit=>
                                         <div className="w-1/3 my-2 mx-auto">`
                                        {disabled ? 
                                         <button  disabled    className={`w-16 h-16 rounded-full my-2  mx-4 flex font-bold text-2xl text-white ${color} justify-center items-center focus:outline-none`}>
                                          {digit}
                                         </button>
                                    :
                                    <button       onClick={()=>pressed(digit)} className={`w-16 h-16 rounded-full my-2  mx-4 flex font-bold text-2xl text-white ${color} justify-center items-center focus:outline-none`}>
                                    {digit}
                                   </button>

                                        }

                                         </div>
                 
                        )}

                        <div className="w-1/3"></div>

<div className="w-1/3 my-2 mx-auto">
{disabled ? 
<button disabled className={`w-16 h-16 rounded-full my-2 mx-4 flex font-bold text-2xl text-white  ${color} justify-center items-center focus:outline-none`}>
0
</button>
:
<button  className={`w-16 h-16 rounded-full my-2 mx-4 flex font-bold text-2xl text-white  ${color} justify-center items-center focus:outline-none`}>
0
</button>
}
</div>

<div className="w-1/3 my-2 mx-auto">

    {loading ?  <SLoad /> : 
    
    <button className={`w-16 h-16 rounded-full my-2 mx-4 flex font-bold text-sm text-white ${color} justify-center items-center focus:outline-none`} onClick={erase}>
                                          delete
                                         </button> 
    
    }
                                    
                                           

                                         </div>
       




                </div>
            </div>




        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-gray-300"></div>
</>
    )
}


export default AddPasscode
