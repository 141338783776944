import {createSlice} from "@reduxjs/toolkit"

// slice stock our report 

export const stockoutreportSlice = createSlice({
    name:"stockoutreports",
    initialState: {
        loading:false,
        error:false,
        errorMsg:null,
        data:null,
    },

    reducers: {
        loading_abr_sor: (state)=> {
            state.error = false;
            state.errorMsg = null;
            state.loading = true;
        },
        success_abr_sor: (state,action)=>{
            state.loading= false;
            state.data = action.payload;
        },
        error_abr_sor: (state,action)=>{
            state.loading = false;
            state.errorMsg = action.payload;
            state.error = true
        },
        clear_error_abr_sor:(state)=>{
            state.error = 'false'
        }
    }
})


export const  {success_abr_sor,loading_abr_sor,error_abr_sor,clear_error_abr_sor} = stockoutreportSlice.actions


export const  store_sor = (state) => state.stockoutreports


export default stockoutreportSlice.reducer
