import Navbar from "../universal/navbar";
import BreadCrumbs from "../universal/breadcrumbs"
import { useState } from "react";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useQuery } from "@tanstack/react-query";
import { fetchPaymentReport } from "../../api/reportAPI";
import { createImageFromInitials, getRandomColor } from "../utils/randomPics";
import { leadingZero } from "../utils/leadingZero";
import Spinner from "../universal/spinner";
import moment from 'moment'



const PaymentReport = () => {


    const [startDate,setStartDate] = useState(null)
    const [endDate,setEndDate] = useState(null)

    const { isLoading, isError, data, refetch, isFetching } = useQuery(['search', startDate, endDate], 

    () => fetchPaymentReport(startDate,endDate),
    {
      enabled: false,
      refetchOnWindowFocus: false,

    }
    );

    const format_date = (date)=> {
      return moment(date).format('YYYY-MM-DD')
    }


    const handleSubmit = () => {
      if (startDate && endDate) {
        setStartDate(format_date(startDate))
        setEndDate(format_date(endDate))
        refetch()
      }
    }


    console.log(isFetching)
    console.log(data)


// const handleSubmit = () => {
//   queryClient.refetchQueries('search');

// }

    return ( 
     <div className="relative mx-auto ">
        <Navbar active="expenses"/>
        <div className="w-full px-0 lg:px-6">
        <BreadCrumbs newPage="Payment Report" />
           
        
        <div className="w-full p-3 flex flex-wrap items-end">
            <div className="w-full text-left lg:w-1/6 p-2">
            <p className="text-gray-500 font-bold py-3">Start Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setStartDate(e.target.value)} />    
            </div>

            <div className="w-full text-left lg:w-1/6 p-2">
            <p className="text-gray-500 font-bold py-3">End Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setEndDate(e.target.value)} />    
            </div>

            <div className="w-full text-left lg:w-1/6 p-2">

<button disabled={isFetching} className="w-full font-bold border-2 border-blue-600 text-blue-600 mb-2 focus:outline-none rounded-lg py-3 disabled:opacity-50"  onClick={handleSubmit} >

            {
                isFetching ? 
                "Fetching Payments" :
                "Get Payments"
            }

</button>

</div>

            </div>

        </div>




                {/* table area */}


        {
           isFetching ? 
            <Spinner />
            :
            data &&
            data.length < 1 || data == null ?
            <div className="w-full  lg:w-1/2 text-center m-3">
            <div className="rounded-lg w-full shadow border-2 border-blue-600 py-3 my-2">
                <div className="w-full flex justify-between  text-blue-600 py-1 px-5 ">
                        <span className="material-icons-outlined">
                        feedback
            </span>
                    <p className="font-extrabold"> Results Not Found </p>
                </div>
            </div>
            
                    </div>
            :

    
        <div class="-mx-4 sm:-mx-8 px-9 sm:px-4 py-4 overflow-x-auto">
          <div
            class="inline-block min-w-full shadow-md rounded overflow-hidden m-6"
          >
            <table class="min-w-full leading-normal">
              <thead>
                <tr>

                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                    Received On
                  </th>

                <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                    Order No
                  </th>

                <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Client
              </th>
          


              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Amount
              </th>

                </tr>
              </thead>
              <tbody>



                {
                data && 
                data.map(order=> 
                
                
              

          
            <tr class="align-top" >


<td class="px-5 py-2 border-b border-gray-200 bg-white text-sm text-left">

{moment(order.received_on).format('YYYY-MM-DD hh:mm A')}    
            </td>   


                <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm text-left">
                    <p class="text-gray-900 whitespace-no-wrap">{leadingZero(order.order.order_no,4)}</p>
                  </td>


                  <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 w-10 h-10">
                      <img className="rounded-full" src={createImageFromInitials(40,  order.order.customer, getRandomColor()) } alt="" /> 
    
                      </div>

                      <div class="ml-3">
                        <p class="text-gray-900 whitespace-no-wrap capitalize">
                        {order.order.customer}
                        </p>
                      
                      </div>
                    </div>
                  </td>


                  <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm text-left">

               {order.amount}     
            </td>   


            </tr>


)}

<tr class="align-top" >


<td class="px-5 py-2 border-b border-gray-200 bg-white text-sm text-left">
                    <p className="font-bold text-lg">
                      TOTAL
                    </p>

            </td>   


                <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm text-left">
                   
                  </td>


                  <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                   
                  </td>


            <td class="px-5 py-2 border-b border-gray-200 bg-white text-lg font-bold text-left">
                 KES {data.reduce((a,b)=> a+parseFloat(b.amount),0)}                
            </td>   


            </tr>


                
            </tbody>
            </table>
            </div>
            </div>
            


        }





        </div>







     );
}
 
export default PaymentReport;