import moment from 'moment'
const DistributionCard = ({...props}) => {
    const delivery = props.delivery
    const showdelivery = props.setShowDel

    const showMore = () => {
        showdelivery(true)
        props.setSelectedDel(delivery)
    }

    return ( 
        <div className="w-full  border border-yellow-500 rounded  py-2 px-3">
        <div className="w-full flex justify-between mb-2">
            <p className="text-yellow-500 font-bold">{delivery.driver_name} | {delivery.plate_no}</p>
            <p className="text-xs font-bold">{moment(delivery.created_at).format('Do MMM YY hh:mm A')}</p>
        </div>
        <div className="flex justify-between text-sm my-2">
            <p className="font-bold text-gray-500">KSH {delivery.total}</p>
            <p>
            <span className="material-icons-outlined text-gray-500" onClick={showMore} >more_vert</span>                   
            </p>
        </div>
</div>
     );
}
 
export default DistributionCard;