import { createSlice } from "@reduxjs/toolkit";

export const reportSlice  = createSlice ({
    name: 'reportS',
    initialState:  {
        isLoading:false,
        error:null,
        reportData:null,
        success:false,
        fetchGraph:false,
        graphLoading:false,
        graphData: null,
        successDist:false,
        errorDistribution:false,
        distributionReport:null


    },
    reducers: {
        reportLoading: (state) => {
            state.isLoading = true
            state.error = false
            state.success = false
        },
        successReport: (state,action) => {
            state.success = true
            state.isLoading = false
            state.reportData = action.payload
            state.fetchGraph = true
        },
        errorReport: (state) => {
            state.success = false
            state.error = true
            state.isLoading = false
        },
        loadingGraph: (state) => {
            state.graphLoading = true
            state.fetchGraph = false
        },
        successGraph: (state,action) => {
            state.graphLoading = false;
            state.graphData = action.payload
        },
        successDistribution: (state,action)=>{
            state.successDist = true
            state.isLoading = false
            state.errorDistribution = false
            state.distributionReport = action.payload
        }
        

    }


})


export const {successReport,reportLoading,errorReport,loadingGraph,successGraph,successDistribution} = reportSlice.actions

export const reportState =  (state) => state.reports

export default  reportSlice.reducer