import { createImageFromInitials,getRandomColor } from "../../utils/randomPics";
const UserCard = ({...props}) => {
    const user = props.user
    const notallowed = ['','Driver','Customer']

    const enterPass = () => {
        console.log('ww')
        props.showpass(true)
        props.userSet(user)
    }
    // console.log(user)
    return ( 

        
            user.groups.length > 0 ?       
             !notallowed.includes(user.groups[0].name) ? 
             <div className="w-full lg:w-1/4 p-2">
             <div className="shadow-sm  w-full rounded text-sm p-4">
                 <div className="flex w-full ">
               <div className="flex-none">
               <img className="rounded-full " src={createImageFromInitials(50,  user.first_name == '' ? 'Not Available' : user.first_name + ' ' + user.last_name, getRandomColor()) } alt="" />
                   </div> 
                 <div className="w-full text-left ml-4 ">
                 <p className="font-bold ">{user.first_name+' '+user.last_name}</p>
                 <p className="text-gray-300 font-bold">{user.email}</p>
                 <div className="w-full text-right flex  justify-end items-end">
                    <div className="">
                    <div className="px-4 text-xs flex-none py-1 rounded-full font-bold bg-blue-200 text-blue-600">{user.groups[0].name}</div>
                    </div>
                    {
                        user && 
                        user.groups[0].name == 'Chefs' && 
                        <div className="flex-none">
                            <button onClick={enterPass} className="bg-blue-200 flex-none  text-blue-500 text-xs px-3 rounded-lg py-1 cursor-pointer  inline-flex ml-3  items-bottom">
                             <span class="material-icons-outlined">
                        pin
                        </span>
                        </button>
                        </div>
                    }
                 </div>
                 </div>
                 </div>
                 
 
             </div>
         </div>
         : ''
        : ''
        

     );
}
 
export default UserCard;