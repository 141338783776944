import { useState,Fragment } from "react";
const EnterStockDetails = ({...props}) => {

    const [invoice,setInvoice] = useState(null)

    const [supplier,setSupplier] = useState(null)

    const fullSubmit = () => {

       const submitObj = {
            created_by: 1,
            supplier:supplier,
            stock_items:props.addedStock,
            invoice_no:invoice,
            status:'active'
        }

        console.log(submitObj)

        props.addStock(submitObj)
        props.setShowDetails(false)

 

    }

    return ( 
<Fragment>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
            {/*content*/}
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

  

        <div className="flex justify-between px-3 pt-5 pb-1">
                    <p className="text-xl font-bold text-blue-600">Enter Stock Details</p>
                    <button   onClick={()=>{
                        props.setShowMain(true)
                        props.setShowDetails(false)
                    }} className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
        </div>


                
     <div className="relative px-6 pb-3 flex-auto text-left">
        
        <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
        <label>Invoice No  </label>
        <input type="text"  name="invoiceNo" onChange={(e)=>setInvoice(e.target.value)} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
        </div>



        <div className="text-gray-400 w-full my-2 mt-4 text-left leading-relaxed">
        <label>Supplier's Name</label>
        <input type="text"  onChange={(e)=>setSupplier(e.target.value)} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
        </div>


       

        <button className="mt-4 py-3 bg-blue-600 w-full focus:outline-none text-bold text-white rounded-xl mb-4" onClick={fullSubmit}>Submit</button>

      </div>         
                    

 

              
      



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</Fragment>
     );
}
 
export default EnterStockDetails;