import {createImageFromInitials,getRandomColor} from "../../utils/randomPics"
import { leadingZero } from "../../utils/leadingZero";
import moment from "moment";

const DistributionTableReport = ({data,setOrder,setShowModal,no_client}) => {
      
    const showDetails = (order) => {
      setOrder(order)
      setShowModal(true)
    }

    return ( 




        <div class="py-3">

        {data ? 
        
    

        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div
            class="inline-block min-w-full shadow-md rounded overflow-hidden"
          >
            <table class="min-w-full leading-normal">
              <thead>
                <tr>
                <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                    Order No
                  </th>

              {
                no_client &&
                <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                Client
              </th>
              }

               
                  <th



                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                    Ordered At
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >Description</th>
                </tr>
              </thead>
              <tbody>

                {data.map(order=>
                    
                    
                  

                <tr class="align-top" >
                <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 whitespace-no-wrap">{leadingZero(order.id,4)}</p>
                  </td>

                  {
                    no_client && 
                 

                  <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 w-10 h-10">
                      <img className="rounded-full" src={createImageFromInitials(40,  order.customer.name, getRandomColor()) } alt="" /> 
    
                      </div>

                      <div class="ml-3">
                        <p class="text-gray-900 whitespace-no-wrap capitalize">
                        {order.customer.name}
                        </p>
                      
                      </div>
                    </div>
                  </td>

}
                  
                  <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                    <p class="text-gray-900 whitespace-no-wrap">{moment(order.ordered_at).format('YYYY-MM-DD hh:mm A')}</p>
                  </td>
                  <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">

                    {order.status == 'paid' ? 

                    <span
                      class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                    >
                      <span
                        aria-hidden
                        class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                      ></span>
                      <span class="relative text-xs">Paid</span>
                    </span>

                    :
                    <span
                    class="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight"
                  >
                    <span
                      aria-hidden
                      class="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                    ></span>
                    <span class="relative text-xs">Unpaid</span>
                  </span>

                }

                  </td>

                  <td class="px-5 py-1 border-b border-gray-200 bg-white text-sm text-right" >
                  <div class="w-full" id="editEmployeeDataArea text-xs" >

                  { order.products.map(product=>
                                            <div className=" border-b text-xs"> 
                                            <div className="flex justify-between">
                                            <p className="font-bold" >{product.product_name}</p>
                                            <p><span className="">{product.price} X {product.quantity} pcs</span></p>
                                            </div>   
                                            <div className="w-full mb-2 text-right -m-1">
                                               = {product.total}
                                            </div> 
                                            </div>
                                        )
                
}
             </div>      

             <div className="text-sm font-bold text-right pb-4 ">
              KSH  {order.totals}     
            </div>   

                  </td>
                  {/* <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm text-right"
                  >
                    <button 
                      onClick={()=>showDetails(order)}
                      type="button"
                      class="inline-block text-gray-500 hover:text-gray-700"
                    >
                      <svg
                        class="inline-block h-6 w-6 fill-current"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z"
                        />
                      </svg>
                    </button>
                  </td> */}
                </tr>
    
    )}
    
              </tbody>
            </table>
          </div>
        </div>

        :

        <div className="w-full  lg:w-1/2 text-center">
        <div className="rounded-lg w-full shadow border-2 border-blue-600 py-3 my-2">
            <div className="w-full flex justify-between  text-blue-600 py-1 px-5 ">
                    <span className="material-icons-outlined">
                    feedback
        </span>
                <p className="font-extrabold"> Results Not Found </p>
            </div>
        </div>
        
                </div>

        }

      </div>

     );
}
 
export default DistributionTableReport;