const SearchOrders = ({...props}) => {
    return (         
        <div className="w-full flex flex-wrap searchBar mt-5">
                <div className="flex-grow my-1 px-2">
                <input type="search" placeholder="Search Orders"  className="px-5 py-3 border w-full text-sm  rounded-lg  focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400" /> 
                </div>
                {/* { !props.isKitchen  && 
                <div className="w-full lg:w-1/6 my-1 px-2">
                    <button className="w-full py-2 border-2 border-red-500 text-red-500  font-bold rounded-lg hover:text-white hover:bg-red-500 focus:outline-none" onClick={()=>props.setIsKitchen(true)}>Cook's Portal</button>
                </div>
        } */}
                           
        </div>
     );
}
 
export default SearchOrders;