import { useEffect,useState } from "react";
const EditOrder = ({...props}) => {
    const order = props.order
    const customers = props.customers
    const items = props.items


    const [orderItems,setOrderItem] = useState([])

    const [del,setDel] = useState(order.delivery.id)



    useEffect(() => {
        ! props.customers && props.fetchCustomers()
    }, []);
    


    const selectItem = (item) => {
        orderItems.find(x=>x.product == item) ? setOrderItem(orderItems.filter(x=>x.product != item)) : setOrderItem([...orderItems,{product:item,quantity:1,price:0}])
    }


    const updateSelected = (val,target) => {
        orderItems.map(x=>x.product==target && (x.quantity=val))
        setOrderItem([...orderItems],orderItems)
    }


    useEffect(()=>{
        setOrderItem(order.products.map(x=> ({product:x.product,quantity:x.quantity,price:x.price})))
    },[])




    const submitOrder = () =>{
        // setting up prices

        if (props.customers){
            
            const customer_prices = props.customers.find(x=>x.id === order.customer.id).prices

            console.log('customer rpices area', customer_prices);

            // console.log(items.find(x=>x.id == 69).productdetails.id)



            
            
            const final_products = orderItems.map(x=>
                
              ({quantity:x.quantity,product:x.product,price: customer_prices.find(i=>i.product.id == items.find(item=>item.id == x.product).productdetails.id).price })

          
            )


            const orderObj = {
                delivery:del,
                products:final_products
            }


            props.editOrderFun(order.id,orderObj)
            props.setOpenModal(false)







        }


    }


    return ( 
    <>
    <div id="" 
    class=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      
              <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      
      
          <div class=" inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl py-4 px-6 transform transition-all sm:my-8 w-full md:w-1/2 lg:w-1/3 " id="">
        
          
            <div class="flex mb-3 justify-between w-full pb-2 border-b">
                <p class="font-bold text-lg">{order.customer.name}</p>
                <span class="material-icons-outlined text-gray-700 cursor-pointer" onClick={()=>props.setOpenModal(false)} >
                  close
                  </span>
            </div> 
            
        <div class="w-full walkInData">
    
        <div className="w-full mt-5 mb-4 text-blue-600 text-left max-h-48 overflow-y-scroll">
                      <p className="font-bold text-gray-600  my-3">Select Products</p>

                    <div className="w-full flex flex-wrap max-h-48 overflow-y-scroll">

                        {
                            items.map(item=>
                                
                                <button onClick={()=>selectItem(item.id)} className={` ${ orderItems.find(x=>x.product == item.id) ? 'text-blue-600 border-blue-600' : 'border-gray-400 text-gray-400' } px-3 py-2 font-bold m-1  border py-2 rounded`}>
                                {item.productdetails.name}
                            </button>

                                )
                        }
                      
                    </div>



        </div>



        <div className="w-full">

        <div className="flex text-gray-600 font-bold  pt-4 border-t ">
                        <p>Enter Product Amount</p>
                      </div>

                      <div className="flex flex-wrap max-h-48  overflow-y-scroll overflow-x-hidden mt-4 sm:m-2  px-2 py-3">


               

                        {
                        orderItems.length > 0 &&
                        orderItems.map((item) => 
                    
                              <div className="w-full flex my-1  px-1 shadow py-2">
                                <p class="py-3 w-full font-bold text-gray-600 text-left px-2">
                                 
                                  {items.find(x=>x.id == item.product).productdetails.name}
                                </p>
                                <input
                                  type="number"
                                  onChange={(e)=>updateSelected(e.target.value,item.product)}
                                  value={item.quantity}
                                  placeholder="Enter Amount"
                                  className="border border-1 my-1 rounded-lg text-sm   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 mr-3 focus:ring-gray-400 focus:ring-opacity-400 py-1 px-2 w-full border-gray-200"
                                />
                              </div>
                         
                        )}
                      </div>


        </div>



            <div className="w-fulll">
            <div className="flex text-gray-600 font-bold  pt-4 border-t ">
                        <p>Enter Deliveries</p>
                      </div>

            <div className="w-full flex my-2 ">

                
            {
                           props.deliveries.map(dels=>
                                
                                <button onClick={()=>{setDel(dels.id)}}  className={` ${del == dels.id ? 'border-blue-600 text-blue-600' :'border-gray-400 text-gray-400' } px-3 py-2 font-bold m-1  border py-2 rounded`}>
                                {dels.driver_name} | {dels.plate_no} 
                            </button>

                                )
                        }

            </div>


            </div>


                        {orderItems.length > 0  &&


<div className="lg:w-2/3 w-full mx-auto my-5">
<button onClick={submitOrder} className="py-3 w-full text-white rounded bg-blue-600 font-bold">Edit Order</button>
</div>


                        }



        </div>
    
    
    
          
      
        
          </div>
        </div>
      </div>
    
    </>
     );
}
 
export default EditOrder;