import { useState } from 'react'

export default function AddClientModal({...props}) {

    const [showModal,setShowModal] = useState(false)   
    
    const [client,setClient] = useState({})
   
    const handleChange = (e) => {
      setClient({
        ...client,
        [e.target.name]:e.target.value
      })
     
    }

    const handleSubmit = () => {
      props.addClient(client)
      setShowModal(false)
    }

    return (
        <>

<button onClick={()=> { setShowModal(true) }} className="h-13 w-13 my-2 focus:outline-none px-3 py-2 text-white bg-gray-400 rounded-xl text-xs">
    Add Clients
  </button>
        <br />

        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-2/3 lg:w-1/3 xl:w-1/3  px-3">                {/*content*/}
                <div className="border-1 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                  {/* header section */}

                  <div className="flex justify-between px-6 pt-5 pb-1">
                    <p className="text-xl font-bold">Add Client</p>
                    <button onClick={()=> setShowModal(false)} className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                  </div>

                  {/*body*/}
                  <div className="relative px-6 pb-3 flex-auto">

                    <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Full Name  </label>
                    <input type="text"  name='name' onChange={e=>handleChange(e)} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>

                    <div className="flex flex-wrap w-full">
                        <div className="w-full lg:w-1/2 px-1 flex">
                        <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Phone No  </label>
                    <input type="text" name='phone' onChange={e=>handleChange(e)}  className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>
                        </div>
                        <div className="w-full lg:w-1/2 px-1 flex">
                        <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Email Address  </label>
                    <input type="text" name='email' onChange={e=>handleChange(e)} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>
                        </div>
                        <div className="w-full lg:w-1/2 px-1 flex">
                        <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Company Name  </label>
                    <input type="text" name='company' onChange={e=>handleChange(e)} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>
                        </div>
                        <div className="w-full lg:w-1/2 px-1 flex">
                        <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Physical Location  </label>
                    <input type="text" name='location' onChange={e=>handleChange(e)} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>
                        </div>
                    </div>

              
              
                   

                    <button className="mt-4 py-3 bg-blue-600 w-full focus:outline-none font-bold text-white rounded-xl mb-4" onClick={handleSubmit} >Submit</button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
  