import {Fragment,useState} from "react"


const ShopPay = ({...props}) => {

    const paymentMethods = ['cash','mpesa']

    const [pmethod,setMethod] = useState([])

    const shopstock = props.shopstock

    console.log(shopstock)

    const removeMethod = (method) => {
        setMethod(pmethod.filter(e=>e.mode !== method))
    }

    const addMethod = (method) => {
        setMethod([...pmethod,{mode:method,amount:0,recevied_by:1,shopstock:shopstock.id,status:'active'}])


    }

    const updateValues = (v,method) => {
      pmethod.find(x=>x.mode==method).amount=v  
    }


    // console.log(pmethod)

    const handleClick = () => {
      props.payShopObj(pmethod)
      props.setShowPayShop(false)
      props.setConfirmShop(true)

    }


    return ( 

        <Fragment>
                <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                    {/*content*/}
                <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        
          
        
                <div className="flex justify-between px-3 pt-5 pb-1">
                            <p className="text-xl font-bold text-blue-600"># MAIN SHOP</p>
                            <button onClick={()=>{
                                props.setShowCollectShop(true)
                                props.setShowPayShop(false)
                            }}   className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                          </div>
        
        
                          <div className="relative px-4 w-full pb-3    mt-4 text-left">
                                                         
              
         <div className="w-full my-3 text-gray-400 font-extrabold ">Select Payment Method</div>
                <div className="w-full flex flex-wrap my-5">
                {paymentMethods.map(method=>     
                pmethod.find(x => x.mode == method) ?      
        
                    <button className="border border-blue-600 text-blue-600 px-5 m-2 capitalize focus:outline-none rounded-full font-bold text-sm py-2" onClick={()=>removeMethod(method)} >
                          {method}
                    </button>
             
                    : 
                    <button className="border border-gray-400 text-gray-400 px-5 m-2 capitalize focus:outline-none rounded-full font-bold text-sm py-2" onClick={()=>addMethod(method)} >
                    {method}
              </button>

                          )}                      
                </div>
                        
         

            {pmethod.length > 0 && 
<>
<div className="flex text-blue-600 font-bold px-6 py-1">
<p>Enter Amount Received</p>
</div>

{pmethod.map(method=> 
    
    <div className="w-full px-3 py-1">
<div className="w-full flex my-1  px-1 shadow py-2">
                                <p class="py-3 w-full font-bold text-black text-left px-2  text-smm capitalize">
                                  {method.mode}
                                </p>
                                <input
                                  type="number"
                                  placeholder="Enter Amount"
                                  onChange={(e)=>updateValues(e.target.value,method.mode)}
                                  className="border border-1 my-1 rounded-lg text-sm   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 mr-3 focus:ring-gray-400 focus:ring-opacity-400 py-1 px-2 w-full border-gray-200"
                                />
                              </div>
</div>

    )}



    <div className="w-full lg:w-2/3 my-3 mx-auto">
    <button className="w-full bg-blue-600 rounded-lg py-4 text-white font-bold " onClick={handleClick}>Submit Payment</button>
    </div>


</>


                
            }

        
            </div>
              
        
        
        
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    
     );
}
 
export default ShopPay;