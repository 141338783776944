import { useState } from "react";
const AddStockModal = ({...props}) => {

  const showModal = props.showModal
  const setShowModal = props.setShowModal

  const products = props.products

  console.log(products)
 
  const [selected,setSelected] = useState([])


  const makeSelected = (id)=> {
      setSelected([...selected,{brand:id,quantity:0,price:0}])
  }

    console.log(selected)

  const removeSelected = (id) => {
      setSelected(selected.filter(x=>x.brand !== id))
  }

  const handleChange = (e,id,type) => {
      const target = selected.find(x=>x.brand == id)
      type == 'price' ?
      target.price = e.target.value
      :
      target.quantity = e.target.value

      console.log(target)
  }

  const handleSubmit = () => {
      props.setAddedStock(selected)
      setShowModal(false)
      props.setShowDetails(true)
  } 

  return ( 
        <>

        <button onClick={()=> { setShowModal(true) }} className="h-13 w-13 my-2 focus:outline-none px-3 py-2 text-white bg-gray-400 rounded-xl text-xs">
            Add Stock
          </button>
                <br />
        
                {showModal ? (
                  <>
                    <div
                      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                      <div className="relative w-auto my-6 mx-2 mx-auto max-w-xl">                {/*content*/}
                        <div className="border-1 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        
                          {/* header section */}
                      
                          <div v className="flex justify-between text-blue-500 border-b pb-2 mx-3 px-6 pt-5 pb-1">
                            <p className="text-xl font-bold ">Add Stock</p>
                            <button onClick={()=> setShowModal(false)} className="focus:outline-none "><span className="material-icons-outlined">close</span></button>
                          </div>
        
                          {/*body*/}

                                
                      
                      {products  !== null  && 
              products.length > 0 &&
<div className="w-full max-h-48 overflow-x-hidden overflow-y-scroll my-4 px-4">
<div className="w-full font-bold  text-gray-400 text-left mb-3 ">Select Products</div>
<div className="w-full flex flex-wrap ">
                       { products.map(product=>                                               
                          product.brands.map(brand=>

                          selected.find(x=>x.brand == brand.id) ? 
                            (<button onClick={()=>removeSelected(brand.id)} className="border border-blue-500 m-2  rounded-full text-blue-500 text-bold text-xs font-bold py-2 px-3 uppercase"> {product.name} | {brand.brand_name}</button>)
                            :
                            <button onClick={()=>makeSelected(brand.id)} className="border border-gray-500 m-2  rounded-full text-gray-500 text-bold text-xs font-bold py-2 px-3 uppercase"> {product.name} | {brand.brand_name}</button>
                        
                          
                          )  ) }
</div>   
</div>                            
                      }


                {

                  selected.length > 0 &&

                  <div className="w-full flex flex-col px-3 max-h-48 overflow-y-scroll pt-3 border-t ">

                  
{ products.map(product=>                                               
                          product.brands.map(brand=>

                          selected.find(x=>x.brand == brand.id) &&            
                    <div className="w-full my-2 shadow-sm flex px-2 py-2 items-center">
                      <div className="flex-grow text-left px-2 flex-none uppercase text-sm font-bold">                    
                      {product.name} | {brand.brand_name}
                      </div>
                      
                      <div className="w-1/4 px-2">
                      <input type="number" onChange={(e)=> { handleChange(e,brand.id,'quantity') }} placeholder="Enter QTY" minimum="1" className="border border-1 my-1  w-2/6 rounded text-sm w-full   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-3 px-3  border-gray-200" />
                    </div>  

                    <div className="px-3">
                        <p className="text-sm font-bold text-blue-600 uppercase border py-2 border-blue-600 uppercase px-4 rounded">{product.units}</p>
                      </div>

                    <div className="w-1/4 px-2 ">
                      <input type="number" onChange={(e)=> { handleChange(e,brand.id,'price') }} placeholder="Enter Price" minimum="1" className="border border-1 my-1  w-2/6 rounded text-sm w-full   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-3 px-3  border-gray-200" />
                    </div>                     
                    </div>                    

                    ) )}  

                    </div>

                }

                           

{selected.length > 0 && 
                          <div className="relative px-6 pb-3 flex-auto">
        
                            <button className="mt-4 py-3 w-full lg:w-2/3 mx-auto bg-blue-600 w-full focus:outline-none font-bold text-white rounded mb-4" onClick={handleSubmit}>Submit</button>
        
                          </div>
        }
                        </div>
                      </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                  </>
                ) : null}
              </>
     );
}
 
export default AddStockModal;