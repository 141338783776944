// slice Customer Sales  Report
import {createSlice} from "@reduxjs/toolkit"

export const productsalesreport = createSlice({
    name:"productsalesreport",
    initialState: {
        loading:false,
        error:false,
        errorMsg:null,
        data:null,
        selected_data:null
    },

    reducers: {
        loading_abr_ps: (state)=> {
            state.error = false;
            state.errorMsg = null;
            state.loading = true;
        },
        success_abr_ps: (state,action)=>{
            state.loading= false;
            state.data = action.payload;
        },
        error_abr_ps: (state,action)=>{
            state.loading = false;
            state.errorMsg = action.payload;
            state.error = true
        },
        clear_error_abr_ps:(state)=>{
            state.error = 'false'
        }
    }
})


export const  {success_abr_ps,loading_abr_ps,error_abr_ps,clear_error_abr_ps} = productsalesreport.actions

export const ps_states = (state) => state.productsalesreport

export default productsalesreport.reducer