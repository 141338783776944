const Spinner = () => {
    return ( 

    <div className="w-full flex justify-center h-48 items-center">

<div class="lds-ripple"><div></div><div></div></div>
    </div>

        
     );
}
 
export default Spinner;