import axios from "axios";
import { useState,useEffect } from "react";
import RemainingMixList from "./remainingMixList";
import { RedLoader } from "../../universal/loaders";
const RemainingMixes = () => {

    const apiUrl = process.env.REACT_APP_API_URL


    const fetchRemaining = async () => {
        setLoading(true)
        const res = await axios.get(apiUrl+'distribution/remainingMixes')
        setLoading(false)
        res.status == 200 && setRemainingMixes(res.data)
    }

    useEffect(()=>{
        fetchRemaining()
    },[])

    const [isLoading,setLoading] = useState(false)
    const [remainingMixes,setRemainingMixes] = useState([])

    return (     
        
        <div className="w-full mt-5 text-left">
            <p className="text-red-400 bg-red-200 font-bold inline-flex mt-3 px-4 py-1 rounded">Remaining Mixes</p>

            {isLoading ? 
              <div className="w-full flex h-48 items-center justify-center ">
              <RedLoader />
            </div>    
            :
            remainingMixes &&
            remainingMixes.length > 0 &&
            <div className="w-full max-h-48 mt-5 lg:max-h-72 overflow-y-scroll">
                <RemainingMixList mixes={remainingMixes} />
            </div>
            
        }


        </div>

     );
}
 
export default RemainingMixes;