import { Fragment,useEffect,useState } from "react";
import moment from 'moment'
import Load from "../../../universal/load";
const CustomerOrder = ({...props}) => {
  const customerselected = props.customer
  const orderPerCustomer = props.orderPerCustomer
  const [customer,setCustomer] = useState(null)
  const order  = props.order
  useEffect(() => {
    props.orderPerCustomer(customerselected)
  },[customerselected])


  console.log(order)




    return ( 

           <Fragment>
                   <div
                 className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
               >
                 <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                       {/*content*/}
                   <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg  relative flex flex-col w-full bg-white outline-none focus:outline-none">
                   <div className="flex justify-between px-3 pt-5 pb-1">
                     {order !== null && 
                               <p className="text-xl font-bold text-gray-600">{order[0].customer.name}</p>
    }
                               <button className="focus:outline-none text-gray-400" onClick={()=>props.setShowCustomer(false)} ><span className="material-icons-outlined">close</span></button>
                             </div>
           
                      {order === null ?  <div className="py-3 mx-auto text-center"> <Load />  </div>  : 

                        <>
                                   
                  
                      

                             <div className="relative px-4 w-full pb-10    mt-4 text-left max-h-56 overflow-y-scroll ">
                {order.map(o=>

                    <div className="w-full shadow px-3 my-1 py-4">
                          <div className="flex w-full font-bold py-3 justify-between border-b ">
                            <p>#{o.id}</p>
                            <p>{moment(o.ordered_at).format('hh:mm A')}</p>
                          </div>

                        {o.products.map(product=> 

                        <div className="w-full my-1 py-2 text-sm">
                          <div className="w-full flex justify-between">
                            <p>{product.product_name}</p>
                            <p>{product.quantity} X {product.price}</p>
                            </div>          
                            <div className="w-full text-right font-bold">
                                = KSH {product.total}
                              </div>              
                        </div>
         )}



                        <div className="w-full py-3 text-right font-bold text-gray-700">
                          KSH {o.totals}
                        </div>

                    </div>
                                    
                  
                  )}
        
                   </div>  
                        </>
                        
                      }

                   </div>      
        
                 </div>
               </div>
               <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
           </Fragment>

     );
}
 
export default CustomerOrder;