import { Fragment } from "react";
const CollectShop = ({...props}) => {

    const stocks = props.shopstock.stocks
   const distribution = props.distribution

    return ( 
     
        <Fragment>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
          
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

  

        <div className="flex justify-between px-3 pt-5 pb-1">
                    <p className="text-xl font-bold text-blue-600"># MAIN SHOP</p>
                    <button  onClick={()=>{
                        props.setShowCollectShop(false)                    
                    }}  className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                  </div>
                    
                  <div className="w-full text-xs text-left w-full px-4 lg:w-1/2 border mx-2 p-2 rounded border-green-400">
                    <p className="font-extrabold">KEY</p>
                    <div className="w-full my-2 flex items-center">
                      <div className="w-12 h-2 bg-green-200"></div>
                      <div className="text-gray-600 ml-4 font-extrabold">SOLD STOCK</div>
                    </div>
                    <div className="w-full my-2 flex items-center">
                      <div className="w-12 h-2 bg-red-200"></div>
                      <div className="text-gray-600 ml-4 font-extrabold">RETURNED STOCK</div>
                    </div>
                  </div>

                  <div className="relative px-4 w-full pb-3    mt-5 text-left">
                               
             

                  <div className="w-full my-5 mb-7 flex flex-wrap max-h-40 overflow-y-scroll">

                    {stocks.map(stock=>
                           <div className="w-full flex my-1 px-1">
                           <div className="w-full shadow-sm px-2 py-4  flex justify-between ">
                             <p className="font-bold capitalize">{distribution.items.find(x=>x.id == stock.product).productdetails.name}</p>
                             <div className="font-bold text-xs flex-none  flex text-white rounded-full">
                               
                               <div className="py-1 px-3 bg-green-200 text-green-600 m-1 rounded-full">{stock.sold_stock} pcs</div>
                               <div className="py-1 px-3 bg-red-200 text-red-600 m-1 rounded-full">{stock.returned_stock} pcs</div>
                               
                               
                               
                               </div>
                           </div>                               
                     </div>

                        )}

                 

                       
                  </div>





                      <div className="w-full flex flex-wrap mt-6 mb-4">

                   

              <div className="flex mx-auto w-full lg:w-1/2 px-2 my-1">
                        <button  className="w-full py-3 border-2 border-green-400 text-green-400 focus:outline-none text-white font-bold rounded-lg" onClick={()=>{
                          props.setShowCollectShop(false)
                          props.setShowPayShop(true)
                          }}>Collect Payments  </button>
              </div>

              <div className="flex mx-auto w-full lg:w-1/2 px-2 my-1">
                        <button  className="w-full py-3 border-2 border-red-400 text-red-400 focus:outline-none text-white font-bold rounded-lg" onClick={()=>{
                          props.setShowReturnShop(true)
                          props.setShowCollectShop(false)
                          }}>Receive Returns  </button>
              </div>

              </div>
                    </div>
      



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</Fragment>
     );
}
 
export default CollectShop;