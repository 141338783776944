import { useState } from "react";



const EditProductModal = ({...props}) => {

    const [product,setProduct] = useState(props.selectedProduct)



    const changeInput = (target,val) => {
            setProduct(prevState=>({
                ...prevState,
                [target]:val
            }))
    }

    const handleSubmit = () => {
        const name = product.name
        const price = product.price
        const wholesale = product.wholesale
        name.length > 0 && price.length > 0 && wholesale.length > 0 && props.editProductFun({name:name,price:price,wholesale:wholesale},product.id)
    }


    return ( 
        <div id="" 
        class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          
              <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          
                  <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          
          
              <div class=" inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl py-4 px-6 transform transition-all sm:my-8 w-full md:w-1/3 lg:w-1/4 " id="">
            
              
                <div class="flex mb-3 justify-between w-full pb-2 border-b">
                    <p class="font-bold text-lg">{product.name}</p>
                    <span class="material-icons-outlined text-gray-700 cursor-pointer" onClick={()=>props.setOpenModal(false)}>
                      close
                      </span>
                </div> 
                
            <div class="w-full">

            <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Product Name  </label>
                    <input type="text" onChange={(e)=>changeInput('name',e.target.value)} value={product.name} name="name" className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>

                    <div className="text-gray-400 w-full my-2 mt-4 text-left leading-relaxed">
                    <label>Unit Cost (KSH)  </label>
                    <input type="number"  onChange={(e)=>changeInput('price',e.target.value)} value={product.price} name="price" className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>


                    <div className="text-gray-400 w-full my-2 mt-4 text-left leading-relaxed">
                    <label>Whole Sale Price (KSH)  </label>
                    <input type="number"  onChange={(e)=>changeInput('wholesale',e.target.value)} value={product.wholesale} name="price" className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>


            </div>



                    <button className="mt-4 py-3 bg-blue-600 w-full focus:outline-none font-bold text-white rounded-xl mb-4" onClick={handleSubmit} >Submit</button>
        
        
              
          
            
              </div>
            </div>
          </div>
     );
}
 
export default EditProductModal;