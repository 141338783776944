import DistributionCard from "./distributionCard";
import DeliveryShow from "./modals/deliveryModal";
import { useState } from "react";
import EditOrder from "./modals/editOrder";
import { editingOrder } from "../../../api/distributionAPI";



const AllDistributions = ({...props}) => {
    const deliveries = props.deliveries



    const editOrderFun = async (order,obj) => {
        const res = await editingOrder(order,obj)
        res.status == 200 && 
        props.getLastDist()
    }


    const [showDels,setShowDel] = useState(false)

    const [editedOrder,selectEditedOrder] = useState(null)

    const [openModal,setOpenModal] = useState(false)

    const [selectedDel,setSelectedDel] = useState(null)

    return ( 
        <>
        <div className="text-gray-400 my-2 font-bold bg-yellow-200  text-yellow-600 inline-flex px-4 py-1 rounded">Active Deliveries</div>
        <div className="w-full flex flex-wrap mt-2">
        {deliveries.map(delivery=>
            <div className="w-full flex my-1">
                <DistributionCard setSelectedDel={setSelectedDel} setShowDel={setShowDel}  delivery={delivery} />
            </div>  
            )}
    
            </div>

        
        {
            showDels && (
                <DeliveryShow selectEditedOrder={selectEditedOrder} setOpenModal={setOpenModal} delivery={selectedDel} getDelivery={props.getDelivery} recentDelivery={props.delivery} setShowDel={setShowDel}   />
            )
        }




        {
            openModal && 
            <EditOrder editOrderFun={editOrderFun}  deliveries={deliveries} fetchCustomers={props.fetchCustomers} customers={props.customers} items={props.items} setOpenModal={setOpenModal}  order={editedOrder}  />
        }

            
        
        </>
    );
}
 
export default AllDistributions;