import { createImageFromInitials,getRandomColor } from '../../../utils/randomPics'
const TransporterCard = ({transporter}) => {

    return ( 
        <div className="shadow-sm flex rounded-lg text-left my-2  p-4">
        <div className=" mr-3">
        <img className="rounded-full"
    id='preview'
    src={
         createImageFromInitials(40,transporter.name , getRandomColor())				}
    alt='profile-pic'
/>
        </div>
        <div className="flex flex-col flex-grow ">
           <p className='font-bold text-sm capitalize'> {transporter.name}</p>
          <div className="flex flex-col md:flex-row md:justify-between text-gray-300 text-sm">
          <div className="font-sm "><span className="font-bold">LNO - </span>{transporter.license_no}</div>
          <div className="font-bold text-gray-500">+254{transporter.phone}</div>
          </div>
        </div>
    </div>
     );
}
 
export default TransporterCard;