import { Fragment } from 'react'
import {useState} from 'react'
const OrderProducedConfirmation = ({setShowModal,mixproduct,updateProduction,prodId}) => {

  const mixorder = mixproduct[0]



  const product = mixorder.mix_details.product_details

  const mixproduction = mixorder.production.find(x=>x.id == prodId)






  const [quantity,setQuantity] = useState(mixproduction.quantity)

  // console.log(mixcollection[0])

  const [isDisabled,setDisabled] = useState(true)

  
  const handleSubmit = () => {
    const prod = {
        quantity:quantity,
        status:'approved',
        mix_order:mixproduction.mix_order,
        produced_by:mixproduction.produced_by,
      }
    updateProduction(mixproduction.id,prod)
    setShowModal(false)
  }


    return ( 
        <Fragment>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/4  px-3">                 
            {/*content*/}
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

        <div className="flex flex-row overflow-hidden">
                  <div class="h-43 flow-hidden flex">
                      <div className="p-4">
                            {/* <img src={product.image_url} alt="" className="object-cover border-4 border-blue-400 rounded-full h-24 w-24" /> */}
                      </div>
                  </div>
                  <div className="flex-grow py-5 px-2">
      <div className="flex justify-between text-black w-full">
      <p className="font-bold text-xl ">{product.name} Mix {mixproduction.type=='shared' && <span className="bg-blue-400 text-white font-bold text-xs ml-3 rounded-full px-3 py-1">shared</span> }  </p>
      <button className="focus:outline-none mr-2"><span className="material-icons-outlined" onClick={()=>{setShowModal(false)}} >close</span></button>
      </div>
      <div className="flex flex-col text-gray-400">
        <p className="font-bold text-left">{mixorder.quantity} Mixes</p>
      </div>
                  </div>
          </div>
        
        <div className="w-full flex">
          <div className="px-6 ml-auto font-bold rounded-full flex-none bg-blue-500 py-3 text-white">{mixproduction.productName} Produced</div>
        </div>

        <div className="w-full px-3 text-left py-5">
            <div className="text-blue-400 font-bold text-lg ">Confirm Pieces Produced ?   </div>
            <div className="flex my-3">
            <div className="w-4/5 px-1 flex">
            
            {isDisabled ? ( <input type="number" onChange={(e)=> setQuantity(e.target.value) }  value={quantity} disabled   className="border border-1  rounded-xl font-bold text-blue-400 text-2xl   border-blue-400 focus:outline-none focus:ring-1 focus:border-blue-400 focus:ring-blue-400 focus:ring-opacity-400 py-3 px-4 w-full border-gray-200" /> ) : (<input type="number" onChange={(e)=> setQuantity(e.target.value) }  value={quantity}    className="border border-1  rounded-xl font-bold text-blue-400 text-2xl   border-blue-400 focus:outline-none focus:ring-1 focus:border-blue-400 focus:ring-blue-400 focus:ring-opacity-400 py-3 px-4 w-full border-gray-200" />)}
            
            </div>
            <div className="w-1/5 px-1 flex">
                   <button className="focus:outline-none py-3 w-full bg-blue-500 rounded-lg text-white" onClick={()=>setDisabled(false)} >  <span class="material-icons-outlined  inline-flex align-bottom">
                    edit
                    </span> </button>
            </div>
            </div>

        </div>


        <div className="w-full px-3 py-4">
            <div className="w-2/3 mx-auto">
                <button className="focus:oultine-none font-bold text-white bg-blue-500 w-full py-4 rounded-2xl" onClick={handleSubmit}>Approve Record</button>
            </div>
        </div>






      



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</Fragment>
     );
}
 
export default OrderProducedConfirmation;