import SearchVehicles from "./vehicles/searchVehicles";
import VehicleList from './vehicles/vehicleList'
import { WarningAlert } from '../../alerts/alerts'
import { YellowLoader } from "../../universal/loaders";
const  Vehicles = ({vehicles}) => {
            

    return (
        <div className="w-full  p-4 px-3  overflow-x-hidden  sm:px-px md:w-1/3 lg:w-1/4 xl:w-1/3 rounded-lg">
             <div className="w-full p-3 shadow overflow-x-hidden">
            <div className="text-left p-4 ">
                <p className="font-bold">Transportation  List</p>
                <SearchVehicles />

                {vehicles === null ? (
                      <div className="w-full flex justify-center h-48 item-cente">
                      <YellowLoader />
                  </div>
                ) : (
         vehicles.length > 0 ?
       
                (
                    <div className="max-h-48 mt-3 overflow-y-scroll  w-full"> 
                <VehicleList vehicles={vehicles} /> 
                    </div>
                ) :
                 (
                    <div className="w-full mt-4">
                <WarningAlert message="Empty Vehicle List" />
                </div>
                )              
                )}
            </div>
            </div>
        </div>
     );
}
 
export default Vehicles;