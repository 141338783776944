import axios from 'axios'
import {error_products,loading_products,success_products} from '../features/product_slice'


const apiUrl = process.env.REACT_APP_API_URL


export const fetchProducts = () => dispatch => {
    return new Promise (async (resolve,reject) => {
        try {
            dispatch(loading_products)
            const res = await axios.get(`${apiUrl}products/only`)
            res.status == 200 ? dispatch(success_products(res.data)): dispatch(error_products('Product Error'))
        }catch {

        }
    } )
}