const RemainingMixList = ({...props}) => {
    const mixes = props.mixes
    return ( 
        
            mixes.filter(mix=>mix.remaining>0).map(mix=>
                
                <div className="w-full my-2">
                <div className="w-full shadow-sm rounded px-4 py-1 border ">
                    <p class="text-gray-600 font-bold py-1" >{mix.productdetails.name}</p>
                    <div className="w-full flex justify-between py-3">
                        <div className="bg-red-100 text-red-600 px-3 py-1 rounded font-bold text-xs">
                            {mix.mix_remaining} Mixes
                        </div>
    
                        <p className="text-gray-600 font-bold text-sm">Remaining {mix.remaining} pcs</p>
    
                    </div>
                </div>
            </div>

                )
        
     );
}
 
export default RemainingMixList;