import { useState } from 'react'

export default function AddCrate({...props}) {

    const [showModal,setShowModal] = useState(false)  
    


    const [quantity,setQuantity]  = useState(null)

    const handleInput = (val) => {
        setQuantity(val)
    }


    const handleSubmit = () => {
     if(quantity.length > 0) {
      props.addingCrate({quantity:quantity})
      setShowModal(false)
     }

    }



    return (

        <>

<button onClick={()=> { setShowModal(true) }} className="h-13 w-13 my-2 focus:outline-none px-3 py-2 text-white bg-gray-400 rounded-xl text-xs">
   Add Crates
  </button>
        <br />

        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-2/3 lg:w-1/3 xl:w-1/3  px-3">                {/*content*/}
                            
                <div className="border-1 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                  {/* header section */}

                  <div className="flex justify-between px-6 pt-5 pb-1">
                    <p className="text-xl font-bold">Add Crate</p>
                    <button onClick={()=> setShowModal(false)} className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                  </div>

                
                  <div className="w-full mt-5 mb-4 px-6 text-left">

                  <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Enter Quantity</label>
                    <input type="text" onChange = {(e)=>{
                      handleInput(e.target.value)
                  }
                    } className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>


                    <div className="mb-3 mt-5 mx-auto w-full lg:w-2/3">
                        <button className="w-full py-3 font-bold text-white bg-blue-600 rounded-lg" onClick={handleSubmit}>Submit</button>
                    </div>


                    </div>

           

                  {/*body*/}
                  <div className="relative px-6 pb-3 flex-auto">



                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
  