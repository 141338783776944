import {createSlice} from "@reduxjs/toolkit"

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user:null,
        role: null,
        isLoading:false,
    },
    reducers: {

        userLoading:(state)=>{
            state.isLoading = true
        },
        userSuccess:(state,action)=>{
            state.isLoading = false
            state.user = action.payload
            action.payload.user.groups.length > 0 ? state.role = action.payload.user.groups[0].name : state.role = 'super admin'
            
        },
        logoutUser:(state,action)=>{
            state.isLoading = false
            state.user=null
            state.role=null
            localStorage.removeItem('b_token')
        },
        
    }

})

export const {userSuccess,logoutUser,userLoading}  = userSlice.actions

export const userSession = (state) => state.user
export const userRole = (state)=>state.user.role

export default userSlice.reducer