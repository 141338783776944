import { Fragment,useState } from "react";
const ShopReturns = ({...props}) => {
    const shopstock = props.shopstock
    const distribution = props.distribution
    const [returned,setReturned] = useState([])


    const items = distribution.items

      console.log(shopstock.stocks)
  

    const makeSelected = (id, product) => {
      setReturned([...returned, { product: product, quantity: 0,shopstock:shopstock.id,shopstockitem:id }]);
    };

 

    const removeSelected = (id)=> {
      setReturned(returned.filter(prod=>prod.shopstockitem !== id))
    }
    
    const handleInputChange = (val,id) => {
     returned.find(r=>r.shopstockitem == id).quantity=val
      
    }

    



    const submitReturns = () => {
      const fullreturned = returned.filter(r=>r.quantity > 0)
      fullreturned.length > 0 && props.returnShop(fullreturned)
      // props.setShowCollectShop(true)
      props.setShowReturnShop(false)
    }

return ( 
    <Fragment>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
            {/*content*/}
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

     

        <div className="flex justify-between px-3 pt-5 pb-1">
                    <p className="text-xl font-bold text-red-500">#{shopstock.id} | MAIN SHOP</p>
                    <button  onClick={()=>{
                       props.setShowReturnShop(false)
                       props.setShowCollectShop(true)                
                    }}  className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                  </div>


                  <div className="relative px-4 w-full pb-3    mt-4 text-left">
                               
                   

                  <div className="w-full my-3 flex flex-wrap">

            </div>


<div className="w-full text-gray-400 font-bold text-lg">
  Select Returned Products 
</div>
                  
          <div className="w-full my-3 flex flex-wrap">
                    
{shopstock.stocks.map(stock=>
returned.find(x=>x.shopstockitem == stock.id) ? 
<button
className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-not-allowed border-red-400 font-bold px-4 py-2 text-sm text-red-400`}
onClick={() => removeSelected(stock.id)}
>
{items.find(x=>x.id == stock.product).productdetails.name}
</button>
                             
:

<button
className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-not-allowed border-gray-400 font-bold px-4 py-2 text-sm text-gray-400`}
onClick={() => makeSelected(stock.id,stock.product)}
>
{items.find(x=>x.id==stock.product).productdetails.name}
</button>



                        )}
       
                  </div>

                  <div className="w-full   max-h-48 overflow-y-scroll ">
{returned.length > 0 &&  



returned.map(stock=> 

<div className="w-full flex my-1 px-1">
                           <div className="w-full shadow-sm px-2 py-2 items-center  flex ">
                             <p className="font-bold capitalize flex-grow">{distribution.items.find(x=>x.id==stock.product).productdetails.name}</p>
                            
                             <div className="w-1/3 py-1 px-2">
                            <input
                                  type="number"
                                  placeholder="Enter Returned"  
                                    onChange={(e)=>handleInputChange(e.target.value,stock.shopstockitem)}                                                          
                                  className="border border-1 my-1 rounded-lg text-sm   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 mr-3 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-4 w-full border-gray-200"
                                />
                            </div>

                           </div>                               
  </div>

)
}

       </div>         
              
                



                    {returned.length > 0 &&  


              <div className="flex mx-auto w-full lg:w-2/3 mt-6 mb-4">
                        <button  className="w-full py-3 bg-red-500 focus:outline-none text-white font-bold rounded-lg"  onClick={submitReturns} >Return Products </button>
              </div>
            }
                    </div>


      



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</Fragment>
 );
}
 
export default ShopReturns;