import { useState,Fragment } from "react";
const PayOrders = ({...props}) => {

    const order = props.selectedOrder


    const [amount,setAmount] = useState(0)

    const paymentMethods = ['cash','mpesa','credit']

    const [pmethod,setMethod] = useState([])


    const handleInputChange = (val,method) => {
      pmethod.find(r=>r.mode == method).amount=parseInt(val)       
    }

    const selectPaymentMethod = (method) =>{
      method != "credit" ? setMethod([...pmethod,{mode:method,amount:0}]) : setMethod([{mode:method,amount:0}])
    }
    const unSelectPaymentMethod = (method) =>{
      setMethod(pmethod.filter(x=>x.mode !== method))
    }
  

    const handleSubmit = ()=> {

      const payObj = pmethod.map((element)=> ({
        ...element,
        order:order.id
      })).filter(pay=>!(pay.mode !== "credit" && pay.amount === 0))

      console.log()

        if(payObj.length > 0){
          props.setPayObj(payObj)
          props.setShowConfirmPay(true)
          props.setShowPayOrder(false)
        }



       

    } 

    return ( 
        <Fragment>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
            {/*content*/}
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

  

        <div className="flex justify-between px-3 pt-3 pb-2 border-b">
                    <p className="text-xl font-bold text-blue-600">#{order.id} | {order.customer.name}</p>
                    <button  onClick={()=>{
                       props.setShowPayOrder(false)
                       props.setShowCollect(true)                
                    }}  className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                  </div>


                  <div className="relative px-4 w-full pb-3    mt-4 text-left">
                               
            




           

                  <div className="w-full my-3 text-gray-400 font-extrabold ">Select Payment Method</div>
                <div className="w-full flex flex-wrap my-5">
                {paymentMethods.map(method=>    
             pmethod.find(x=>x.mode == method) ?          
                    <button className="border border-blue-600 text-blue-600 px-7 m-2 capitalize  rounded-full font-bold text-sm py-3" onClick={()=>unSelectPaymentMethod(method)} >
                          {method}
                    </button>
                    : 
                    <button className="border border-gray-400 text-gray-400 px-7 m-2 capitalize  rounded-full font-bold text-sm py-3" onClick={()=>selectPaymentMethod(method)} >
                    {method}
              </button>

                          )}                      
                </div>



          {/* {pmethod !== 'credit' && 

<div className="text-gray-400 w-full my-2 text-left leading-relaxed">
  <label className="font-bold" >Enter Amount Received  </label>
  <input type="number" name="name" min="0" onChange={(e)=>{
    setAmount(e.target.value)
  }} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-3 px-4 w-full border-gray-200" />
  </div>
      
} */}

{
  pmethod.length > 0 &&
  pmethod.map(x=>
    x.mode != "credit" &&
    <div className="w-full flex my-2 px-1">
                           <div className="w-full shadow-sm px-5 flex items-center border border-gray-50  ">
                             <p className="font-bold capitalize text-gray-500 flex-grow capitalize">{x.mode}</p>
                            
                             <div className="w-1/3 py-1 px-2">
                            <input
                                  type="number"
                                  placeholder="Enter Amount"  
                                  onChange={(e)=>{handleInputChange(e.target.value,x.mode)}}
                                                                className="border border-1 my-1 rounded-lg text-sm   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 mr-3 focus:ring-gray-400 focus:ring-opacity-400 py-3 px-4 w-full border-gray-200"
                                />
                            </div>

                           </div>                               
  </div>
  )
}


                

                
              
                    <div className="w-full lg:w-1/2  my-2 flex-col rounded-lg ml-auto text-right border border-yellow-600 px-3">
                        <div className="my-3 text-yellow-600  font-bold ">
                            <span className="capitalize">{order.delivery.driver_name} | <span className="uppercase">{order.delivery.plate_no}</span></span>
                        </div>
                       
                    </div>

          {pmethod.length > 0 &&
          
          <div className="flex mx-auto w-full lg:w-2/3 mt-6 mb-4">
          <button  className="w-full py-3 bg-blue-600 focus:outline-none text-white font-bold rounded-lg" onClick={handleSubmit}  >Collect Payments </button>
</div>
          }


                    </div>
      



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</Fragment>
     );
}
 
export default PayOrders;