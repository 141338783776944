import { Fragment } from 'react'
import {useState} from 'react'
import { WarningAlert } from '../../../alerts/alerts'
const OrderProgress = ({setShowModal,mixproduct,addProduction}) => {

  const mixorder = mixproduct[0]

  const [quantity,setQuantity] = useState(null)

  const product = mixorder.mix_details.product_details

  const mix_type = mixorder.mix_details.mix_type

  const shared = mixorder.mix_details.shared
  // console.log(shared)

  const production = {
    quantity:quantity,
    mix_order: mixorder.id,
    product:product.id,
    produced_by: 1,
    status: 'pending',
    type: 'normal'
  }

  const [produce,setProduce] = useState([])
  

  const makeSelect = (id) => {
      setProduce([...produce,{product:id,quantity:0,mix_order:mixorder.id,produced_by:1,status:'pending',type:'shared'}])
  } 

  

  const removeSelect = (id) => {
    setProduce(produce.filter(produce=>produce.product !== id))
  }


  const updateValues = (val,product) => {
    produce.find(produce=>produce.product==product).quantity=val

  }

  const handleSubmit = () => (
    quantity != null && (
     addProduction(production),
     setShowModal(false)
    )
  )

  const handleShared = () => {
   if(produce.find(produce=>produce.quantity.length == 0) ){ 
    alert('Empty Iputs')
    }else{
          // adding other properties
  // console.log(produce)
  addProduction(produce)
  setShowModal(false)


    }

  }
  

    return ( 
        <Fragment>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/4  px-3">                 
            {/*content*/}
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

        <div className="flex flex-row overflow-hidden">
                  <div class="h-43 flow-hidden flex">
                      <div className="p-4">
                            <img src={product.image_url} alt="" className="object-cover border-4 border-green-400 rounded-full h-24 w-24" />
                      </div>
                  </div>
                  <div className="flex-grow py-5 px-2">
      <div className="flex justify-between text-black w-full">
      <p className="font-bold text-xl ">{product.name}   {mix_type == "shared" &&   <span className="mr-auto bg-green-400 rounded-full text-xs text-white py-1 px-4 font-bold capitalize" >{mix_type}</span>  }  </p>
      <button className="focus:outline-none mr-2"><span className="material-icons-outlined " onClick={()=>{setShowModal(false)}} >close</span></button>
      </div>
      <div className="flex flex-col text-gray-400">
        <p className="font-bold text-left">{mixorder.quantity} Mixes</p>
      </div>
                  </div>
          </div>

          {mix_type == 'shared' ? 
    <>
  
<div className="pb-5 mt-4">

  {shared.length > 0 ? 

<>
      <div className="w-full text-gray-600 text-sm font-bold px-2 my-3 text-left">Select Product Produced</div>

    <div className="w-full flex flex-wrap px-2">
      {shared.map(product=>        


    produce.find(produce=>produce.product == product.product) ? 
    <button className="py-2 font-bold text-xs px-5 m-1 border border-green-400 text-green-400 uppercase rounded-full focus:outline-none" onClick={()=>removeSelect(product.product)}>{product.produce.name}</button>
    :
    <button className="py-2 font-bold text-xs px-5 m-1 border border-gray-400 text-gray-400 uppercase rounded-full focus:outline-none" onClick={()=>makeSelect(product.product)}>{product.produce.name}</button>


    )}
    </div>



{produce.length > 0 &&  
      
  <>
      <div className="w-full px-2 my-3">
        
{produce.map(produce=> 

              <div className="w-full px-3 py-1">
          <div className="w-full flex my-1  px-1 shadow py-2">
                                          <p class="py-3 w-full font-bold text-black text-left px-5  text-sm  uppercase">
                                           {shared.find(product=>product.product == produce.product).produce.name}
                                          </p>
                                          <input
                                            type="number"
                                            placeholder="Enter Amount"
                                            onChange={(e)=>updateValues(e.target.value,produce.product)}
                                            className="border border-1 my-1 rounded-lg text-sm   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 mr-3 focus:ring-gray-400 focus:ring-opacity-400 py-1 px-2 w-full border-gray-200"
                                          />
                                        </div>
          </div>
        
    )}




      </div>


<div className="w-full mx-auto my-4">
  <button className="mx-auto bg-green-400  w-full lg:w-2/3 py-3 rounded-xl font-bold text-white text-sm w-full" onClick={handleShared} >Submit</button>
</div>

</>


}





</>



 

    :

<WarningAlert message={'Shared Mix Does Not Contain Products'} />
    
    }

</div>

    </>
          
          : 

              <>
                        <div className="text-gray-400 w-full my-2 px-5 text-left leading-relaxed">
                    <label className="text-green-400 font-bold" >Enter {product.name} Produced</label>
                    <input type="number" onChange={(e)=>{
                      setQuantity(e.target.value)
                    }} className="border border-1 rounded-xl   border-green-400 mt-1 focus:outline-none focus:ring-1 focus:border-green-400 focus:ring-green-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
          </div>






        <div className="flex text-center my-3 px-5">
          <button className="w-full bg-green-400 text-white mx-auto focus:outline-none rounded-xl py-3" onClick={handleSubmit}>Submit</button>
        </div>
      
              </>

          }
        



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</Fragment>
     );
}
 
export default OrderProgress;