import axios from "axios"
import { logoutUser } from "../features/userSlice"
import { useSelector,useDispatch } from "react-redux"
import { userSuccess,userLoading } from "../features/userSlice"
const apiUrl = process.env.REACT_APP_API_URL




const FetchUserDetails  =  (token) => dispatch => {

    return new Promise (async (resolve,reject) => {
        try {
            dispatch(userLoading())
            console.log('Fetching Users')
            const res = await axios.get(apiUrl+'auth/user/'+token)
            res.status == 200 ?  dispatch(userSuccess(res.data)) : dispatch(logoutUser())
        }catch (error){
            // dispatch(logoutUser())
        }
    } )


}




export const UserDetails = () => dispatch => {
    const token = localStorage.getItem('b_token')
    token ? dispatch(FetchUserDetails(token) ): dispatch(logoutUser())
}