import { createSlice } from "@reduxjs/toolkit";

export const ingredientSlice = createSlice({
    name:"ingredients",
    initialState: {
        loading:false,
        error:false,
        errorMsg:null,
        ingredients:null,
    },

    reducers: {
        loading_ingredients: (state)=> {
            state.error = false;
            state.errorMsg = null;
            state.loading = true;
        },
        success_ingredients: (state,action)=>{
            state.loading= false;
            state.ingredients = action.payload;
        },
        error_ingredients: (state,action)=>{
            state.loading = false;
            state.errorMsg = action.payload;
            state.error = true
        },
        clear_error_ingredients:(state)=>{
            state.error = 'false'
        }
    }
})


export const  {success_ingredients,loading_ingredients,clear_error_ingredients,error_ingredients} = ingredientSlice.actions

export const ingredient_state = (state) => state.ingredients

export default ingredientSlice.reducer