import { Fragment } from "react";
import {useState } from 'react'
import AddClientModal from "./modals/AddClientsModal";
import AddTransportation from "./modals/AddTransportation";
import AddTransporters from "./modals/AddTransporters";


const FABSalesAssets = ({...props}) => {

  const [ingMenuState,setMenuState] = useState('hidden')

  const showMore = ()=> {
    if (ingMenuState === 'hidden'){
      setMenuState('')
    }else{
      setMenuState('hidden')
    }
  }

  return ( 
  <Fragment>
  <div className={`hiddenFloat ${ingMenuState}  fixed  bottom-24 right-8`}>
    <AddClientModal  addClient={props.addClient} />
    <AddTransportation addVehicle={props.addVehicle}  />
    <AddTransporters addDriver={props.addDriver} />
  </div>

    
  
  <button  onClick={showMore} 
    className="p-0 w-16 bottom-7 fixed right-8 h-16 bg-blue-600 text-center rounded-full hover:bg-blue-900 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">  
      <span className="material-icons-outlined text-white">add</span>
  </button>
  </Fragment>
   );
}
 
export default FABSalesAssets;