import Navbar from "../universal/navbar";
import BreadCrumbs from "../universal/breadcrumbs";
import { useEffect } from "react";
import SubmitDistribution from "../layouts/distribution/SubmitDistribution";
import { fetchPendingOrders } from "../../api/distributionAPI";
import { useState } from "react";
import axios from "axios";
import { YellowLoader } from "../universal/loaders";
const apiUrl = process.env.REACT_APP_API_URL


const OrderPayments = ({...props}) => {

   const token = localStorage.getItem('b_token')


   const [pendingOrders,setPendingOrders] = useState([])

   const [loading,setLoading] = useState(true)
   
   const [selectedOrder,setSelectedOrder] = useState(null)

   const pendingOrdersF = async () => {
      const orders = await fetchPendingOrders()
      setLoading(false)
      setPendingOrders(orders.data)
   }

   useEffect( ()  => {
      pendingOrdersF()
   }, [])

   const addPayment = (obj)=> {
      axios.post(apiUrl+'distribution/payments',obj).then((res)=>{
      const data =  res.status === 201 ? pendingOrdersF(): console.log('error')
      }).catch((err)=>{
        console.log(err.request)
      })
    }
  

   const returnDist = (obj) => {
      axios.post(apiUrl+'distribution/return',obj).then((res)=> {
         res.status === 201 ? pendingOrdersF() : console.log('error')
      }).catch((err)=> {
        console.log(err.request)
      })
    }

    const addDamages = (obj) => {
      axios.post(apiUrl+'distribution/orderdamages',obj).then((res)=> {
         res.status === 201 ? pendingOrdersF() : console.log('error')
      }).catch((err)=> {
        console.log(err.request)
      })
    }

    useEffect(()=>{
      if (selectedOrder){
         const order = selectedOrder.id
         setSelectedOrder(pendingOrders.find(x=>x.id===selectedOrder.id))
         console.log(selectedOrder);
         
      }
    },[pendingOrders])


    const addDiscountApi = (obj,orderItem)=>{
      axios.post(apiUrl+'distribution/discount/'+orderItem,obj,
      {
         headers: {
           'Authorization': `Token ${token}` 
 }}
      ).then((res)=>{
         res.status == 201 ? pendingOrdersF() : console.log('error')
      }).catch((err)=>{
         console.log(err.request)
      })
    }


    return ( 

        <div className="relative h-screen overflow-x-hidden mx-auto">
        <Navbar active="orderPayments" />
     <div className="w-full px-6">
     <BreadCrumbs newPage="Order Payments"  />
   
   

   {loading &&
      <div className="w-full flex h-48 items-center justify-center">
         <YellowLoader />
      </div>
   }


{
   pendingOrders.length > 0 &&

   <SubmitDistribution addDiscountApi={addDiscountApi} addDamages={addDamages} setSelectedOrder={setSelectedOrder} selectedOrder={selectedOrder}   getDelivery={props.getDelivery} delivery={props.delivery} returnDist={returnDist} returnShop={props.returnShop} pendingOrders={pendingOrders} addPayment={addPayment} />

}




        </div>
        </div>
     );
}
 
export default OrderPayments;