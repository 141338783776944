import {useState } from 'react'


export default function AddBrandModal({...props}) {

    const [selectedBrand,setSelectedBrand] = useState(null)
    const [brand,setBrand] = useState(null)

    const [showModal,setShowModal] = useState(false)    


    const ings = props.ingredients

    const makeSelected = (id) =>{
        setSelectedBrand(id)      
    }


    const handleSubmit = (e) => {
        const brandValue = {
            'brand_name':brand,
            'product':selectedBrand
          }

          props.addBrand(brandValue)
          setShowModal(false)
          setSelectedBrand(null)
    }
  

    return (

      

        <>

<button onClick={()=> { setShowModal(true) }} className="h-13 w-13 my-2 focus:outline-none px-3 py-2 text-white bg-gray-400 rounded-xl text-xs">
    Add Brands
  </button>
        <br />

        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-xl">                {/*content*/}
                <div className="border-1 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                  {/* header section */}

                  <div className="flex justify-between px-6 pt-5 pb-1">
                    <p className="text-xl font-bold">Add Brands</p>
                    <button onClick={()=> setShowModal(false)} className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                  </div>

                  {/*body*/}
                  <div className="relative px-6 pb-3 flex-auto">

                    <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Brand Name</label>
                    <input type="text" onChange = {(e)=>{
                      setBrand(e.target.value)
                  }
                    } className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>


                    <div className="w-full mt-5 mb-4 text-left">
                      <p className="font-bold my-3" >Select Ingredient</p>
                      <div className="w-full px-2 flex-wrap">
                          {ings.map(ing=> {
                          if(selectedBrand === ing.id){
                       return     <button className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-pointer border-blue-600 font-bold px-4 py-2 text-sm text-blue-600`} onClick={()=> makeSelected(ing.id) }> <span className={`material-icons-outlined  text-sm text-blue-600`}>done</span> {ing.name}</button>
                          }else{                           
                            return  <button className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-pointer border-gray-300 font-bold px-4 py-2 text-sm text-gray-500`} onClick={()=> makeSelected(ing.id) }> <span className={`material-icons-outlined hidden text-sm text-blue-600`}>done</span> {ing.name}</button>
                          }
                          }
                        )}
                    </div>
                    </div>


                    <button className="mt-4 py-3 bg-blue-600 w-full focus:outline-none text-bold text-white rounded-xl mb-4" onClick={handleSubmit} >Submit</button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
  