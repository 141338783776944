import { Link, useParams } from "react-router-dom";
import { BlueLoader, SBlueLoader, SRedLoader, SYellowLoader } from "../universal/loaders";
import Navbar from "../universal/navbar";
import { useState,useEffect } from "react";
import { addingPayments, fetchCustomerDetails } from "../../api/accountingAPI";
import ReceivePaymentModal from "../layouts/accounts/receivePayment";


const CustomerAccount = () => {

    const params = useParams();


    const [customer,setCustomer] = useState(null)

    const [openModal,setOpenModal] = useState(false)

    const fetchCustomer = async () => {
        setLoading(true)
        const res  = await fetchCustomerDetails(params.customerId)
        setLoading(false)
        setCustomer(res.data)
    }

    const [loading,setLoading] = useState(true)


    const receivePayment = async (obj) =>{
        obj['customer'] = customer.pk
        obj['mode'] = obj.mode.toLowerCase()
        setOpenModal(false)
        const res = await addingPayments(obj)
        // console.log('wow', res
        res.status == 201 && fetchCustomer()

    }
    
    useEffect(() => {
        fetchCustomer()
    }, []);
    
    
    return ( 

        <div className="w-full">
        <Navbar />
        <div className="w-full px-6">
        <nav className="bg-gray-100 shadow-sm text-sm breadCrumb">
          <ol className="list-reset py-4 pl-4 rounded flex bg-grey-light text-grey">
            <li className="px-2">  <Link to="/" className="no-underline text-indigo font-bold">Dashboard</Link></li>
            <li>/</li>

            <li className="px-2">  <Link to="/customers" className="no-underline text-indigo font-bold">Customers</Link></li>
            <li>/</li>
            <li className="px-2 mt-"> {loading ? <SBlueLoader /> : customer && customer.name  }</li>
          </ol>


          </nav>    


          <section className="w-full p-3 flex flex-wrap">

          <div className="w-full flex flex-wrap">

          <div className="lg:w-1/5 w-full p-3">
                    <div className="w-full px-3 py-5 cursor-pointer shadow border text-blue-600 rounded-lg border-blue-600">
                           <div className="w-full flex justify-between items-center">
                            <p className="text-sm font-bold">Total Orders</p>
                            <button className="w-12 h-12 focus:outline-none rounded-lg  text-blue-600 border-blue-600 border-2">
                                <span className="material-icons-outlined text-sm  inline-flex align-middle">
                                    receipt
                                    </span>
                            </button>
                           </div>

                           <div className="w-full mt-3 text-2xl text-left flex items-center">
                           {loading ? <SBlueLoader /> : customer&& customer.total_orders} <span className="text-xs ml-2">Orders</span>
                           </div>

                    </div>
            </div>

            <div className="lg:w-1/5 w-full p-3">
                    <div className="w-full px-3 py-5 cursor-pointer shadow border text-yellow-600 rounded-lg border-yellow-600">
                           <div className="w-full flex justify-between items-center">
                            <p className="text-sm font-bold">Unpaid Orders</p>
                            <button className="w-12 h-12 focus:outline-none rounded-lg  text-yellow-600 border-yellow-600 border-2">
                                <span className="material-icons-outlined text-sm  inline-flex align-middle">
                                credit_card_off
                                    </span>
                            </button>
                           </div>

                           <div className="w-full mt-3 text-2xl text-left flex items-center">
                           {loading ? <SYellowLoader/> : customer&& customer.unpaid_count } <span className="text-xs ml-2">Orders</span>
                           </div>

                    </div>
            </div>




            <div className="lg:w-1/5 w-full p-3"> 
                    <div className="w-full px-3 py-5 cursor-pointer shadow border text-red-600 rounded-lg border-red-600">
                           <div className="w-full flex justify-between items-center">
                            <p className="text-sm font-bold">Balance</p>
                            <button className="w-12 h-12 focus:outline-none rounded-lg  text-red-600 border-red-600 border-2">
                                <span className="material-icons-outlined text-sm  inline-flex align-middle">
                                    wallet
                                    </span>
                            </button>
                           </div>

                           <div className="w-full mt-3 text-2xl text-left flex items-center">
                           <span className="text-xs mr-3">KES </span>     {loading ? <SRedLoader /> :  customer&& customer.unpaid_total  }
                           </div>

                    </div>
            </div>


            </div>

            </section>











          </div>



            {
                openModal && 
                <ReceivePaymentModal receivePayment={receivePayment}  setOpenModal={setOpenModal}  customer={customer} />
            }


          



            <div className="w-48 h-8 fixed bottom-10 right-8">

        {loading ? <div className="w-full flex justify-end items-end"><BlueLoader /></div> :  <button onClick={()=>setOpenModal(true)} className="w-full py-4 rounded-full font-bold  bg-blue-600 text-white">Receive Payments</button>
  }              
            </div>




          </div>

     );
}
 
export default CustomerAccount;