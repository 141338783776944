import { useState } from "react";

const MixExpenseModal = ({setShowMixModal}) => {


    const [amount,setAmount] = useState(amount)
    const [expense,setExpense] = useState(amount)


    const handleSubmit = () => {
        
    }

    return ( 
                <>
                <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                    
                <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        
                <div className="w-full">

                <div v className="flex justify-between text-blue-500 px-6 pt-5 pb-1">
                            <p className="text-xl font-bold ">Add Stock</p>
                            <button onClick={()=> setShowMixModal(false)} className="focus:outline-none "><span className="material-icons-outlined">close</span></button>
                          </div>

                </div>       


                <div className="text-gray-400 w-full my-4 text-left leading-relaxed">
                    <label className=" font-bold " >Expense Title  </label>
                    <input type="text" onChange={(e)=>setExpense} className="border border-1 rounded-lg   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>

                    <div className="text-gray-400 w-full my-4 text-left leading-relaxed">
                    <label className=" font-bold " >Expense Amount  </label>
                    <input type="text" onChange={(e)=>setAmount} className="border border-1 rounded-lg   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>
                
               

                    <div className="relative px-6 pb-3 flex-auto">
        
                            <button className="mt-4 py-3 w-full lg:w-2/3 mx-auto bg-blue-600 w-full focus:outline-none text-bold text-white rounded-xl mb-4" onClick={handleSubmit}>Submit</button>
        
                          </div>
        
        
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40"></div>
        </>
     );
}
 
export default MixExpenseModal;