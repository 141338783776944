import { Fragment } from "react";
import {useState } from 'react'
import AddBrandModal from "./modals/addBrandModal";
import AddIngredientModal from "./modals/addIngredientModal";

const FAB = ({...props}) => {

  const [ingMenuState,setMenuState] = useState('hidden')

  const showMore = ()=> {
    if (ingMenuState === 'hidden'){
      setMenuState('')
    }else{
      setMenuState('hidden')
    }
  }

  return ( 
  <Fragment>
  <div className={`hiddenFloat ${ingMenuState}  absolute bottom-24 right-8`}>
    <AddIngredientModal addIngredients={props.addIngredients}/>
    <AddBrandModal ingredients = {props.ingredients} addBrand = {props.addBrand} />
  </div>

    
  
  <button  onClick={showMore} 
    className="p-0 w-16 absolute  bottom-7 right-8 h-16 bg-blue-600 text-center rounded-full hover:bg-blue-900 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">  
      <span className="material-icons-outlined text-white">add</span>
  </button>
  </Fragment>
   );
}
 
export default FAB;