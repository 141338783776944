import FabStock from "../layouts/stocks/FabStock";
import Navbar from "../universal/navbar";
import BreadCrumbs from "../universal/breadcrumbs";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { basicManagement,cashierManagement,superManagement } from "../../features/permissions"


import StockList from "../layouts/stocks/StockList";
import { userRole } from "../../features/userSlice";
import AddStockModal from "../layouts/stocks/modals/addStockModal";
import { useState } from "react";
import MixExpenseModal from "../layouts/products/modals/addMixExpense";

const Stocks = ({...props}) => {


    const role = useSelector(userRole)

    useEffect(() => {
        props.getProducts()
        props.fetchStock()
        props.presentStock()
    }, [])


    const [showMixModal,setShowMixModal] = useState(false)

   

    return ( 
        
        <div className="relative h-screen overflow-y-scroll mx-auto ">
        <Navbar active="stocks" />
        <div className="w-full px-6">
        <BreadCrumbs newPage="Stock" />
            {/* <SearchOrders type={'stock'} /> */}
        

            {role && basicManagement.includes(role) && 
          
           <FabStock products={props.products} addStock={props.addStock} />

        } 
        <div className="w-full">

           <StockList stock={props.stockAvailable}  />
        </div>
       
        </div>

        {showMixModal && 
        <MixExpenseModal setShowMixModal={setShowMixModal}  />
        
        }



        </div>
     );
}
 
export default Stocks;