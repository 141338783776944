
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart,Bar } from 'recharts';




const MixChartProduction = ({result}) => {

    const data =result
  

    const randomColor = ['#EF4444','#FBBF24','#10B981','#2563EB','#7C3AED','#BE185D','#4F46E5']

    // console.log(results.results.data)

    return ( 
        <ResponsiveContainer width="100%" height="100%">
        <BarChart
        //   width={500}
        //   height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
    
          <XAxis dataKey="mix" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}

            <Bar type="monotone" dataKey="quantity" stroke='#2563eb' fill='#2563eb' />

          {/* <Line type="monotone" dataKey="pv" stroke="#3B82F6" /> */}
        </BarChart>
      </ResponsiveContainer>

     );
}
 
export default MixChartProduction;

