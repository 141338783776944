import Clients from '../layouts/salesAssets/clients'
import Transporters from '../layouts/salesAssets/transporters'
import FABSalesAssets from '../layouts/salesAssets/FabSalesAssets'
import BreadCrumbs from '../universal/breadcrumbs'
import Vehicles from '../layouts/salesAssets/vehicles'
import Navbar from '../universal/navbar'
import {useEffect} from 'react'

const SalesAssets = ({...props}) => {

    useEffect(() => {
        props.fetchCustomers()
        props.fetchDrivers()
        props.fetchVehicles()       
    },[])
    
   


    return ( 
        <div className="w-full relative  overflow-x-hidden mx-auto ">
        <Navbar active="salesAssets" />
        <div className="w-full px-2">
        <BreadCrumbs newPage="Sales Assets"  />
            <FABSalesAssets addDriver={props.addDriver} addVehicle={props.addVehicle} addClient={props.addClient}  />

            <div className="flex w-full mt-4 flex-wrap ">
                    <Transporters drivers={props.drivers} />
                    <Vehicles vehicles={props.vehicles} />
                    <Clients updatePrice={props.updatePrice} customers={props.customers} />
                    
            </div>
        </div>

            


        </div>
     );
}
 
export default SalesAssets;