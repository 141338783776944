import moment from "moment";
import CollectionModal from "./modals/collectionModal";
import { useState } from "react";

const CollectionCard = ({...props}) => {
    const collections = props.collections

    
    const [selectedCollection,setSelected] = useState()

    const [show,showCollection] = useState(false)

    return ( 
        <div className="w-full flex flex-wrap p-2">

{collections.map(collection=> 
            <div className="w-full px-2 lg:w-1/5 my-2">
            <div className="rounded-lg w-full shadow border-2 border-red-400 py-3 my-2"

                onClick={()=>{
                    setSelected(collection)
                    showCollection(true)}
                }

            > 
            <div className="w-full flex justify-between text-gray-700 uppercase py-1 px-5 ">
                <p className="text-sm text-gray-700 font-bold">{collection.product} Mix </p>
                <p className="font-extrabold text-gray-700 text-xs">{moment(collection.ordered_at).format('hh:mm:A')}</p>
            </div>
            <div className="w-full py-2 mt-1 flex px-2 ">
                <div className="text-sm  bg-red-400 ml-auto font-bold px-2 py-1 rounded-full flex-none text-white">{collection.quantity} mixes</div>
            </div>
        </div> 
            </div>
    )}




{
    show &&     
<CollectionModal  selectedDate={props.selectedDate} updateCollection={props.updateCollection} collection={selectedCollection}  showCollection={showCollection}   />
}





   
        </div>
     );
}
 
export default CollectionCard;