export const ErrorAlert = ({message}) => {
    return ( 
        <div className="flex flex-row px-4 bg-red-500 py-4 mx-4 mb-10">
        <div className="flex-none">
          <button className="focus:outline-none bg-white font-bold text-red-400 h-12 w-12  shadow rounded-full h-12">
            <span className="material-icons-outlined">close</span>
          </button>
        </div>
        <div className="flex-grow  px-2 text-white">
            <p className="font-bold">Error !</p>
            <p className="text-sm" >{message}</p>
        </div>
      </div>
     );
}
 
export const SuccessAlert = ({message}) => {
    return (
        <div className="flex flex-row px-4 bg-green-500 py-4 mx-4 mb-10">
        <div className="flex-none">
          <button className="focus:outline-none bg-white font-bold text-green-400 h-12 w-12  shadow rounded-full h-12">
            <span className="material-icons-outlined">done</span>
          </button>
        </div>
        <div className="flex-grow  px-2 text-white">
            <p className="font-bold">Success !</p>
            <p className="text-sm" >{message}</p>
        </div>
      </div>
    );
}
 
export const WarningAlert = ({message}) => {
    return ( 
        <div className="flex flex-row px-4 bg-yellow-400 py-4 mx-4 mb-10">
        <div className="flex-none">
          <button className="focus:outline-none bg-white font-bold text-yellow-400 h-12 w-12  shadow rounded-full h-12">
          <span class="material-icons-outlined">
info
</span>
          </button>
        </div>
        <div className="flex-grow  px-2 text-white">
            <p className="font-bold">Warning !</p>
            <p className="text-sm" >{message}</p>
        </div>
      </div>
     );
}
 
