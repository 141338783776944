import { BlueLoader } from "../../universal/loaders";
import ProductRecipeShow from "./modals/productRecipeShow"


const ProductList = ({...props}) => {
    const p  = props
    const products = p.products
    return ( 
  <>
    {products === null ? (

            <div className="w-full   h-48 flex justify-center items-center">
                    <BlueLoader />
            </div>

    ) : (
          <div className="flex mt-4 flex-wrap overflow-hidden sm:-mx-px">
                 {products.map(product=> (           

           <div className="w-full  md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" key={product.id}>
               <div className="rounded-lg shadow overflow-hidden w-full">
               <div className="flex flex-fluid  text-left">
                       <div className="flex-none w-1/3 relative">
                           <img src={product.image_url} alt="" className="absolute inset-0 w-full h-full object-cover" />
                       </div>
                       <div className="flex flex-col w-full px-4 py-3">
                           <h3 className="text-gray-500 font-bold">{product.name}</h3>
                           <div className="flex  justify-between  mt-4">
                                  <div className="flex items-start flex-col">
                                  <p className="font-bold text-sm text-right text-gray-600">KSH {product.price}</p>
                                  <p className="py-1 text-blue-600 font-bold text-xs">Whole Sale: KSH {product.wholesale} </p>
                                  </div>
                                   <div className="text-gray-500 hover:text-blue-500">


                                    <div className="#">
                                    <button className="py-1 text-xs font-bold bg-blue-600 rounded text-white mx-2 px-3" onClick={()=>{
                                        p.setSelectedProduct(product)
                                        p.setOpenModal(true)
                                    }}  >
                                        <span className="material-icons-outlined">
                                            edit
                                        </span>
                                    </button>
                                   <ProductRecipeShow addRecipe={p.addRecipe}  ingredients={p.ingredients} product= {product} loading={p.loading}     addMix={p.addMix} />       
                                    </div>
                                   </div>                            
                           </div>
                       </div>
               </div>
               </div>
           </div>

           ))}
          </div>
    )}
  </>
     );
}
 
export default ProductList;