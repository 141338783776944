import moment from "moment"
import { useState } from "react";
import { useEffect } from "react";
const ProductSalesTable = ({...props}) => {



        const data = props.data


        const [total_price,setTotalPrice] = useState(0)
        const [total_qty,setQty] = useState(0)
   
    
        useEffect(() => {
                if (data.length > 0){
                setTotalPrice(data.reduce((a,b)=>{return a+b.total },0))
                setQty(data.reduce((a,b)=>{return a+b.charged_stock },0))
                }

        }, []);
        

        return ( 
            data.length > 0 ?
    
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
    
    
              <div
                class="inline-block min-w-full shadow-md rounded overflow-hidden"
              >
                <table class="min-w-full leading-normal text-sm uppercase">
                  {/* <thead> */}
                    <tr>

                              
                    <th
                        class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                      >
                       ORDERED AT
                      </th>
    

                    <th
                        class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                      >
                       PRODUCT
                      </th>
    
    
                      <th
                        class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                      >
                       SOLD QUANTITY
                      </th>
    
    
                      <th
                        class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                      >
                       TOTAL PRICE
                      </th>
    
                      <th
                        class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                      >
                       CUSTOMER
                      </th>
    
                    
                     
              
                    </tr>
                  {/* </thead> */}
                  <tbody>
    
    
                {data.map(prod=> 
                         <tr class="align-top" >
                        <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                             <p class="text-gray-900 whitespace-no-wrap">{moment(prod.order.ordered_at).format('YYYY-MM-DD HH:mm')}</p>
                           </td>
                   
                           <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                             <p class="text-gray-900 whitespace-no-wrap">{prod.product}</p>
                           </td>
                           <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                             <p class="text-gray-900 whitespace-no-wrap">{prod.charged_stock}</p>
                           </td>
                           <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                             <p class="text-gray-900 whitespace-no-wrap">KES {prod.total}</p>
                           </td>
                           <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                             <p class="text-gray-900 whitespace-no-wrap">{prod.order.customer}</p>
                           </td>

                         </tr>
                  
                    )}



                                 <tr class="align-top" >
                        <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                             <p class="text-gray-900 whitespace-no-wrap font-bold text-lg ">TOTAL</p>
                           </td>
                   
                           <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                             <p class="text-gray-900 whitespace-no-wrap"></p>
                           </td>
                           <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                             <p class="text-gray-900 whitespace-no-wrap">{total_qty}</p>
                           </td>
                           <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                             <p class="text-gray-900 whitespace-no-wrap">KES {total_price}</p>
                           </td>
                           <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                             <p class="text-gray-900 whitespace-no-wrap"></p>
                           </td>

                         </tr>                                                                          
                   
                  
                    
    
                  </tbody>
                </table>
              </div>
            </div>
            :
            <div className="w-full lg:w-1/2 text-center">
            <div className="rounded-lg w-full shadow border-2 border-blue-600 py-3 my-2">
                <div className="w-full flex justify-between  text-blue-600 py-1 px-5 ">
                        <span className="material-icons-outlined">
                        feedback
            </span>
                    <p className="font-extrabold"> Results Not Found </p>
                </div>
            </div>
            
                    </div>
         );
    
}
 
export default ProductSalesTable;