import deliveryLottie from '../../../lotties/delivery.json';
import Lottie from 'react-lottie';
import { useEffect } from 'react';

const StartDistribution = ({...props}) => {
  const completed = props.completedItems

  useEffect(() => {
    props.getCompleted()

  }, [])


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: deliveryLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    const handleClick = () => {
        const user = {distributed_by:1};
        props.startDelivery(user)
    }

    return ( 

      <div className="flex flex-wrap w-full ">
        <div className="w-full lg:w-1/2   px-3">
            {completed === null ? <p>Loading...</p> :
            completed.length > 0 && 
              <> 
             <div className="w-full text-blue-600 my-1 font-bold text-left mt-5">Produced Products</div>

            <div className="w-full flex flex-wrap p-2">

          {  completed.map(mix=>
          <>
            <div className="my-1 px-1 w-full overflow-hidden  lg:w-1/2">
            <div className="border border-blue-200 rounded-lg shadow border-0 flex px-4 py-3  justify-between w-full text-left">
                <p className="font-bold text-gray-600">{mix.product.name}</p>
                <div className="flex flex-row">
                    <div className="bg-red-200 text-red-600 p-2   rounded-full  text-xs">{mix.quantity} pcs</div>
                    <div className="bg-blue-200 text-blue-600 p-2 ml-2  rounded-full  text-xs">{mix.mixes} mixes</div>
                </div>
            </div>
            </div>

</>
            )}
            
              


            </div>
            </>
            }
        </div>
        <div className="w-full lg:w-1/2 ">
                    <div className="w-full">
                    <Lottie 
                options={defaultOptions}
                height={400}
                width={400}
                />
                    </div>
                
                
                <div className="my-5 w-3/4 lg:w-1/3 mx-auto">
                <button className="focus:outline-none my-2 py-2 w-full border-2 border-blue-500 rounded-lg font-bold text-blue-500" onClick={handleClick} >Initialize Distribution</button>
                </div>
        </div>

      </div>
        
     );
}
 
export default StartDistribution;