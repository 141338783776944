import IngredientBreadcrumb from '../layouts/ingredients/breadcrumb'
import FAB from '../layouts/ingredients/fab';
import IngList from '../layouts/ingredients/IngList';
import SearchIng from '../layouts/ingredients/searchIng';
import Navbar from '../universal/navbar';
import { useEffect } from 'react'
import BreadCrumbs from '../universal/breadcrumbs';


const Ingredients = ({...props}) => {

    useEffect(() => {
        props.fetchIngredients()
    }, [])

    // console.log(props.ingredients)

    return ( 
   
        <div className="relative h-screen overflow-y-hidden mx-auto">
            <Navbar active="ingredients" />
            <div className="w-full px-2 lg:px-6">  
            <BreadCrumbs newPage="Ingredients" />
            <FAB ingredients={props.ingredients} addIngredients={props.addIngredients} addBrand={props.addBrand} />
            <SearchIng />
            <IngList ingredients = {props.ingredients} loading={props.loading} /> 
            
            
            </div>          
        </div>
       
     );
}
 
export default Ingredients;