import { createSlice } from "@reduxjs/toolkit";

export const mixSlice = createSlice({
    name:"mixes",
    initialState: {
        loading:false,
        error:false,
        errorMsg:null,
        mixes:null,
    },

    reducers: {
        loading_mixes: (state)=> {
            state.error = false;
            state.errorMsg = null;
            state.loading = true;
        },
        success_mixes: (state,action)=>{
            state.loading= false;
            state.mixes = action.payload;
        },
        error_mixes: (state,action)=>{
            state.loading = false;
            state.errorMsg = action.payload;
            state.error = true
        },
        clear_error_mixes:(state)=>{
            state.error = 'false'
        }
    }
})


export const  {success_mixes,loading_mixes,clear_error_mixes,error_mixes} = mixSlice.actions

export const mixes_state = (state) => state.mixes

export default mixSlice.reducer