import BreadCrumbs from "../universal/breadcrumbs"
import FabOrders from '../layouts/orders/fabOrders'
import OrdersList from "../layouts/orders/ordersList"
import SearchOrders from "../layouts/orders/searchOrders";
import Navbar from "../universal/navbar";
import { useEffect,useState } from "react";
import {useSelector} from "react-redux"
import {userRole} from "../../features/userSlice"
import RemainingMixes from "../layouts/orders/remainingMixes";


const Orders = ({...props}) => {

    const role = useSelector(userRole)

    useEffect(() => {
        props.fetchMixOrders()
        props.getProducts()
    },[])

    const [isKitchen,setIsKitchen] = useState(false)

    return (

        <div className="relative h-screen mx-auto ">
        <Navbar isKitchen={isKitchen} active="orders"/>
        <div className="w-full px-6">
        <BreadCrumbs newPage="Orders" />
           

    {role && 
        role !== "Chefs" && 
        <FabOrders  addOrderMix = {props.addOrderMix} products={props.products} />
    }

            <div className="w-full flex flex-wrap">

            <div className="w-full lg:w-3/4 p-2">
            <OrdersList updateMix={props.updateMix} isKitchen={isKitchen}  updateProduction={props.updateProduction} mixorders= {props.mixorders} addMixIngredients={props.addMixIngredients} addProduction={props.addProduction} />
            </div>

            <div className="w-full lg:w-1/4 p-2">
                <RemainingMixes />
            </div>

            </div>

       

        </div>

        </div>
     );
}
 
export default Orders;