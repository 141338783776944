// import { toast,ToastContainer } from 'react-toastify';
import { useState } from "react";
import { LoginUser,Logout } from "../../api/loginAPI";
import {loginPending, loginStates,loginSuccess,loginFailed,logoutUser} from "../../features/loginSlice";
import { Redirect } from "react-router";
import { useSelector,useDispatch ,connect} from "react-redux";
import { UserDetails } from "../../api/userAPI";
import { userSuccess } from "../../features/userSlice";
import LoginPasscode from "../login/modal/loginModal";
import {useNavigate} from "react-router-dom" 



// toast.configure()

const Login = () => {

const [username,setUsername] = useState(null)
const [password,setPassword] = useState(null)


const [showPin,setShowPin] = useState(false)

const [res,setRes] = useState(null)

const dispatch = useDispatch()


const loginS = useSelector(loginStates)

const handleLogin = ({...props}) => {
    const loginObj = {
        username:username,
        password:password
    }
    dispatch(loginPending())

    dispatch(LoginUser(loginObj))
}

!loginS.isAuth && (
  
    localStorage.getItem('b_token') && dispatch(UserDetails())
)


    return ( 

        <>

        {loginS.isAuth ? <Redirect to={{
                pathname: "/",
            }} /> :

        <div className="w-full p-3 h-screen flex items-center justify-center ">
            <div className="w-full lg:w-1/3 p-3 ">
                    <div className="shadow px-6 py-7">
                        <p className="text-center  my-3 font-bold text-xl">Bakery <sup>&reg;</sup></p>


                {
                    loginS.failed && 

                    <div className="w-full my-4 bg-red-500  py-5 text-white px-2 lg:px-5 text-left rounded-sm">
                        <p className="font-bold text-white font-xs capitalize ">Incorrect Username Or Password</p>
                    </div>

                }


                    <div className="text-gray-400 w-full my-4 text-left leading-relaxed">
                    <label className=" font-bold " >Enter Username </label>
                    <input type="text" onChange={(e)=>setUsername(e.target.value)} className="border border-1 rounded-lg   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>

                    <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label className="font-bold" >Enter Password </label>
                    <input type="password"  onChange={(e)=>setPassword(e.target.value)} className="border border-1 rounded-lg   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>

                    {
                        loginS.isLoading ?       <button className="bg-blue-600 py-3 text-lg mt-4  outline-none  text-white font-bold w-full" disabled >Logging ...</button> :
                        <button className="bg-blue-600 py-3 text-lg mt-4 outline-none  text-white font-bold w-full" onClick={handleLogin}>Log In</button>

                    }

                    <button className="w-full text-blue-600 py-3 font-bold my-3 border-2 border-blue-600 rounded focus:outline-none hover:text-white hover:bg-blue-600" onClick={()=>setShowPin(true)} >
                    Enter PIN
                    <span class="material-icons-outlined inline-flex align-bottom ml-5">
password
</span>



                    </button>

        {showPin && 
        
<LoginPasscode setShowPin={setShowPin}  />
        
        }


      
                    </div>
            </div>
        </div>
 }
        </>
     );
}


export default Login;
