import TransporterCard from "./transporterCard";

const TransporterList = ({transporters}) => {
    return ( 
        transporters.map(transporter=>(
            <TransporterCard transporter={transporter} />
        ))
     );
}
 
export default TransporterList;