import { useState,Fragment } from 'react'
import AddDistributionModal from './modals/AddDistributionModal'
import AddDelivery from  './modals/addDelivery'

import ProceedToCustomer from "./modals/ProceedToCustomer";
import IssueToShop from './modals/issueToShop';
import ConfirmPush from './modals/confirmPush';

const FabDistribution = ({...props}) => {
    const [ingMenuState,setMenuState] = useState('hidden')

    const [showCustomers,setShowCustomers] = useState(false)

    const [orderObj,setOrderObj] = useState(null)

    const [orderModal,setOrderModal] = useState(false)

    const [confirmPush,setConfirmPush] = useState(false)

    console.log(confirmPush)

    const [distObj,setDistObj] = useState(null)

    const [showIssue,setShowIssue] = useState(false)


    const showMore = ()=> {
      if (ingMenuState === 'hidden'){
        setMenuState('')
      }else{
        setMenuState('hidden')
      }
    }

  return ( 
  <Fragment >
  <div className={`hiddenFloat ${ingMenuState}  fixed bottom-24 right-8`}>

  {/* <IssueToShop  setShowIssue={setShowIssue}  showIssue={showIssue} addShopStock={props.addShopStock} distribution={props.distribution} setDistObj={setDistObj} setConfirmPush={setConfirmPush} /> */}

  <AddDistributionModal   distribution={props.distribution} setOrderObj={setOrderObj}  orderModal={orderModal} setOrderModal={setOrderModal} showCustomers={showCustomers} setShowCustomers={setShowCustomers} />
  
  <AddDelivery distributionId={props.distribution.id}  addDelivery={props.addDelivery} vehicles={props.vehicles} drivers={props.drivers} fetchDrivers={props.fetchDrivers} fetchVehicles={props.fetchVehicles} />


  </div>    

    
  
  <button  onClick={showMore} 
    className="p-0 w-16 bottom-7 fixed right-8 h-16 bg-blue-600 text-center rounded-full hover:bg-blue-900 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">  
      <span className="material-icons-outlined text-white">add</span>
  </button>


  { showCustomers ?  <ProceedToCustomer addOrderSell={props.addOrderSell} distItems = {props.distribution.items} showModal={setOrderModal} setShowCustomers={setShowCustomers} setOrderObj={setOrderObj} orderObj={orderObj}  fetchCustomers={props.fetchCustomers} customers={props.customers} /> : '' }


  {confirmPush && 
<ConfirmPush  setConfirmPush={setConfirmPush} distObj = {distObj} addShopStock={props.addShopStock} setShowIssue={setShowIssue}    />
  }
  





  </Fragment>
   );
}
 
export default FabDistribution;