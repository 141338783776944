const VehicleCard = ({vehicle}) => {
    return ( 
        <div className="flex w-full md:w-1/3  justify-between  flex-initial  font-bold text-sm shadow rounded-full  p-3 px-4 m-3">
            <div className=" mr-2">{vehicle.plate_no}</div>
            {vehicle.v_type === "Motor Bike" ?  (<span class="material-icons-outlined font-bold text-green-500 ">
delivery_dining
</span>) :  (<span class="material-icons-outlined ml-2 font-bold text-blue-500 text">
local_shipping
</span>)}
        </div>
     );
}
 
export default VehicleCard;