import { useState } from "react";
import SetPrices from "../modals/setPrices";
import ClientCard from "./clientCard";

const ClientList = ({...props}) => {
    const customers = props.customers

    const [cus,setCus] = useState(null)

    const [showModal,setShowModal] = useState(null)

    // console.log(cus)

    return ( 
        <>
    {    customers.map(client=>(
            <ClientCard showModal={setShowModal} setCus={setCus} client={client} />
        ))


}

{ showModal && 
        <SetPrices  updatePrice={props.updatePrice} cus={cus} showModal={setShowModal} />
    }
            </>
     );
}
 
export default ClientList;