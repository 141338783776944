import VehicleCard from './vehicleCard'
const VehicleList = ({vehicles}) => {
    
    return (  

        <div className="flex flex-wrap">
            {
                     vehicles.map(vehicle=>(
                        <VehicleCard  vehicle={vehicle} />
                ))
                
            
            }
        </div>
);
   
}
 
export default VehicleList;