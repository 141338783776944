import Load from "../../universal/load";
import ExpenseCard from "./expenseCard";

const ExpenseList = ({...props}) => {
    const expenses = props.expenses
    console.log(expenses)
    return ( 
        expenses == null ? <Load /> : 
        <>
    <div className="w-full flex flex-wrap my-3">
    {expenses.map(expense=>            
                <ExpenseCard  expense={expense}/>
            )}
    </div>
        </>
     );
}
 
export default ExpenseList;