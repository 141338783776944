const StockCard = ({...props}) => {
    const stock = props.stock
    return ( 
    
        <div className="w-full p-2 lg:w-1/4 text-left my-2">
            <div className="w-full shadow capitalize p-4 rounded-lg">
                <div className="flex justify-between">
                <div className="w-full flex flex-col">
                    <p className="font-bold py-1 uppercase">{stock.product.name}</p>
                    <p className="font-bold py-1 text-gray-400 uppercase">{stock.brand_name}</p>
                </div>
                <div className="w-full flex flex-col uppercase">
                    <div className="flex-none bg-green-200 py-1 my-1 px-3 text-green-600 rounded-lg text-xs font-bold ml-auto">REM || {stock.stockproducts.remaining_kgs} {stock.product.units} </div>
                    <div className="flex-none bg-red-200 py-1 my-1 px-3 text-red-600 rounded-lg text-xs font-bold ml-auto">STOCK IN || {stock.stockproducts.stock_in_pkg} {stock.product.units}</div>
                    <div className="flex-none bg-blue-200 py-1 px-3 my-1  text-blue-600 rounded-lg text-xs font-bold ml-auto">CONSUMED || {stock.stockproducts.stock_out_kgs} {stock.product.units} </div>
                </div>
                </div>
            </div>
        </div>

     );
}
 
export default StockCard;