import { useState,Fragment } from 'react'
import AddProductModal from './modals/addProductModal'
const FabProduct = ({addProduct}) => {  
        
  const [prodMenuState,setMenuState] = useState('hidden')

  const showMore = ()=> {
    if (prodMenuState === 'hidden'){
      setMenuState('')
    }else{
      setMenuState('hidden')
    }
  }

  return ( 
  <Fragment>
  <div className={`hiddenFloat ${prodMenuState}  absolute bottom-24 right-8`}>
  <AddProductModal addProduct={addProduct}/>
  </div>

    
  
  <button  onClick={showMore} 
    className="p-0 w-16 absolute  bottom-7 right-8 h-16 bg-blue-600 text-center rounded-full hover:bg-blue-900 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">  
      <span className="material-icons-outlined text-white">add</span>
  </button>
  </Fragment>
     );
}
 
export default FabProduct;