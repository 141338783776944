import { useSelector } from "react-redux";
import { systemusers } from "../../../features/sytemuserSlice";
import AddPasscode from "./modals/addPasscode";
import UserCard from "./userCard";
import {useState} from "react"
const UserList = () => {
    const users = useSelector(systemusers)
    const [showPass,setShowPass] = useState(false)
    const [userSelect,setUserSelect] = useState(null)
    return ( 
        <div className="w-full flex flex-wrap">
            {
                users &&
                users.users == null ? <p>Loading...</p> : 
                
                users.users.map(user=>
                    <UserCard user={user} userSet={setUserSelect} showpass={setShowPass} />
                    )

            } 

            
            {showPass && 
                <AddPasscode showPass={setShowPass} user={userSelect} />
            }

        </div>
     );
}
 
export default UserList;