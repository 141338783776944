const OrderCard = ({...props}) => {

    let status = props.status

    const mixorder = props.mixorder

    const collection = props.mixorder.mixcollections

    const openOrder = props.openOrder

    const product = mixorder.mix_details.product_details

    let c,w

    if(status === 'initialized'){

       if (collection.length <  1 ){
        c = 'red';
        w= '1/4';
        status = 'initialized'
        
       }else{
        c = 'green';
        w= '1/2';
        status = 'on progress'
       
       }
 
    }else {
        c = 'blue'
        w= '5/6';
        status = status
       
    }

   

    return (

    !props.isKitchen && status == 'produced' ? 

mixorder.production.map(production=>

    production.status == 'pending' &&

        <div className="w-full  px-3 lg:w-1/4 my-2 ">
        <div onClick={()=> openOrder(mixorder.id,status,production.id) } className={`w-full cursor-pointer border-4 border-${c}-400  shadow overflow-hidden rounded-lg`}>
      
                <div class="flex-none w-1/3 relative">
                    <img src={product.image_url} alt="" class="absolute -ml-1 inset-0 w-full h-full object-cover" />
                </div>
                <div className="flex flex-col w-full px-4 py-3">
                   <div className="flex justify-between">
              
                   <h3 className="text-gray-500 font-bold">{production.productName}    {production.type == 'shared' && <span className="bg-blue-400 mr-auto text-white rounded-full py-1 px-3 text-xs lowercase">shared from {product.name} mix</span> } </h3>
                   {/* <p className="font-bold">8:00 PM</p> */}  
                   </div>
                    <div className="flex  justify-between  mt-4">
                            <p className="font-bold text-right text-sm">{production.quantity} / {mixorder.quantity } Mixes</p>
                            <div className="w-1/2">
                                    <div className="bg-gray-200 relative h-1 mt-4 rounded w-full">  
                                           <span className={`bg-${c}-400 w-${w} left-0 h-1 absolute rounded`}></span>
                                    </div>
                            </div>                         
                    </div>
                </div>
        </div>

    </div>

)

    :

    status !== 'produced' &&  (

        <div className="w-full  px-3 lg:w-1/4 my-2 ">
    <div onClick={()=> openOrder(mixorder.id,status,1) } className={`w-full cursor-pointer border-4 border-${c}-400  shadow overflow-hidden rounded-lg`}>
   
            <div class="flex-none w-1/3 relative ">
                <img src={product.image_url} alt="" class="absolute -ml-1 inset-0 w-full h-full object-cover" />
            </div>
            <div className="flex flex-col w-full px-4 py-3">
               <div className="flex justify-between">
               <h3 className="text-gray-500 font-bold">{product.name}</h3>
               {/* <p className="font-bold">8:00 PM</p> */}
               </div>
                <div className="flex  justify-between  mt-4">
                        <p className="font-bold text-right">{mixorder.quantity} Mixes</p>
                        <div className="w-1/2">
                                <div className="bg-gray-200 relative h-1 mt-4 rounded w-full">  
                                       <span className={`bg-${c}-400 w-${w} left-0 h-1 absolute rounded`}></span>
                                </div>
                        </div>                         
                </div>
            </div>
    </div>
</div>
    )

      );
}
 
export default OrderCard   ;