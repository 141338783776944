import moment from "moment";

const StockOutReportTable = ({data}) => {
    return ( 


   data.length > 0 ? 

        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div
            class="inline-block min-w-full shadow-md rounded overflow-hidden"
          >
            <table class="min-w-full leading-normal text-sm uppercase">
              <thead>
                <tr>
                <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                   Collected On
                  </th>
                  <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                   Product
                  </th>
                  <th
                    class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                   Amount Collected
                  </th>
                </tr>
              </thead>
              <tbody>

                    
                    
                {
                  data.map(collection=> 
                    <tr class="align-top" >
                    <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                        <p class="text-gray-900 whitespace-no-wrap">{moment(collection.collected_at).format('YYYY-MM-DD HH:mm')}</p>
                      </td>
                      <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                        <p class="text-gray-900 whitespace-no-wrap">{collection.brand.product.name} - {collection.brand.brand_name}</p>
                      </td>
                      <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-sm">
                        <p class="text-gray-900 whitespace-no-wrap">{collection.quantity}  {collection.brand.product.units}</p>
                      </td>
                    </tr>
                    )
                }

              
                

              </tbody>
            </table>
          </div>
        </div>
        :
        <div className="w-full lg:w-1/2 text-center">
        <div className="rounded-lg w-full shadow border-2 border-blue-600 py-3 my-2">
            <div className="w-full flex justify-between  text-blue-600 py-1 px-5 ">
                    <span className="material-icons-outlined">
                    feedback
        </span>
                <p className="font-extrabold"> Results Not Found </p>
            </div>
        </div>
        
                </div>
        
     );
}
 
export default StockOutReportTable;