import { BlueLoader } from "./loaders";

const LoginLoad = () => {
    return ( 

            <div className="w-full h-screen flex justify-center items-center">
                <BlueLoader />
            </div>

     );
}
 
export default LoginLoad;