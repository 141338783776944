import RemainingCard from "./remainingCard";

const RemainingProd = ({products}) => {

    return ( 
        products.map(product=>(
            <RemainingCard  product={product} />    
        ))

     );
}
 
export default RemainingProd;