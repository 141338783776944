import BreadCrumbs from "../universal/breadcrumbs"
import Navbar from "../universal/navbar";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment'
import { useState } from "react";
import CollectionCard from "../layouts/collections/collectionCard";



const Collection = ({...props}) => {

    const [selectedDate,setSelectedDate] = useState()



    const handleSubmit = () => {

            const date = moment(selectedDate)
            if(date.isValid()){
                props.fetchCollections(date.format('YYYY-MM-DD'))       
            }else{
                console.log('Invalid Date')
            }

    }


    const collections = props.collections

 
    return ( 
        <div className="relative h-screen mx-auto ">
     <Navbar active="collections"/>
        <div className="w-full px-6">
    <BreadCrumbs newPage="Mix Collections" />   


        <div className="w-full p-3 flex flex-wrap items-end">
            <div className="w-full text-left lg:w-1/6 p-2">
            <p className="text-gray-500 font-bold py-3">Select Collection Date</p>
            <DatePickerComponent id="datepicker" format='yyyy-MM-dd'  onChange={(e)=>setSelectedDate(e.target.value)} />    
            </div>

            <div className="w-full lg:w-1/6 px-4 py-2">
                <button className="w-full font-bold border-2 border-red-400 text-red-400 mb-2 focus:outline-none rounded-lg py-3" onClick={handleSubmit}>Get Collections</button>
            </div>


        


        </div>


        {collections === null ? '': 
                <div className="w-full my-3">
        {collections.length > 0 ? 
        
        <div className="w-full">

                <CollectionCard collections={collections} selectedDate={selectedDate} updateCollection={props.updateCollection} />

        </div>
            
        :

<div className="w-full  lg:w-1/2 text-center">
<div className="rounded-lg w-full shadow border-2 border-red-400 py-3 my-2">
    <div className="w-full flex justify-between  text-red-400 py-1 px-5 ">
            <span className="material-icons-outlined">
            feedback
</span>
        <p className="font-extrabold"> Zero Mixes Found</p>
    </div>
</div>

        </div>
    }
                </div>

        } 


        


        </div>
        </div>
    
     );
}
 
export default Collection;