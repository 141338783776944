import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name:"products",
    initialState: {
        loading:false,
        error:false,
        errorMsg:null,
        products:null,
    },

    reducers: {
        loading_products: (state)=> {
            state.error = false;
            state.errorMsg = null;
            state.loading = true;
        },
        success_products: (state,action)=>{
            state.loading= false;
            state.products = action.payload;
        },
        error_products: (state,action)=>{
            state.loading = false;
            state.errorMsg = action.payload;
            state.error = true
        },
        clear_error_products:(state)=>{
            state.error = 'false'
        }
    }
})


export const  {success_products,loading_products,clear_error_products,error_products} = productSlice.actions

export const products_state = (state) => state.products

export default productSlice.reducer