// slice -- Stock In Report 
import {createSlice} from "@reduxjs/toolkit"


export const stockinreportSlice = createSlice({
    name:"stockinreports",
    initialState: {
        loading:false,
        error:false,
        errorMsg:null,
        data:null,
    },

    reducers: {
        loading_abr_sir: (state)=> {
            state.error = false;
            state.errorMsg = null;
            state.loading = true;
        },
        success_abr_sir: (state,action)=>{
            state.loading= false;
            state.data = action.payload;
        },
        error_abr_sir: (state,action)=>{
            state.loading = false;
            state.errorMsg = action.payload;
            state.error = true
        },
        clear_error_abr_sir:(state)=>{
            state.error = 'false'
        }
    }
})


export const  {success_abr_sir,loading_abr_sir,error_abr_sir,clear_error_abr_sir} = stockinreportSlice.actions

export const stores_sir = (state) => state.stockinreports

export default stockinreportSlice.reducer

