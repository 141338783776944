import { Fragment } from "react";
import { ErrorAlert } from "../../../alerts/alerts";
import WarningAlert from "../../../alerts/warningAlert";
import { useState,useEffect } from "react";

const OrderPending = ({setShowModal,mixproduct,addMixIngredients}) => {

 const mixorder = mixproduct[0]

 const quantity = mixorder.quantity

 const  mixingredients = mixorder.mix_details.ingredients

 const ingWithMore = mixingredients.filter(x=>x.ingredient_details.brands.length > 1).filter(x=>x.ingredient_details.type==='auto')

 const ingWithOutMore = mixingredients.filter(x=>x.ingredient_details.brands.length === 1).filter(x=>x.ingredient_details.type === 'auto')


  const [autos,setAut] = useState( mixingredients.filter(x=>x.ingredient_details.type === "auto").filter(x=>x.ingredient_details.brands.length > 0 ).map(ing=>
    {

      let brand 
      let ingredient
      let quantity
      let autoObj={}
      
      ingredient = ing.ingredient_item
      ing.ingredient_details.brands.length === 1 ?  brand = ing.ingredient_details.brands[0].id : brand = null
      quantity = ing.quantity
  
      autoObj['product']=ingredient
      autoObj['brand']=brand
      autoObj['quantity']=quantity*mixorder.quantity

      return autoObj

    }))







 
 const product = mixorder.mix_details.product_details

 const [selected,setSelected] = useState([])

 const makeSelected = (id) =>{
  const selectedI = {
    brand:id,
    quantity:0,
    }
      setSelected([...selected,selectedI]) 
  }

  const setB = (brand,ingredient) => {
    autos.find(o=>o.product===ingredient).brand=brand
    setAut([...autos])
  }

 const removeSelected = (id) => {
  setSelected(selected.filter(item=> item.brand !== id))
 }


 const handleChange = (e,id) => {
   selected.find(s=>s.brand === id).quantity=e.target.value
  }

  const handleSubmit = () => {

    autos.map(a=>
       { delete a.product
         return a}
      ) 
   

    const addColl = [...autos,...selected]


    const newObj = {
      'mix':mixorder.id,
      'collected_by':1,
      'ingredients':addColl
    }

   

    setShowModal(false)
    addMixIngredients(newObj)


  }

    return ( 
        <Fragment>
                <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-2 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                    {/*content*/}
                <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                <div className="flex flex-row overflow-hidden">
                          <div class="h-43 flow-hidden flex">
                              <div className="p-4">
                                    <img src={product.image_url} alt="" className="object-cover  border-4 border-red-400 rounded-full h-24 w-24" />
                              </div>
                          </div>
                          <div className="flex-grow py-5 px-2">
              <div className="flex justify-between text-black w-full">
              <p className="font-bold text-xl ">{product.name}</p>
              <button className="focus:outline-none mr-2"><span className="material-icons-outlined" onClick={()=>{setShowModal(false)}} >close</span></button>
              </div>
              <div className="flex flex-col text-gray-400">
                <p className="font-bold text-left">{mixorder.quantity} Mixes</p>
              </div>
                          </div>
                  </div>


        <div className="w-full">
          
        </div>


                  <div className="text-left">

              {mixingredients.length < 1 ? (<ErrorAlert message="Ingredients Not Assigned" />) : (
                <>
                              <div className="flex text-red-400 text-lg px-3 font-bold">Ingredients Collection</div>
              
              <div className="flex flex-wrap  overflow-hidden overflow-y-scroll max-h-48 mt-4 sm:m-2  px-4 py-3">
                  {mixingredients.filter(x=>x.ingredient_details.type == 'manual').map(ing=>
                            
                      ing.ingredient_details.brands.map(brand=> {
                        if(selected.find(s => s.brand === brand.id)){
                        return (  
                          <button className={`focus:outline-none border m-2 rounded-2xl border-1 border-red-400 font-bold px-4 py-2 text-xs uppercase font-extrabold text-red-400`}  onClick={()=> removeSelected(brand.id) } key={brand.id}> <span className={`material-icons-outlined font-extrabold  text-xs text-red-400`}>done</span> {ing.ingredient_details.name} | {brand.brand_name}  </button>                       
                        )
                        }else{
                          return  (<button className={`focus:outline-none border m-2  rounded-2xl border-1 cursor-pointer border-gray-300 font-bold px-4 py-2 text-xs uppercase text-gray-500`} onClick={()=> makeSelected(brand.id)} key={brand.id} > <span className={`material-icons-outlined hidden text-sm text-blue-600`}>done</span> {ing.ingredient_details.name} | {brand.brand_name}  </button>)
                        }
                      })
                  // )
                  //  }
                   )}
                  </div>


               
                </>
              )  }
              </div>
                  
                
              {selected.length > 0 && (
              <div className="w-full px-4 text-left  my-2">
                <p className="font-bold text-red-400 ">Enter Ingredients Amount (Manuals)</p>
                <div className="w-full overflow-y-scroll max-h-48">
                {mixingredients.map(ing=> {       
       return (
                ing.ingredient_details.brands.map(brand=> { 
                  return (
                  selected.find(s=>s.brand===brand.id) && (
                    <div className="w-full flex flex-row  my-1 justify-between  px-2 shadow py-2">
                    <p class="py-3 px-3  w-3/6 text-xs uppercase  text-left font-bold text-black" >{ing.ingredient_details.name} | {brand.brand_name} </p>
                    <p className="text-gray-400 mt-3  w-1/6 text-xs font-bold">{ing.quantity} <span className="uppercase"> {ing.ingredient_details.units}</span> </p>
                    <input type="number" onChange={(e)=> { handleChange(e,brand.id) }} placeholder="Enter Amount" minimum="1" className="border border-1 my-1  w-2/6 rounded text-sm w-1/5   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-1 px-3  border-gray-200" />
                    </div> 
                    // <p>w</p>
                  ) 
                
                  )
                })
       )        
    })}

</div>
</div>

)}

{ingWithMore.length > 0 && 
<div className="w-full my-2 max-h-48 overflow-y-scroll px-3 text-left">
<p className="font-bold text-red-400  mb-3">Select Brands (Auto)</p>
{ingWithMore.map(ing=>
  <div className="w-full flex shadow px-2 align-center text-sm py-2 uppercase">
    <div className="w-1/3">
      <p className="font-bold text-gray-500">{ing.ingredient_details.name}</p>
    </div>
    <div className="flex-grow flex-wrap text-right">
      {ing.ingredient_details.brands.map(brand=>
      autos.find(b=>b.product == ing.ingredient_item).brand === parseInt(brand.id) ?
      <button  onClick={()=>setB(brand.id,ing.ingredient_item)} className="m-1 border-red-400 font-bold  focus:outline-none text-red-400 border-2 py-1 px-3 rounded-full uppercase">
        {brand.brand_name}
      </button>
      :      <button  onClick={()=>setB(brand.id,ing.ingredient_item)}   className="m-1 border-gray-300 font-bold  focus:outline-none text-gray-300 border-2 py-1 px-3 rounded-full uppercase">
      {brand.brand_name}
    </button>
        )}
        
    </div>
  </div>  
)}
</div>
}



<div className="flex text-center my-3 mt-4">
                  <button className="w-1/2 bg-red-400 text-white mx-auto focus:outline-none rounded-xl py-3" onClick={handleSubmit}>Submit</button>
                </div>
                
            





                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
     );
}
 
export default OrderPending;