import { createSlice } from "@reduxjs/toolkit";

export const customerslice = createSlice({
    name:"customers",
    initialState: {
        loading:false,
        error:false,
        errorMsg:null,
        customers:null,
    },

    reducers: {
        loading_customers: (state)=> {
            state.error = false;
            state.errorMsg = null;
            state.loading = true;
        },
        success_customers: (state,action)=>{
            state.loading= false;
            state.customers = action.payload;
        },
        error_customers: (state,action)=>{
            state.loading = false;
            state.errorMsg = action.payload;
            state.error = true
        },
        clear_error_customers:(state)=>{
            state.error = 'false'
        }
    }
})


export const  {success_customers,loading_customers,clear_error_customers,error_customers} = customerslice.actions

export const customer_state = (state) => state.customers

export default customerslice.reducer