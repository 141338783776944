import { useState } from 'react'


export default function AddTransportation({addVehicle}) {

    const [showModal,setShowModal] = useState(false) 
    const [vehicleSelected,setVehicleSelected] = useState('')  
    
    const vehicles = [
      {"name":"Canter Truck"},
      {"name":"Motor Bike"},
    ]
   
    const makeSelected = (selected) => {
        setVehicleSelected(selected)
        setAddedVehicle({
          ...addedVehicle,
          ['v_type']:selected
        })
    }

    const [addedVehicle, setAddedVehicle] = useState({})

    
    const handleChange = (e) => {
        setAddedVehicle({
          ...addedVehicle,
          [e.target.name]:e.target.value
        })
    }

    const handelSubmit = () => {


      
      addVehicle(addedVehicle)

      setShowModal(false)

    }

    return (

        <>

<button onClick={()=> { setShowModal(true) }} className="h-13 w-13 my-2 focus:outline-none px-3 py-2 text-white bg-gray-400 rounded-xl text-xs">
    Add Transportation
  </button>
        <br />

        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/4  px-3">                {/*content*/}
                <div className="border-1 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                  {/* header section */}

                  <div className="flex justify-between px-6 pt-5 pb-1">
                    <p className="text-xl font-bold">Add Transportation</p>
                    <button onClick={()=> setShowModal(false)} className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                  </div>

                  {/*body*/}
                  <div className="relative px-6 pb-3 flex-auto">

                    <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Plate No. / Reg No.  </label>
                    <input type="text" name="plate_no" onChange = {(e)=>handleChange(e)} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>

                    <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                    <label>Model Type  </label>
                    <input type="text"  name="v_model" onChange = {(e)=>handleChange(e)} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                    </div>

                    <div className="w-full mt-5 mb-4 text-left">
                      <p className="font-bold my-3" >Select Transportation Type</p>
                          {vehicles.map(vehicle=> {
                          if(vehicleSelected === vehicle.name){
                       return     <button className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-pointer border-blue-600 font-bold px-4 py-2 text-sm text-blue-600`} onClick={()=> makeSelected(vehicle.name) }> <span className={`material-icons-outlined  text-sm text-blue-600`}>done</span> {vehicle.name}</button>
                          }else{                           
                            return  <button className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-pointer border-gray-300 font-bold px-4 py-2 text-sm text-gray-500`} onClick={()=> makeSelected(vehicle.name) }> <span className={`material-icons-outlined hidden text-sm text-blue-600`}>done</span> {vehicle.name}</button>
                          }
                          }
                        )}
                    
                    </div>

              
              
                   

                    <button className="mt-4 py-3 bg-blue-600 w-full focus:outline-none text-bold text-white font-bold rounded-xl mb-4" onClick={handelSubmit}>Submit</button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
  