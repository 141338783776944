import { useState,useEffect } from 'react'

import WarningAlert from '../../../alerts/warningAlert'
import AddRecipeModal from '../modals/addRecipeModal';

export default function ProductRecipeShow({...props}) {

    const [showModal,setShowModal] = useState(false)  

    const product = props.product

    
   const [estimate,setEstimate] = useState()



    const submitMix = () => {
      const  mixData = {
          estimation:estimate,
          product:product.id,
          status:'active',
        }
      props.addMix(mixData)
      console.log(mixData)
      setShowModal(false)
    }



    return (

        <>

<button  onClick={()=> { setShowModal(true);  }} className="focus:outline-none">
<span className="material-icons-outlined cursor-pointer ">dashboard</span>
  </button>
        <br />

        {showModal ? (
        <>
           <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-2/3 lg:w-1/3 xl:w-1/3  px-3">                {/*content*/}
              <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        {props.loading ? (
          <p>Loading</p>
        ): (
          <>
            {product === null ? (
              <p>Null</p>
            ): (
              <>          


                <div className="flex flex-row overflow-hidden">
                        <div className="h-43 flow-hidden flex">
                            <div className="p-4">
                                  <img src={product.image_url} alt="" className="object-cover rounded-full h-24 w-24" />
                            </div>
                        </div>
                        <div className="flex-grow py-5 px-2">
            <div className="flex justify-between text-black w-full">
            <p className="font-bold text-xl ">{product.name}</p>
            <button className="focus:outline-none mr-2"><span className="material-icons-outlined" onClick={()=>{setShowModal(false)}} >close</span></button>
            </div>

      

            <div className="flex flex-col text-gray-400 mt-2 font-bold">
            {product.mix === null ? '' : <p className="text-xs font-bold my-1">{product.mix.estimation} Pcs/Mix</p>}
              <p>KSH {product.price}.00</p>
              {/* <p>{product}</p> */}
            </div>
                        </div>
                </div>



          {product.mix === null ? (
            <div className="w-full px-4">
              <div className="text-gray-400 w-full my-2 text-left leading-relaxed">
                  <label className="font-bold" >Enter Estimated Products Per Mix  </label>
                  <input type="number"  onChange={(e)=>{
                    setEstimate(e.target.value)
                  }} className="border border-1 rounded-xl   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" />
                  </div>

                  <div className="w-full mx-auto my-3 md:w-2/3 lg:w-1/2">
                    <button onClick={submitMix} className="w-full focus:outline-none bg-blue-600 rounded-lg py-3 text-white font-sm font-bold">Submit</button>
                  </div>
              
            </div>
          ) :  (
            <>

          {product.mix.ingredients.length < 1  ? (
            <> 
             <WarningAlert message="Please make the recipe ." />        
             <AddRecipeModal addRecipe={props.addRecipe} product={product} ingredients={props.ingredients} /> 
            </>
          ) : (
           
            <>
           
        <div className="w-full px-5 pt-1 pb-4">
          <div className="text-black font-bold">{product.name} Ingredients / Mix</div>
        </div>

        <div className="flex w-full flex-wrap ">

          {product.mix.ingredients.map((ingredient)=> {
return (
  <div className="w-full md:w-1/2 p-2 "> 
  <div className="w-full border px-2 py-3 flex justify-between font-bold text-sm text-gray-500">
    <p>{ingredient.ingredient_details.name}</p>
    <div className="bg-blue-500 text-xs rounded-full px-2 py-1 text-white ">{ingredient.quantity} <span className="ml uppercase">{ingredient.ingredient_details.units}</span> </div>
  </div>
</div>  
)
          }) }

       
    


        </div>

          

 </>

) }

            </>
            )
        }



        





           
          
              </>
            )}
        </>
        )}
           </div>
            </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
        ) : null}
      </>
    );
  }
  