import {Fragment} from 'react'

const ConfirmPay = ({...props}) => {

    const payObj = props.payObj

const handleSubmit = ()=>{
    props.addPayment(payObj)
    props.setShowConfirmPay(false)
}

    return ( 


 <Fragment>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
            {/*content*/}
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">




                  <div className="relative px-4 w-full pb-3    mt-5 text-left">
                                    
                                    <div className="text-2xl font-bold text-gray-700 text-center capitalize">Confirm Receive Payment ?</div>

                    </div>


                    <div className="w-full flex justify-between px-3 mb-4">

<button  onClick={handleSubmit} className="bg-green-500 text-white font-bold text-lg rounded-full py-2 px-8 focus:outline-none">
    Yes
</button>

<button className="bg-red-500 text-white font-bold text-lg rounded-full py-2 px-8  focus:outline-none "          onClick={
                            ()=> {
                                props.setShowConfirmPay(false)
                                props.setShowPayOrder(true)
                            }
                        } >
    No
</button>

                    </div>



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</Fragment>
     );
}

export default ConfirmPay;