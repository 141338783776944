import SearchClients from "./clients/searchClients"
import {WarningAlert} from '../../alerts/alerts'
import ClientList from "./clients/clientList"
import { useRef } from "react"
import { YellowLoader } from "../../universal/loaders"
const Clients = ({...props}) => {


const cells = useRef(null)

const customers = props.customers
console.log('cards');



const handleSearch = (e) =>{
    const cards = cells.current.children

    const searchText = e.target.value.toLowerCase()

    for (const card  of cards){
        card.textContent.toLowerCase().indexOf(searchText) != -1 ? card.classList.remove('hidden') : card.classList.add('hidden') 
    }
  

    
}


    return ( 
        <div className="w-full  p-4 px-3   overflow-x-hidden   md:w-1/3 lg:w-1/3 xl:w-1/3 rounded-lg">
                <div className="w-full p-3 shadow overflow-x-hidden">
            <div className="text-left p-4">
                <p className="font-bold">Clients List</p>
                <SearchClients  handleSearch={handleSearch} />
            
                {customers === null ? (
                    <div className="w-full flex justify-center h-48 item-cente">
                        <YellowLoader />
                    </div>
                ) :
                (
                    customers.length > 0 ? 
                        (
                        <div className="max-h-48 mt-3 overflow-y-scroll   w-full"  ref={cells} >
                        <ClientList  updatePrice={props.updatePrice} customers={customers} />
                        </div>
                        ) :
                         (
                            <div className="w-full mt-4">
                        <WarningAlert message="Empty Client List" />
                        </div>
                        )                      
                    
                )
                }


</div>
            </div>
        </div>
     );
}
 
export default Clients;