import { useState } from "react";
import axios from "axios";
import { stringify } from "postcss";
const Test = () => {
   
    const apiUrl = process.env.REACT_APP_API_URL


    const [image,setImage] = useState(null)

 const  handleImageChange = (e) => {
        setImage(e.target.files[0])
  };

  const handleSubmit = ()=>{
    let form_data = new FormData();
    console.log(image)
    form_data.append('image',image,image.name)
    form_data.append('location','{ "type": "Point", "coordinates": [-123.0208, 44.0464] }')
    form_data.append('height',15)

    sendI(form_data)    

  }

  const sendI = (form_data) => {
    const headers = {
        'Authorization': 'Token 87866a159fc0ff8f8147b777c5dde53ed8c4f631',
        'content-type': 'multipart/form-data'
    }

    axios.post('https://api.greeeth.com/trees/',form_data,{ headers : {
        'Authorization': 'Token 87866a159fc0ff8f8147b777c5dde53ed8c4f631',
        'content-type': 'multipart/form-data'
    }}).then((res)=>{
        console.log(res)
    })
  } 

    
    

    return ( 

    
        <>
       
                <input type="file"  onChange={(e)=>handleImageChange(e)} />
                <button onClick={handleSubmit} > Submit </button>
        </>


     );
}
 
export default Test;