import { createImageFromInitials,getRandomColor } from '../../../utils/randomPics'
const   ClientCard = ({...props}) => {
const client = props.client
    return ( 
        <div className="shadow-sm flex rounded-lg text-left my-2  p-4 mx-1">
        <div className="mr-4">
        <img className="rounded-full"
    id='preview'
    src={
         createImageFromInitials(40,client.name , getRandomColor())				}
    alt='profile-pic'
/>
        </div>
        <div className="flex flex-col flex-grow ">
           <p className='font-bold text-sm'> {client.name}</p>
          <div className="flex flex-col md:flex-row md:justify-between text-gray-300 text-sm">
          <div className="">
              <p>
              {client.email}
              </p>
        <p>
        +254{client.phone}
        </p>
          </div>
          <div className="font-bold">
          <button className="px-3 text-white bg-green-400 rounded-lg items-center inline-flex align-center py-1 "  onClick={()=>{
            props.setCus(client)
            console.log(client)
            props.showModal(true)
          }}  >
                            <span class="material-icons-outlined flex-none">
                            price_change
                            </span> 
            </button>          
          </div>
          
          </div>
        </div>
    </div>
     );
}
 
export default ClientCard;