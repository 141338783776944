import RemainingProd from './remainingProd'
const DistributionDashboard = ({...props}) => {

    const distribution= props.distribution
    
    const productList = distribution.items

    
  
    return ( 
<div className="w-full lg:w-2/3 flex flex-wrap -mx-1 mt-1 overflow-x-hidden text-left  sm:-mx-1">
<div className="w-full text-gray-400 my-1 font-bold">Active Production</div>

<RemainingProd  products={productList} />

</div>
     );
}
 
export default DistributionDashboard;