import WarningAlert from "../../alerts/warningAlert";
import { BlueLoader } from "../../universal/loaders";
import StockCard from "./StockCard"

const StockList = ({...props}) => {
    const stocks = props.stock

    console.log(stocks)

    return (    
        <div className="w-full px-3 mt-4 overflow-x-hidden">

        {   stocks == null ? 

            <div className="w-full flex h-48 justify-center items-center">
                <BlueLoader />
            </div>
            
        :

        stocks.length > 0  ?
        <div className="w-full flex flex-wrap py-2">
           {stocks.filter(x=>x.stockproducts !== null ).map(stock=>
         
            <StockCard stock={stock} />
         
            
            )}
            </div>
        :
           <WarningAlert msg="Stocks Not Available" /> 
           
    }
        
        </div>
     );
}
 
export default StockList;