import {Fragment,useState,useEffect} from "react"
import moment from 'moment'
import Collection from "../../../pages/Collection";


const CollectionModal = ({...props}) => {

    const collection = props.collection

    const [useColl,setCol] = useState()



    const handleInputChange=(e,col)=>{
     useColl.find(x=>x.id==col).quantity=e
     setCol([...useColl])
    }

    useEffect(() => {
        setCol(collection.mixcollections)      
    }, [collection]);




    const handleSubmit = () => {
      const updateObj = {
        mixcollections:useColl
      }
      const date = moment(props.selectedDate).format('YYYY-MM-DD')

      props.updateCollection(collection.id,updateObj,date)

      props.showCollection(false)

    }
  

    return ( 

   <Fragment>
           <div
         className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
       >
         <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
               {/*content*/}
           <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   
     
   
           <div className="flex justify-between px-3 pt-5 pb-1">
                       <p className="text-xl font-bold text-red-400">{collection.product} Mix</p>
                       <button className="focus:outline-none text-gray-400" onClick={()=>props.showCollection(false)} ><span className="material-icons-outlined">close</span></button>
                     </div>
   
   
                     <div className="relative px-4 w-full pb-3    mt-4 text-left">
            <w className="full font-bold text-gray-500 font-sm">Submited Mix Collection</w>
                        <div className="w-full my-4 px-2">


{useColl && 



                    useColl.length > 0 ? 
                    
                    <>                    
                  {   useColl.map(col=>

                      
                        
                        <div className="w-full flex justify-between shadow-sm rounded-lg py-2 px-3 text-xs uppercase  items-center my-1">
                          {/* {col.length} */}
                        <p className="font-bold text-gray-600 text-xs">{col.product}</p>
                        <input type="number" onChange={(e)=> {handleInputChange(e.target.value,col.id)}}  value={col.quantity} placeholder="Enter Amount" minimum="1" className="border border-1 my-1  w-2/6 rounded text-sm w-1/5   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3  border-gray-200" />
                                    
                        </div>
                        
                        )  }
                    


                        <div className="w-full lg:w-2/3 mx-auto py-3">
                          <button className="bg-red-400 w-full text-white py-2 rounded-lg mt-3 font-bold" onClick={handleSubmit} > Edit Collection</button>
                        </div>

                 
</>
 
                  :
            
                  <p>No COllections Made</p>

                        

            }

                        </div>                            

           </div>  
           </div>      

         </div>
       </div>
       <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
   </Fragment>
     );
}
 
export default CollectionModal;