import {createSlice} from "@reduxjs/toolkit"

// slice -- mix pricing report


export const mixPricingReportSlice = createSlice({
    name:"mixpricingreports",
    initialState: {
        loading:false,
        error:false,
        errorMsg:null,
        data:null,
    },

    reducers: {
        loading_abr_mpr: (state)=> {
            state.error = false;
            state.errorMsg = null;
            state.loading = true;
        },
        success_abr_mpr: (state,action)=>{
            state.loading= false;
            state.data = action.payload;
        },
        error_abr_mpr: (state,action)=>{
            state.loading = false;
            state.errorMsg = action.payload;
            state.error = true
        },
        clear_error_abr_mpr:(state)=>{
            state.error = 'false'
        }
    }
})


export const  {success_abr_mpr,loading_abr_mpr,error_abr_mpr,clear_error_abr_mpr} = mixPricingReportSlice.actions

export const mpr_states = (state) => state.mixpricingreports

export default mixPricingReportSlice.reducer










