import moment from "moment";
import DistributionTableReport from "../distributionTableReport";
const CustomerOrderViewModal = ({setShowModal,order}) => {
    return ( 
        <div class=" fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0  mx-auto">
      
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      
               <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      
       
          <div class=" inline-block align-top bg-white rounded-lg text-left overflow-hidden shadow-xl p-4 transform transition-all sm:my-8 sm:align-middle w-full lg:w-1/2" id="">
         <div class="w-full" id="">
           
         <div class="flex mb-3 justify-between w-full">
                <p class="font-bold text-lg">{order.name}</p>
                <span class="material-icons-outlined text-gray-700 cursor-pointer" onClick={()=>setShowModal(false)}>
                  close
                  </span>
             </div> 

            
             
  <div className="w-full">
  <DistributionTableReport data={order.orders} no_client={false}   />
  </div>
          
      
           </div>
          </div>
        </div>
      </div>
    
     );
}
 
export default CustomerOrderViewModal;