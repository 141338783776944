import {createSlice} from "@reduxjs/toolkit"
import {logoutUser, userSuccess} from "./userSlice";


export const loginSlice = createSlice({
    name: "auth",
    initialState: {
        isLoading:false,
        isAuth:false,
        failed:false,
        error:null
    },
    reducers: {
        loginPending: (state) => {
            state.isLoading=true;
            state.failed = false;
            state.error= null
        },
        loginSuccess:(state,action)=> {
            state.isLoading=false;
            state.failed=false;
            state.error=null;
            state.isAuth=true
        },
        loginFailed:(state,action)=>{
            state.isLoading=false;
            state.failed=true
            state.error=action.payload
        },
        resetFailed:(state,action)=>{
            state.isLoading=false;
            state.failed=false;
            state.error=null
        }

    },
    extraReducers: {
        [userSuccess]: (state,action)=>{
            state.isAuth=true
            state.isLoading=false
        },
        [logoutUser]:(state,action)=>{
            state.isAuth=false;
        }
    }
})


export const {loginFailed,loginPending,loginSuccess,resetFailed}  = loginSlice.actions

export const loginStates = (state) => state.auth

export default loginSlice.reducer