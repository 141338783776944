import { useState, useEffect, Fragment } from "react";

export default function AddDelivery({ ...props }) {
  const [showModal, setShowModal] = useState(false);

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);

  useEffect(() => {
    props.fetchDrivers();
    props.fetchVehicles();
  }, []);

  const showAlert = (msg) => {
    alert(msg);
  };

  const drivers = props.drivers;
  const vehicles = props.vehicles;

  const handleSubmit = () => {
  
    const delivery = {
      vehicle: selectedVehicle,
      driver: selectedDriver,
      status: "active",
      distribution: props.distributionId,
    };
   if (selectedVehicle === null || selectedDriver === null ) {
    showAlert("Empty Inputs")
   }else{
    props.addDelivery(delivery)
    setShowModal(false)
   }
  
  };

  return (
    <>
      <button
        onClick={() => {
          setShowModal(true);
        }}
        className="h-13 w-13 my-2 focus:outline-none px-3 py-2 text-white bg-gray-400 rounded-xl text-xs"
      >
        Create Deliveries
      </button>
      <br />

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">
              {" "}
              {/*content*/}
              <div className="border-1 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header section */}

                <div className="flex justify-between px-6 pt-5 pb-1">
                  <p className="text-xl font-bold">Create Deliveries</p>
                  <button
                    onClick={() => setShowModal(false)}
                    className="focus:outline-none text-gray-400"
                  >
                    <span className="material-icons-outlined">close</span>
                  </button>
                </div>

                {/*body*/}
                <div className="relative px-6 pb-3 flex-auto">
                  <div className="flex w-full px-1 pb-5 flex-col text-left">
                    <p className="w-full font-bold mt-3 text-blue-500">
                      Select Driver/Transporter
                    </p>
                    <div className="w-full flex flex-wrap mt-5">
                      {drivers === null ? (
                        <p>Loading...</p>
                      ) : (
                        <>
                          {drivers.map((driver) => {
                            if (driver.user == selectedDriver) {
                              return (
                                <button
                                  className="px-6 py-2 font-bold m-2 text-sm   border-2 rounded-3xl text-blue-600 border-blue-600 capitalize"
                                  key={driver.id}
                                  onClick={() => {                                
                                    setSelectedDriver(driver.user);
                                  }}
                                >
                                  {driver.name}
                                </button>
                              );
                            } else {
                              return (
                                <button
                                  className="px-6 py-2 font-bold m-2 text-sm  border-2 rounded-3xl text-gray-400 border-gray-300 capitalize"
                                  key={driver.id}
                                  onClick={() => {
                                    setSelectedDriver(driver.user);
                                  }}
                                >
                                  {driver.name}
                                </button>
                              );
                            }
                          })}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="flex w-full px-1 pb-5 flex-col text-left">
                    <p className="w-full font-bold mt-3 text-blue-500">
                      Select Vehicles
                    </p>
                    <div className="w-full flex flex-wrap mt-5">
                      {drivers === null ? (
                        <p>Loading....</p>
                      ) : (
                        <>
                          {vehicles.map((vehicle) => {
                            if (vehicle.id === selectedVehicle) {
                              return (
                                <button
                                  key={vehicle.id}
                                  onClick={() => {
                                    setSelectedVehicle(vehicle.id);
                                  }}
                                  className="px-5 py-2 font-bold m-2 text-sm   border-2 rounded-3xl text-blue-600 border-blue-600 uppercase"
                                >
                                  {vehicle.plate_no}
                                </button>
                              );
                            } else {
                              return (
                                <button
                                  key={vehicle.id}
                                  onClick={() => {
                                    setSelectedVehicle(vehicle.id);
                                  }}
                                  className="px-5 py-2 font-bold m-2 text-sm   border-2 rounded-3xl text-gray-400 border-gray-300 uppercase"
                                >
                                  {vehicle.plate_no}
                                </button>
                              );
                            }
                          })}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-wrap overflow-hidden">
                    <div className="w-full lg:w-1/2 my-3 mx-auto text-center">
                      <button
                        className="bg-blue-600 w-full py-3 focus:outline-none text-white rounded-lg font-bold"
                        onClick={handleSubmit}
                      >
                        Submit Delivery
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
