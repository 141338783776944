
import {WarningAlert} from '../../alerts/alerts'
import { YellowLoader } from '../../universal/loaders';
import SearchTransporters from './transporters/searchTransporters';
import TransporterList from './transporters/transporterList';


const Transporters = ({...props}) => {

    const transporters = props.drivers





    return ( 
        <div className="w-full px-3  overflow-x-hidden  md:w-1/3 lg:w-1/3 rounded-lg">
            <div className="w-full p-3 shadow overflow-x-hidden">
            <div className="text-left p-4">
                <p className="font-bold">Transporters / Driver List</p>
                <SearchTransporters />
                
                {transporters === null ? (
                      <div className="w-full flex justify-center h-48 item-cente">
                     <YellowLoader/>
                  </div>
                ) : (
                   
                    transporters.length > 0 ? 
                    (
                        <div className="w-full max-h-48 overflow-y-scroll"> <TransporterList transporters={transporters} /> </div>) :
                    (
                        <div className="w-full mt-4">
                    <WarningAlert message="Empty Tranporter / Driver List" />
                    </div>
                    )    
                 
                ) }


            

            </div>
            </div>
        </div>
     );
}
 
export default Transporters;