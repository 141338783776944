
import { Link } from 'react-router-dom'

const BreadCrumbs = ({newPage}) => {
    return ( 
        <nav className="bg-gray-100 shadow-sm text-sm breadCrumb">
          <ol className="list-reset py-4 pl-4 rounded flex bg-grey-light text-grey">
            <li className="px-2">  <Link to="/" className="no-underline text-indigo font-bold">Dashboard</Link></li>
            <li>/</li>
            <li className="px-2 mt-">{newPage}</li>
          </ol>

  <style>
    {`@media print {.breadCrumb{display: none;}}`}
  </style>

          </nav>

        
     );
}
 
export default BreadCrumbs;