const IngList = ({...props}) => {
       return ( 
        <>
                <div className="flex mt-4 flex-wrap overflow-hidden sm:-mx-px">

        {
            props.ingredients === null 
            ? <p>Loading...</p>
            : 
            <>     
            {props.ingredients.map(ingredient => (
                <div className="w-full  overflow-hidden   md:w-1/2 lg:w-1/3 xl:w-1/4 my-1 px-2" key={ingredient.id}>
                    <div className="w-full flex  border-2 shadow flex-fluid py-4 text-left px-6 rounded-full">
                            <p className="text-gray-500 flex-grow font-bold capitalize">{ingredient.name}</p>
                            <div className="text-right flex-grow">
                         
                                      {ingredient.brands.map(brand=>{
                                          return (
                                            <div className="text-xs text-blue-600 inline mx-1 bg-blue-200 px-2 py-1 rounded-xl">{brand.brand_name}</div>
                                          )
                                          })
                                      }
                                  
                              
                            </div>
                    </div>
                </div>
    
          
            ))
            }
             </> 
                
        }
    
             </div>
             </>
     );
}
 
export default IngList;