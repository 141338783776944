import { useEffect } from "react";

const MixPricingReportTable = ({data}) => {

  console.log(data)

    return ( 

        data.length > 0 ?

        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div
          class="inline-block min-w-full shadow-md rounded overflow-hidden"
        >
          <table class="min-w-full leading-normal text-xxs uppercase ">
            
              <tr>
              <th
                  class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                >
                 Mix
                </th>

                <th
                  class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                >
                 Total Cost
                </th>

                <th
                  class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                >
                    Estimated Produce
                </th>

                <th
                  class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                >
                  Whole Sale Price
                </th>


                <th
                  class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                >
                  Profit / Loss
                </th>
                

                <th
                  class="px-5 py-6 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                >
                Cost Breakdown
                </th>
        
        
        
              </tr>
          
            <tbody>


            {data.map(mix_price => 
            
                <tr class="align-top" >
                <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-xs">
                    <p class="text-gray-900 whitespace-no-wrap">{mix_price.product.name} Mix</p>
                  </td>


                  <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-xs">
                    <p class="text-gray-900 whitespace-no-wrap font-bold">KSH {mix_price.current_value_of_mix}</p>
                  </td>


                  <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-xs">
                    <p class="text-gray-900 whitespace-no-wrap">{mix_price.estimation} PCS</p>
                  </td>

                  <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-xs">
                    <p class="text-gray-900 whitespace-no-wrap">{mix_price.estimation } PCS @ KSH {mix_price.product.wholesale} <span className="text-right font-bold">= KSH {mix_price.estimation * mix_price.product.wholesale}</span> </p>
                    {/* <p className="font-bold text-right"></p> */}
                  </td>

                  <td class="px-5 py-2 border-b border-gray-200 bg-white text-left text-xs">
                    <p className="font-bold text-xs text-gray-900"> = KSH {(mix_price.estimation*mix_price.product.wholesale)-mix_price.current_value_of_mix} </p>
                  </td>

                  <td  class="px-5 py-1 border-b border-gray-200 bg-white text-xs text-right" >
                      {mix_price.ingredients.map(ingredient=> 

<div className=" border-b text-xs"> 
<div className="flex justify-between">
<p className="font-bold" >{ingredient.ingredient_item.name}  </p>
<p><span className="">{ingredient.cost_per_ingredient} * {ingredient.quantity} {ingredient.ingredient_item.units} </span></p>
</div>   
<div className="w-full mb-2 text-right font-bold">
   = KSH  {ingredient.current_value_per_ingredient}
</div>
</div>
                            
                        //     <div className="my-1">
                        //     <p></p>
                        //     <div className="w-full flex justify-between pr-5">
                        //     <p><span className="text-bold text-gray-600">{ingredient.quantity}</span></p>
                        //     <p className="font-bold" >= KSH {ingredient.current_value_per_ingredient}</p>
                        //     </div>
                        //    </div>               
                            
                        )}
                  </td>
           
            
                </tr>
         

                )}
                  
        
              
            
              

            </tbody>
          </table>
        </div>
      </div>

        :

        <div className="w-full lg:w-1/2 text-center">
        <div className="rounded-lg w-full shadow border-2 border-blue-600 py-3 my-2">
            <div className="w-full flex justify-between  text-blue-600 py-1 px-5 ">
                    <span className="material-icons-outlined">
                    feedback
        </span>
                <p className="font-extrabold"> Results Not Found </p>
            </div>
        </div>
        
                </div>

     );
}
 
export default MixPricingReportTable;