const RemainingCard = ({product}) => {
    return (         
<div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:w-1/2 lg:w-1/3">
<div className="border border-blue-200 rounded-lg shadow border-0 flex px-4 py-3  justify-between w-full text-left">
    <p className="font-bold text-gray-600">{product.productdetails.name}</p>
    <div className="flex flex-row">
        <div className="bg-blue-200 text-blue-600 p-2   mr-3 rounded-full  text-xs">{product.remaining} R</div>
        <div className="bg-red-200 text-red-600 p-2   rounded-full  text-xs">{product.distributed} D</div>
    </div>
</div>
</div>
     );
}
 
export default RemainingCard;