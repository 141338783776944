import { useState,Fragment } from 'react'
const AddRecipeModal = ({...props}) => {
        
    const [showModal,setShowModal] = useState(false)   

    const [selectedI,setSelectedI] = useState([])
    const [moreSelected,setMoreSelected] = useState(false)
    
    const ingredients  = props.ingredients
    const product = props.product

    const makeSelected = (id) =>{
    const selected = {
      mix:product.mix.id,
      ingredient_item:id,
      quantity:0,
      }
        setSelectedI([...selectedI,selected]) 
    }

    const removeSelected = (id)=> {
        setSelectedI(selectedI.filter(item=> item.ingredient_item !== id))
    }


 

 

    // const handleChange

    const handleChange = (e) => {
        const id = e.target.name        
              selectedI.find(s => s.ingredient_item === parseInt(id)).quantity = e.target.value
    }

    const handleSubmit = (e) => {
      console.log(selectedI)
        props.addRecipe(selectedI)
    }

    return ( 
        <>

<div className="flex-none w-full ">
<button onClick={()=>{setShowModal(true)}} className="bg-yellow-400 float-right mr-3 focus:outline-none rounded-full  py-3 px-3 text-white"><span className="material-icons-outlined inline-flex align-bottom">
add_circle_outline
</span> &nbsp;  Mix Ingredients</button>
</div>

        <br />

        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-2/3 lg:w-1/3 xl:w-1/3  px-3">                {/*content*/}
                <div className="border-1 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">


                  <div className="flex flex-row overflow-hidden">
                          <div className="h-43 flow-hidden flex">
                              <div className="p-4">
                                    <img src="https://images.pexels.com/photos/1387070/pexels-photo-1387070.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" className="object-cover rounded-full h-24 w-24" />
                              </div>
                          </div>
                          <div className="flex-grow py-5 px-2">
              <div className="flex justify-between text-black w-full">
              <p className="font-bold text-xl ">{product.name}</p>
              <button className="focus:outline-none mr-2"><span className="material-icons-outlined" onClick={()=>{setShowModal(false)}} >close</span></button>
              </div>
              <div className="flex flex-col text-gray-400">
                <p className="font-bold text-sm">{product.mix.estimation} PCS / Mix</p> 
                <p className="text-lg font-bold text-black mt-3">Add Mix Recipe</p>
              </div>
                          </div>
                  </div>




                  <div className="flex w-full px-5 pb-5">
                        
                    <div className="w-full mt-5 mb-4 text-blue-600 text-left">
                      <p className="font-bold  my-3" >Select Ingredient</p>
                          {ingredients.map(ing=> {
                          if(selectedI.find(s => s.ingredient_item === ing.id)){
                       return     (<button className={`focus:outline-none border m-2 rounded-2xl border-1  border-blue-600 font-bold px-4 py-2 text-sm text-blue-600`}  onClick={()=> removeSelected(ing.id) } key={ing.id}> <span className={`material-icons-outlined  text-sm text-blue-600`}>done</span> {ing.name}</button>
                         ) }else{                           
                            return  (<button className={`focus:outline-none border m-2 rounded-2xl border-1 cursor-pointer border-gray-300 font-bold px-4 py-2 text-sm text-gray-500`} onClick={()=> makeSelected(ing.id)} key={ing.id} > <span className={`material-icons-outlined hidden text-sm text-blue-600`}>done</span> {ing.name}</button>)
                          }
                          }
                        )}
                    
                    </div>
                  </div>


                  {selectedI.length > 0 ? <Fragment>
              <div className="flex text-blue-600 font-bold px-6 py-3">
                      <p>Enter Amount Required Per Mix</p>
              </div>

                  <div className="flex flex-wrap  overflow-hidden mt-4 sm:m-2  px-3 py-3">
                  {ingredients.map(ing=> {                
                   if(selectedI.find(s=>s.ingredient_item === ing.id)){ return         ( 
                    <div className="w-full flex p-2     py-2 md:w-1/2 lg:w-1/2 xl:w-1/2" key={ing.id}>
                      <div className="w-full flex shadow p-2 ">
                    <p className="py-3 px-3 w-full font-bold text-black" >{ing.name}</p>
                  <input type="number" name={ing.id} onChange={handleChange}  placeholder="Enter Amount" className="border border-1 text-black my-1 rounded-xl text-sm   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-1 px-3 w-full border-gray-200" />
                  </div> 
                    </div>
                                     
                                     )
                  }}
                  )}
                  </div> 


                  <div className="flex flex-wrap overflow-hidden">
                    <div className="w-1/2 my-3 mx-auto text-center">
                      <button className="bg-blue-600 w-full py-3 text-white rounded-full" onClick={handleSubmit} >Submit Mix Recipe</button>
                    </div>
                  </div>

                  </Fragment> : ''}



              
          
         


                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        </>
     );
}
 
export default AddRecipeModal;