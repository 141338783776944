import { Fragment,useState,useEffect } from "react";
const SetPrices = ({...props}) => {
    const client = props.cus
    const [prices,setPrices] = useState()
    useEffect(() => {
        setPrices(client.prices)
    }, [client])


    const handleChange=(value,id)=>{
       
        prices.find(price=>price.id==id).price=value
        setPrices([...prices])
        
    }

    const handleSubmit=()=>{
      const obj = {"prices":prices}
      props.updatePrice(client.id,obj)
      props.showModal(false)
    }
    
    return ( 
        <Fragment>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
            {/*content*/}
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

  

        <div className="flex justify-between px-3 pt-1 pb-1">
                    <p className="text-xl text-green-500 font-bold">{client.name}</p>
                    <button  onClick={()=>{
                        props.showModal(false)
                    }}  className="focus:outline-none text-gray-400"><span className="material-icons-outlined">close</span></button>
                  </div>


                  <div className="relative px-4 w-full pb-3    mt-1 text-left">


        

                <div className="w-full mt-5">
                  <p className="my-3 font-bold my-3 font-lg">Select Product Prices</p>

                  <div className="flex flex-wrap  overflow-y-scroll max-h-48 mt-4 sm:m-2  px-3 py-3">
                 
                    { prices &&
                        prices.map(price=>
                            <div className="w-full flex p-2     py-2 my-1" key={price.id}>
                            <div className="w-full flex shadow p-2 ">
                          <p className="py-3 px-3 w-full font-bold text-black" >{price.product.name}</p>
                        <input type="number" onChange={(e)=>handleChange(e.target.value,price.id)} value={price.price} placeholder="Enter Amount" className="border border-1 text-black my-1 rounded-xl text-sm   border-gray-200 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-1 px-3 w-full border-gray-200" />
                        </div> 
                          </div>
                                     
                            )
                    }

                        
                                    
                 
                  </div> 

                </div>
                
                
       
    
              <div className="flex mx-auto w-full lg:w-2/3 my-4 mt-7">
                          <button  className="w-full py-3 bg-green-400 focus:outline-none text-white font-bold rounded-lg" onClick={handleSubmit}>Set Prices</button>
              </div>



                            


                                


                    </div>
      



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</Fragment>
     );
}
 
export default SetPrices;