import FabDistribution from "../layouts/distribution/FabDistribution"
import BreadCrumbs from "../universal/breadcrumbs"
import DistributionDashboard from '../layouts/distribution/distributionDashboard'
import Navbar from "../universal/navbar"
import {useEffect} from 'react'
import StartDistribution from "../layouts/distribution/startDistribution"
import SubmitDistribution from "../layouts/distribution/SubmitDistribution"
import PerformDistribution from "../layouts/distribution/PerformDistribution"
import { YellowLoader } from "../universal/loaders"


const Distrubution = ({...props}) => {

    useEffect(() => {
        props.getLastDist()
        
    }, [])


    const lastD = props.lastD


    

    return (   
        <div className="relative h-screen overflow-x-hidden mx-auto">
               <Navbar active="distribution" />
            <div className="w-full px-6">
            <BreadCrumbs newPage="Distribution"  />

        {
            lastD == null ?
            <div className="w-full flex h-48 items-center justify-center">
            <YellowLoader />
         </div>
            : 
            <>

                {
                   lastD.length < 1 ? (<StartDistribution completedItems={props.completedItems} getCompleted={props.getCompleted}  startDelivery={props.startDelivery} />) :
                   <>
                                   
                        {(() => {
                            if (lastD[0].status === 'pending'){
                                return (
                               <PerformDistribution getLastDist = {props.getLastDist} getDelivery={props.getDelivery} delivery={props.delivery} addShopStock={props.addShopStock} addOrderSell={props.addOrderSell}   vehicles={props.vehicles} addDelivery={props.addDelivery} drivers={props.drivers} fetchDrivers={props.fetchDrivers} fetchVehicles={props.fetchVehicles}  distribution={lastD[0]} customers={props.customers}  fetchCustomers={props.fetchCustomers}  />
                                )
                            
                            }else if (lastD[0].status === 'completed'){
                                return (
                                    <StartDistribution completedItems={props.completedItems} getCompleted={props.getCompleted}  startDelivery={props.startDelivery} />
                                    )
                            }else {
                                return(
                                    <p></p>
                                )
                            }                               
                         })()}
                   
                   </>
                }

            </>             
            
        }

        

            {/* <FabDistribution />
            <DistributionDashboard /> */}
            </div>
        </div>
     );
}
 
export default Distrubution;