import Navbar from "../universal/navbar";
import { useEffect } from "react";
import CustomerOrders from "../layouts/dashboard/customerOrders";
import {useSelector} from "react-redux"
import { Redirect } from "react-router";
import {userRole} from "../../features/userSlice"
import { currencyFormat } from "../universal/formatCur";
import { BlueLoader } from "../universal/loaders";

const Dashboard = ({...props}) => {

   const dashboardData = props.dashboardData

   const role = useSelector(userRole)


      useEffect(() => {
        props.dashboardReports()
    }, [])

    // const completed = props.
    




    return ( 
        <>

        { role && 
           role == 'Chefs' ? <Redirect to={{pathname:'/orders'}} />
           :


        role  == 'Clerk' ? <Redirect to={{pathname: '/distribution'}} />


        :

       <>

        <Navbar active="/" />


        {dashboardData == null ?
        
        <div className="w-full flex h-48 items-center justify-center">
        <BlueLoader />
     </div>

        :

        <div className="w-full px-3 lg:px-6 flex flex-wrap px-2">

                

            
                <div className="w-full flex flex-wrap lg:w-3/4 p-2 lg:p-4">

                <div class="w-full  lg:w-1/4 p-2"> 
            <div class="w-full p-3 bg-green-200 rounded-2xl flex py-6 shadow-lg items-start">
                <div class="bg-green-700 text-green-600 flex flex-none px-4 py-3 ml-3 rounded-full">
                    <span class="material-icons-outlined text-green-200">
                        account_balance_wallet
                        </span>
                </div>
                <div class="font-extrabold text-green-700 text-2xl px-4 flex flex-col flex-grow text-right">
                   <div class="w-full mb-3">   <span class="text-sm">KES</span>  {currencyFormat(dashboardData.payments.mpesa)}</div>
                    <div class="text-sm pb-3">Mpesa Received</div>
                </div>
             </div>
            </div>

            <div class="w-full  lg:w-1/4 p-2"> 
            <div class="w-full p-3 bg-blue-200 rounded-2xl flex py-6 shadow-lg items-start">
                <div class="bg-blue-700 text-blue-600 flex flex-none px-4 py-3 ml-3 rounded-full">
                    <span class="material-icons-outlined text-blue-200">
                        payments
                        </span>
                </div>
                <div class="font-extrabold text-blue-700 text-2xl px-4 flex flex-col flex-grow text-right">
                   <div class="w-full mb-3">   <span class="text-sm">KES</span> {currencyFormat(dashboardData.payments.cash)} </div>
                    <div class="text-sm pb-3">Cash Received </div>
                </div>
             </div>
            </div>

            <div class="w-full  lg:w-1/4 p-2"> 
            <div class="w-full p-3 bg-yellow-200 rounded-2xl flex py-6 shadow-lg items-start">
                <div class="bg-yellow-700 text-yellow-600 flex flex-none px-4 py-3 ml-3 rounded-full">
                    <span class="material-icons-outlined text-yellow-200">
                    money_off
                        </span>
                </div>
                <div class="font-extrabold text-yellow-700 text-2xl px-4 flex flex-col flex-grow text-right">
                   <div class="w-full mb-3">   <span class="text-sm">KES</span>  {currencyFormat(dashboardData.expenses)}</div>
                    <div class="text-sm pb-3">Todays Expenses </div>
                </div>
             </div>
            </div>

            <div class="w-full  lg:w-1/4 p-2"> 
            <div class="w-full p-3 bg-pink-200 rounded-2xl flex py-6 shadow-lg items-start">
                <div class="bg-pink-700 text-pink-600 flex flex-none px-4 py-3 ml-3 rounded-full">
                    <span class="material-icons-outlined text-pink-200">
                    attach_money
                        </span>
                </div>
                <div class="font-extrabold text-pink-700 text-2xl px-4 flex flex-col flex-grow text-right">
                   <div class="w-full mb-3">   <span class="text-sm">KES</span>  {currencyFormat(dashboardData.payments.cash - dashboardData.expenses)}</div>
                    <div class="text-sm pb-3">Cash In Hand </div>
                </div>
             </div>
            </div>
            
            

                        {/* <div className="w-full lg:w-1/3 p-2">
                            <div className="rounded-lg shadow border-2 border-blue-500 py-4">
                                <div className="w-full flex justify-between  text-blue-500 py-3 px-5 ">
                                    <p className="text-sm font-bold uppercase  ">Cash Sales</p>
                                    <p className="font-bold">KES {dashboardData.payments.cash}</p>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="w-full lg:w-1/3 p-2">
                            <div className="rounded-lg shadow border-2 border-green-500 py-4">
                                <div className="w-full flex justify-between uppercase text-green-500 py-3 px-5 ">
                                    <p className="text-sm font-bold">MPESA Sales</p>
                                    <p className="font-extrabold">KES {dashboardData.payments.mpesa}</p>
                                </div>
                            </div>
                        </div> */}
                      

                        {/* active mixes */}
                        <div className="w-full lg:w-1/3 my-3 shadow ">
                            <div className="w-full py-3 px-2">
                            <div className="w-full text-gray-500 font-bold text-left">Today's Mixes</div>

                            <div className="w-full my-4 max-h-48 overflow-y-scroll  px-1">

                            {dashboardData.mixes.length > 0 ? 
        dashboardData.mixes.map(mix=> 
            
            <div className="rounded-lg shadow border-2 border-red-400 py-3 my-2">
            <div className="w-full flex justify-between uppercase  text-red-400 py-1 px-5 ">
                <p className="text-sm font-bold">{mix.mix}</p>
                <p className="font-extrabold">{mix.qty} Mixes</p>
            </div>
        </div>


            )
            
        

                            :


                            <div className="rounded-lg w-full shadow border-2 border-red-400 py-3 my-2">
                                <div className="w-full flex justify-between  text-red-400 py-1 px-5 ">
                                        <span class="material-icons-outlined">
                                        feedback
</span>
                                    <p className="font-extrabold"> Zero Mixes Found</p>
                                </div>
                            </div>

                            }

                            </div>


                            </div>
                                                        
                        </div>


                </div>

                <div className="w-full lg:w-1/4">
                <div className="w-full text-gray-600 my-1 font-bold text-left mt-5"> Todays Produced Products</div>   
                
                <div className="w-full flex my-3 flex-wrap max-h-48 overflow-y-scroll  ">
                {dashboardData.production.length > 0 ? 


dashboardData.production.map(prod=> 
                    
                <div className="rounded-lg shadow border-2 w-full border-green-400 uppercase py-3 my-2">
                                <div className="w-full flex justify-between  text-green-400 py-1 px-5 ">
                                    <p className="text-sm font-bold">{prod.product}</p>
                                    <p className="font-extrabold">{prod.qty} pcs</p>
                                </div>
                            </div>
)
                :
                <div className="rounded-lg w-full  shadow border-2 border-green-400 py-3 my-2">
                <div className="w-full flex justify-between  text-green-400 py-1 px-5 ">
                        <span class="material-icons-outlined">
                        feedback
</span>
                    <p className="font-extrabold"> Zero Productions Found</p>
                </div>
            </div>

                    
            }

            </div>

                <div className="w-full text-gray-600 my-1 font-bold text-left mt-5">Distributed Products</div>   


                <div className="w-full flex my-3 flex-wrap max-h-48 overflow-y-scroll  ">

                { dashboardData.distribution.length > 0 ? 
                
                    
                dashboardData.distribution.map(dist=> 

                    <div className="rounded-lg w-full shadow border-2 w-full border-blue-400 uppercase py-3 my-2">
                    <div className="w-full flex justify-between  text-blue-400 py-1 px-5 ">
                        <p className="text-sm font-bold">{dist.product}</p>
                        <p className="font-extrabold">{dist.qty.qty} pcs</p>
                    </div>
                </div>


                    )
           

     
:

<div className="rounded-lg w-full shadow border-2 border-blue-400 py-3 my-2">
<div className="w-full flex justify-between  text-blue-400 py-1 px-5 ">
        <span class="material-icons-outlined">
        feedback
</span>
    <p className="font-extrabold"> Zero Distribution Made</p>
</div>
</div>


}


                </div>
                


                <div className="w-full text-gray-600 my-1 font-bold text-left mt-5">Todays  Orders</div>   


                <div className="w-full flex my-3 flex-wrap max-h-48 overflow-y-scroll  ">

            {dashboardData.customers.length > 0 ? 
                <CustomerOrders dOrder={props.dashboardCustomerOrder} orderPerCustomer={props.orderPerCustomer}  customers={dashboardData.customers}  />
           :

           <div className="rounded-lg w-full shadow border-2 border-yellow-400 py-3 my-2">
<div className="w-full flex justify-between  text-yellow-400 py-1 px-5 ">
        <span class="material-icons-outlined">
        feedback
</span>
    <p className="font-extrabold"> Nill Orders Returned</p>
</div>
</div>
        
        }

             
                </div>
                

                </div>

        </div>

   

}
</>
}
        </>


     );
}
 
export default Dashboard;